import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TagsFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "Tags", keyOptions = "tags" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

    const store = useStore();
    const [filterTags, setFilterTags] = useState(appliedFilter);
    const [tags, setTags] = useState({ items: [], pageCount: 1 });
    const [tagsIsLoading, setTagsIsLoading] = useState(false);

    const fetchTagsOptions = (optionFilters = null, page = 1) => {
        if (!tags.items.length) setTagsIsLoading(true);

        store.tagsStore
            .searchTags({ tag_names: optionFilters, save: true, size: optionsSize, page })
            .then((data: any) => {
                if (!data) return;

                setTags((prevTags) => {
                    const prevIds = new Set(prevTags.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTags.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTagsIsLoading(false));
    };

    useEffect(() => {
        setFilterTags(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={tagsIsLoading}
            options={tags.items.map((tag: any) => ({ name: tag.tag, value: tag.id, friendly_id: tag.friendly_id }))}
            pageCount={tags.pageCount}
            searchLocal={false}
            values={filterTags}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterTags(value)}
            getOptions={fetchTagsOptions}
            onSave={(savedTags) => onChange({ [keyOptions]: savedTags })}
        />
    );
});
