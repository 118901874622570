import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { action, observable } from "mobx";
import { findFilter } from "../layout/components/content/filters";
import PaginationStore from "./paginationStore";

class TasksStore extends PaginationStore {
    store;

    tasks = observable([]);
    live_results = observable({});

    error = {
        zip: "",
        view: "",
    };

    constructor(store) {
        super(); 
        this.store = store;
    }

    setTasks = action((tasks, part = false) => {
        if (!part) {
            this.tasks = tasks;
        } else {
            tasks.map((it) => {
                let filtered_task = this.tasks.find((task) => task.id === it.id);
                if (filtered_task) {
                    filtered_task.status = it.status;
                    filtered_task.start = it.start;
                    filtered_task.celery_task = it.celery_task;
                    filtered_task.elapsed = it.elapsed;
                    filtered_task.execution_bugs_count = it.execution_bugs_count;
                    filtered_task.results = it.results;
                    filtered_task.directory = it.directory;
                    filtered_task.variable_group_id = it.variable_group_id;
                    filtered_task.defects = it.defects;
                }
            });
        }
    });

    setBugs = action((execution_id, val) => {
        let task = this.tasks.filter((it) => it.id === execution_id);
        if (task.length === 1) {
            task[0].bugs = val;
        }
    });

    setSuiteLiveResults = action((execution_id, data) => {
        this.live_results[execution_id] = data;
    });

    setDefectTypeLiveResults = action((data) => {
        data.map((item) => {
            let task = this.tasks.filter((it) => it.id === item.execution_id);
            if (task.length === 1) {
                if (!task[0].defects) {
                    task[0].defects = { suggested: {}, accepted: {} };
                }
                task[0].defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
            }
        });
    });

    setDefectTypeSuitesLiveResults = action((execution_id, data) => {
        data.map((item) => {
            let suite = this.live_results[execution_id]?.filter((it) => it.suite_id === item.suite_id);
            if (suite.length === 1) {
                if (!suite[0].defects) {
                    suite[0].defects = { suggested: {}, accepted: {} };
                }
                suite[0].defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
            }
        });
    });

    setDefectTypeCasesLiveResults = action((execution_id, data) => {
        data.map((item) => {
            let suite = this.live_results[execution_id]?.filter((it) => it.suite_id === item.suite_id);
            if (suite.length === 1) {
                suite[0]?.test_cases.map((test) => {
                    if (test.id === item.test_id) {
                        if (!test.defects) {
                            test.defects = { suggested: {}, accepted: {} };
                        }
                        test.defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
                    }
                });
            }
        });
    });

    setCaseLiveResults = action((execution_id, suite_id, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            suite[0].test_cases = data;
        }
    });

    setCaseKeywordsLiveResults = action((execution_id, suite_id, case_id, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            let tcase = suite[0].test_cases.filter((it) => it.test_id === case_id);
            if (tcase.length === 1) {
                tcase[0].keywords = data;
            }
        }
    });

    setCaseKeywordsLogLiveResults = action((execution_id, test_run_id, suite_id, case_id, execution_path, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            let tcase = suite[0].test_cases.filter((it) => it.test_id === case_id);
            if (tcase.length === 1) {
                let keyword = tcase[0].keywords.find((a) => a.test_run_id === test_run_id && a.execution_path === execution_path);
                if (keyword) {
                    keyword.logs = data;
                }
            }
        }
    });

    setLiveCountedResults = action((data, exec_ids) => {
        exec_ids.map((item) => {
            let full_source = this.tasks.filter((it) => it.id === parseInt(item));
            let data_to_fill = data.filter((it) => parseInt(it.execution_id) === item);
            if (full_source.length === 1 && data && data_to_fill.length > 0) {
                full_source[0].live_counted_results = {
                    passed: data_to_fill[0].passed,
                    failed: data_to_fill[0].failed,
                    total: data_to_fill[0].total,
                    not_run: data_to_fill[0].not_run,
                    running: data_to_fill[0].running,
                    skipped: data_to_fill[0].skipped,
                };
            }
        });
    });

    setErrorZipMessage = action((err) => {
        this.error.zip = err;
    });

    setErrorViewMessage = action((err) => {
        this.error.view = err;
    });

    getZipFileForExecutions = (item) => {
        return this.store.apiStore
            .saveFile({
                url: `/tasks/result/${item.id}`,
                headers: this.store.authStore.getAuthHeader(),
            })
            .then((res) => res.blob())
            .then((data) => {
                saveAs(data, `results-${item.name}`);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorZipMessage(err));
    };

    getResultsByList = action((executions_ids, returned = false) => {
        return this.store.apiStore
            .post({
                url: `/execution/list`,
                body: JSON.stringify(executions_ids),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (!returned) {
                    this.setTasks(data, true);
                } else {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    combineURL = (filters, page, size) => {
        return this.store.apiStore.getCombinedPaginatedURL("execution", { 
            ...filters,
            ...filters?.from_date && { 
                from_date: Math.round(dayjs(filters.from_date).valueOf() / 1000),
            },
            ...filters?.to_date && { 
                to_date: Math.round(dayjs(filters.to_date).valueOf() / 1000)
            },
            ...filters?.execution_name && { 
                execution_name_like: filters?.execution_name, 
                execution_name: undefined,
            },
            ...filters?.types && {
                execution_types: filters?.types,
                types: undefined,
            },
            ...filters?.bugs_only?.[0] && filters?.bugs_only?.[0] !== "0" && {
                with_reported_bugs_only: filters?.bugs_only,
            }
        }, page, size);
    };

    searchExecutions = action(({ filters, page = 1, size = 10 }) => {
        return this.store.apiStore
            .get({
                url: this.combineURL(filters, page, size),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getResults = action(({ filters, page = 1, size = 10 }) => {
        return this.store.apiStore
            .get({
                url: this.combineURL(filters, page, size),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTasks(data["items"]);
                this.setPage(data.page);
                this.setTotal(data.total);
                this.setSize(data.size);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getMetricExecutionsPayload = (metric_name, params = {}) => {
        return { metric_name: metric_name, metric_params: params };
    };

    getExecutionsMetric = action((metric_name, params) => {
        const final_body = this.getMetricExecutionsPayload(metric_name, params);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                if (metric_name === "defect_types_count_execution") {
                    this.setDefectTypeLiveResults(data);
                }
                if (metric_name === "defect_types_count_suite") {
                    this.setDefectTypeSuitesLiveResults(params["execution_id"], data);
                }
                if (metric_name === "defect_types_count_test_case") {
                    this.setDefectTypeCasesLiveResults(params["execution_id"], data);
                } else {
                    return data;
                }
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get executions metric");
            });
    });

    combineDQURLRequest = (page, size, filters) => {
        const tcIds = findFilter(filters, "tc_ids");
        const status = findFilter(filters, "dq_test_results");
        const startDate = findFilter(filters, "start");
        const endDate = findFilter(filters, "end");
        let url = `/dq_stats/?page=${page}&size=${size}`;

        status.forEach((t) => (url = url.concat(`&dq_test_results=${t}`)));
        tcIds.forEach((t) => (url = url.concat(`&dq_check_ids=${t}`)));

        if (dayjs(startDate).isValid()) {
            url = url.concat(`&run_date_start=${startDate}`);
        }
        if (dayjs(endDate).isValid()) {
            url = url.concat(`&run_date_end=${endDate}`);
        }

        return url;
    };

    getDQResults = action(({ filters, page = 1, size = 50 }) => {
        return this.store.apiStore
            .get({
                url: this.combineDQURLRequest(page, size, filters),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch((err) => this.setErrorViewMessage(err));
    });

    getLiveSuiteResults = action((execution_id) => {
        return this.store.apiStore
            .get({
                url: `/metrics/live_test_suites_results_for_execution?execution_id=${execution_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSuiteLiveResults(execution_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getLiveCaseResults = action(({ execution_id, suite_id } = {}) => {
        return this.store.apiStore
            .get({
                url: `/metrics/live_test_case_results_for_suite_execution?execution_id=${execution_id}&suite_id=${suite_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setCaseLiveResults(execution_id, suite_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getCountedLiveResults = action((hashes) => {
        return this.store.apiStore
            .post({
                url: "/metrics/live_test_results_status_for_executions",
                body: JSON.stringify(hashes),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setLiveCountedResults(data, hashes);
                    return data;
                }
            });
    });

    getExecutionBugs = action((execution_id) => {
        return this.store.apiStore
            .get({
                url: `/reported_bugs/by_execution/${execution_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setBugs(execution_id, data);
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get bugs");
                this.setBugs(execution_id, []);
            });
    });

    getExecutionByStatusesAndIds = action(({ ids = [], statuses = [] }) => {
        return this.store.apiStore
            .post({
                url: `/search/?pagination=false&join=true&distinct=true`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    search: [{ table_name: "execution" }],
                    filters: [
                        {
                            items: [
                                ...ids.map((id) => ({
                                    column: "execution.id",
                                    is_value_column: false,
                                    search_value: id,
                                    search_type: "eq",
                                })),
                            ],
                            logical_opperand: "or",
                        },
                        {
                            items: [
                                ...statuses.map((status) => ({
                                    column: "execution.status",
                                    is_value_column: false,
                                    search_value: status,
                                    search_type: "eq",
                                })),
                            ],
                            logical_opperand: "or",
                        },
                    ],
                }),
            })
            .then((response) => response.json())
            .then((data) => data || [])
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getDetailedDQRules = action(({ ids = [], status = null, start = null, end = null, page = 1, size = 100 }) => {
        return this.getDQResults({ filters: [{ tc_ids: ids, start, end, ...status && { dq_test_results: [status] } }], page, size })
            .then(async (data) => {
                if (!data || !data?.items?.length) {
                    return {
                        items: [],
                        pageCount: 0,
                    };
                }

                const uniqueDqRuleIds = Array.from(data.items.reduce((acc, item) => {
                    acc.add(item.dq_rule_id);
                    return acc;
                },  new Set()));
                const testCases = (await this.store.testsStore.searchDQTestCaseData({ filters: { ids: uniqueDqRuleIds }, size: uniqueDqRuleIds.length }))?.items || [];

                const transformedData = data.items.reduce((acc, item) => {
                    const currentTestCase = testCases.find((testCase) => testCase.id === item.dq_rule_id);

                    if (currentTestCase) {
                        acc.push({ 
                            id: item.dq_rule_id,
                            friendly_id: currentTestCase.friendly_id,
                            name: item.dq_rule_name,
                            description: currentTestCase.description,
                            date: item.dq_check_date,
                            test_result: item.dq_test_result,
                            total: item.dq_total_count,
                            passed: item.dq_total_count - item.dq_number_failed,
                            failed: item.dq_number_failed,
                        });
                        return acc;
                    }

                    return acc;
                }, []);

                return {
                    items: transformedData,
                    pageCount: Math.ceil(data.total / size),
                };
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });
}

export default TasksStore;
