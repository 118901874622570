import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const RequirementTypesFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "Type", keyOptions = "type" }) => {
    const store = useStore();

    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterTypes, setFilterTypes] = useState(appliedFilter);

    useEffect(() => {
        setFilterTypes(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={store.requirementStore.types.map((type) => ({
                name: type.toUpperCase(),
                value: type,
            }))}
            showSearchInput={false}
            values={filterTypes}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterTypes(value)}
            onSave={(savedTypes) => onChange({ [keyOptions]: savedTypes })}
        />
    );
});
