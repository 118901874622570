import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const ReleaseFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "Releases", keyOptions = "release" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

    const store = useStore();
    const [filterReleaseIds, setFilterReleaseIds] = useState(appliedFilter);
    const [releases, setReleases] = useState({ items: [], pageCount: 1 });
    const [releasesIsLoading, setReleasesIsLoading] = useState(false);

    const fetchReleaseOptions = (optionFilters = {}, page = 1) => {
        if (!releases.items.length) setReleasesIsLoading(true);

        store.releaseStore
            .searchRelease({ filters: optionFilters, pagination: true, page, size: optionsSize, type: "or", returned: true })
            .then((data) => {
                if (!data) return;

                setReleases((prevReleases) => {
                    const prevIds = new Set(prevReleases.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevReleases.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setReleasesIsLoading(false));
    };

    useEffect(() => {
        setFilterReleaseIds(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={releasesIsLoading}
            options={releases.items.map((tag: any) => ({ name: tag.name, value: tag.id, friendly_id: tag.friendly_id }))}
            pageCount={releases.pageCount}
            searchLocal={false}
            values={filterReleaseIds}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterReleaseIds(value)}
            getOptions={fetchReleaseOptions}
            onSave={(savedReleases) => onChange({ [keyOptions]: savedReleases })}
        />
    );
});
