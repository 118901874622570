import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const RequirementStatesFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "State", keyOptions = "state" }) => {
    const store = useStore();

    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterStates, setFilterStates] = useState(appliedFilter);

    useEffect(() => {
        store.requirementStore.getStates(true);
    }, []);

    useEffect(() => {
        setFilterStates(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={store.requirementStore.states.map((type) => ({
                name: type.toUpperCase(),
                value: type,
            }))}
            showSearchInput={false}
            values={filterStates}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterStates(value)}
            onSave={(savedStates) => onChange({ [keyOptions]: savedStates })}
        />
    );
});
