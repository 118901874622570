import { unionBy } from "lodash";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const AggregationsFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Aggregation Type", keyOptions = "aggregation_type" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterAggregations, setFilterAggregations] = useState(appliedFilter);
        const [aggregations, setAggregations] = useState([]);
        const [aggregationsIsLoading, setAggregationsIsLoading] = useState(false);

        const fetchAggregationsOptions = () => {
            if (!aggregations.length) setAggregationsIsLoading(true);

            store.dataQualityStore
                .getAllVariants()
                .then((data: any) => {
                    if (!data) return;
                    setAggregations([...unionBy(data, "type")]);
                })
                .finally(() => setAggregationsIsLoading(false));
        };

        useEffect(() => {
            setFilterAggregations(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={aggregationsIsLoading}
                options={aggregations.map((i: any) => ({ name: i?.display_name, value: i?.type }))}
                searchLocal={true}
                values={filterAggregations}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterAggregations(value)}
                getOptions={fetchAggregationsOptions}
                onSave={(savedAggregations) => onChange({ [keyOptions]: savedAggregations })}
            />
        );
    }
);
