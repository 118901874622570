import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const UsersFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "Created By", keyOptions = "users" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

    const store = useStore();
    const [filterUsers, setFilterUsers] = useState(appliedFilter);
    const [users, setUsers] = useState({ items: [], pageCount: 1 });
    const [usersIsLoading, setUsersIsLoading] = useState(false);

    const fetchUserOptions = (optionFilters = {}, page = 1) => {
        if (!users.items.length) setUsersIsLoading(true);

        store.usersStore
            .searchUsers({ filters: optionFilters, type: "or", pagination: true, page, returned: true })
            .then((data) => {
                if (!data?.items?.length) return;

                setUsers((prevUsers) => {
                    const prevIds = new Set(prevUsers.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevUsers.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setUsersIsLoading(false));
    };

    useEffect(() => {
        setFilterUsers(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={usersIsLoading}
            options={users.items.map((tag: any) => ({ name: tag.username, value: tag.id, friendly_id: tag.friendly_id }))}
            pageCount={users.pageCount}
            searchLocal={false}
            values={filterUsers}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterUsers(value)}
            getOptions={fetchUserOptions}
            onSave={(savedUsers) => onChange({ [keyOptions]: savedUsers })}
        />
    );
});
