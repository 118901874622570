import { Space } from "antd";
import { FunctionComponent } from "react";
import { GitRepoFilterItem, InputFilterItem, StatusFilterItem, TestSuitesFilterItem, TestTypeFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const TestSuitesFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap>
            <TestSuitesFilterItem filters={filters} onChange={onChange} label="ID" keyOptions="id" />
            <InputFilterItem filters={filters} keyOptions="name" placeholder="Search test suite name...." onChange={onChange} />
            <TestTypeFilterItem filters={filters} onChange={onChange} />
            <StatusFilterItem filters={filters} onChange={onChange} />
            <GitRepoFilterItem filters={filters} onChange={onChange} label="Git Repo" />
        </Space>
    );
};
