import { action, makeObservable, observable } from "mobx";
import PaginationStore from "./paginationStore";

class TagsStore extends PaginationStore {
    store;

    tags = [];
    error = {
        get: "",
        create: "",
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            tags: observable,
        });
    }

    setTags = action((tags) => {
        this.tags = tags;
    });

    setErrorViewTags = action((err) => {
        this.error.get = err;
    });

    setErrorCreateTags = action((err) => {
        this.error.create = err;
    });

    getTags = action(() => {
        return this.store.apiStore
            .get({
                url: `/tags/`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTags(data);
                this.setPage(data.length);
                this.setTotal(data.length);
                this.setSize(data.length);
                this.setPagination(false);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewTags(err));
    });

    getRelatedTags = (suites, approaches = null) => {
        let body = {};

        if (approaches.length > 0) {
            body.approaches = approaches.map((it) => it.id);
            body.suites = [];
        } else if (suites.length > 0) {
            body.suites = suites.map((it) => it.id);
            body.approaches = [];
        }
        if ((!body.suites && !body.approaches) || (body.suites?.length === 0 && body.approaches?.length === 0)) {
            return Promise.resolve();
        }
        return this.store.apiStore
            .post({
                url: `/tags/get_related`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTags(data);
                this.setPage(data.length);
                this.setTotal(data.length);
                this.setSize(data.length);
                this.setPagination(false);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewTags(err));
    };

    createTags = (tags) => {
        return this.store.apiStore
            .post({
                url: `/tags/create_tags`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    tags: tags,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorCreateTags(err));
    };

    searchTags = ({ tag_names, save = false, page = 1, size = 50, pagination = true }) => {
        let payload_filters = [];

        if (tag_names instanceof Array && tag_names.length) {
            payload_filters = [
                {
                    column: "tag.tag",
                    is_value_column: false,
                    search_value: tag_names,
                    search_type: "in",
                },
            ];
        } else if ((tag_names instanceof Object || typeof tag_names === "object") && (tag_names?.name?.length || tag_names?.id?.length)) {
            payload_filters = [
                {
                    column: "tag.tag",
                    is_value_column: false,
                    search_value: `%${tag_names.name}%`,
                    search_type: "like",
                },
                {
                    column: "tag.id",
                    is_value_column: false,
                    search_value: `${tag_names.id}`,
                    search_type: "eq",
                },
            ];
        } else {
            if ((tag_names instanceof String || typeof tag_names === "string") && tag_names) {
                payload_filters = [
                    {
                        column: "tag.tag",
                        is_value_column: false,
                        search_value: `%${tag_names}%`,
                        search_type: "like",
                    },
                    {
                        column: "tag.id",
                        is_value_column: false,
                        search_value: `${tag_names}`,
                        search_type: "eq",
                    },
                ];
            }
        }
        
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    search: [{ table_name: "tag" }],
                    return_schema: "TagSchema",
                    filters: [
                        {
                            logical_opperand: "or",
                            items: payload_filters,
                        },
                    ],
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (save) {
                    if (pagination) {
                        this.setTags(data["items"]);
                        this.setPage(data["page"]);
                        this.setSize(data["size"]);
                        this.setTotal(data["total"]);
                    } else {
                        this.setTags(data);
                    }
                }
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewTags(err));
    };
}
export default TagsStore;
