import { SearchOutlined } from "@ant-design/icons";
import { Input, InputProps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDebouncedValue } from "../../hooks";

interface Props extends Omit<InputProps, "onChange"> {
    value: string;
    onChange: (value: string) => void;
    debounce?: number;
}

export const DebouncedInput: React.FC<Props> = ({ value, onChange, debounce = 500, addonBefore = <SearchOutlined />, ...restProps }) => {
    const hasMounted = useRef(false);
    const [inputValue, setInputValue] = useState(value);
    const debouncedValue = useDebouncedValue(inputValue, debounce);

    useEffect(() => {
        if (hasMounted.current) {
            onChange(debouncedValue);
        }

        if (!hasMounted.current) {
            hasMounted.current = true;
        }
    }, [debouncedValue]);

    return <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} addonBefore={addonBefore} {...restProps} />;
};
