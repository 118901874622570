export type TestCaseKeywordLogs = {
    execution_path: string;
    id: number;
    log_level: string;
    message: string;
    suite_id: number;
    test_id: number;
    test_run_id: number;
    timestamp: string;
};

export type TestCaseKeywords = {
    filter(arg0: (it: TestCaseKeywords) => boolean): any;
    execution_path: string;
    arguments: string[];
    keyword: string;
    keyword_order: number;
    keyword_type: string;
    message_count: number;
    status: string;
    test_id: number;
    test_run_id: number;
    logs?: any[];
};

export const tag_status_mapper: any = {
    FAIL: `tag-failed-no-hover`,
    FAILED: `tag-failed-no-hover`,
    ERROR: `tag-error-no-hover`,
    NOT_RUN: "connectionTag",
    WARNING: "tag-info",
    RUNNING: "tag-running",
    PASSED: "tag-passed",
    PASS: "tag-passed",
};
export type TestCaseKeywordsLog = {
    execution_path: string;
    id: number;
    log_level: string;
    message: string;
    suite_id: number;
    test_id: number;
    test_run_id: number;
    timestamp: string;
};

export type TestCasesTypeLiveResult = {
    status: string;
    test_run_id: number;
    test_id: number;
    suite_id: number;
    start_time: string;
    name: string;
    id: number;
    fingerprint: string;
    execution_status: string;
    defects?: any;
    keywords?: TestCaseKeywords[];
};
export type TestCase = {
    id: number;
    friendly_id: string;
    name: string;
    description: string;
    is_active: boolean;
    keywords: any[];
    keyword?: any;
    test_type: string;
    test_suites: any[];
    test_approaches: any[];
    requirements?: any[];
    work_item_url: string;
    executions: any[];
    created_date: string;
    updated_date: string;
    tags: any[];
    last_execution_status?: string;
    live_execution_history?: any[];
    dimensions?: any[];
    robot_test_case?: any;
    category_type: string;
};
