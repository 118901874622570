import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TestDataParametersFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Test parameters", keyOptions = "test_data" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterTestDataParameters, setFilterTestDataParameters] = useState(appliedFilter);
        const [testDataParameters, setTestDataParameters] = useState({ items: [], pageCount: 1 });
        const [testDataParametersIsLoading, setTestDataParametersIsLoading] = useState(false);

        const fetchTestDataParameterOptions = (optionFilters = {}, page = 1) => {
            if (!testDataParameters.items.length) setTestDataParametersIsLoading(true);

            store.testsStore
                .searchTestDataParameters({ search_value: optionFilters ? optionFilters?.["id"] : "", page, size: optionsSize })
                .then((data: any) => {
                    if (!data) return;

                    setTestDataParameters((prevTestDataParameters) => {
                        const prevIds = new Set(prevTestDataParameters.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevTestDataParameters.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setTestDataParametersIsLoading(false));
        };

        useEffect(() => {
            setFilterTestDataParameters(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={testDataParametersIsLoading}
                options={testDataParameters.items.map((it) => ({
                    name: it.parameter,
                    value: it.parameter,
                    key: it.id,
                }))}
                pageCount={testDataParameters.pageCount}
                searchLocal={false}
                values={filterTestDataParameters}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterTestDataParameters(value)}
                getOptions={fetchTestDataParameterOptions}
                onSave={(savedTestData) => onChange({ [keyOptions]: savedTestData })}
            />
        );
    }
);
