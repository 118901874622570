import { Select, Spin } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { useStore } from "../../../stores/StoreContext";
import { ProjectType } from "../../../types";
import { useAvailableUserProjects } from "../../../view/pages/authentication/login/useAvailableUserProjects";
interface ProjectSelectInterface {
    id?: string;
    onChangeUserProject?: (e: any) => void;
    initValue?: string;
}

export const ProjectSelect: FunctionComponent<ProjectSelectInterface> = observer(
    ({ id = `login_instance_select`, onChangeUserProject, initValue }) => {
        const store = useStore();
        const { availableProjects, loadingProject, project, setProject, searchProject } = useAvailableUserProjects(store);

        const userProject = store.authStore.user?.projects?.map((i: ProjectType) => i?.project_name);

        useEffect(() => {
            if (initValue) {
                setProject(initValue);
            }
        }, [initValue]);

        useEffect(() => {
            if (!project && !initValue) {
                setProject(availableProjects?.[0]?.name);
                onChangeUserProject && onChangeUserProject(availableProjects?.[0]?.name);
            }
        }, [availableProjects]);

        return (
            <>
                <Select
                    id={id}
                    optionFilterProp="name"
                    popupMatchSelectWidth={false}
                    placeholder={"User project"}
                    value={project}
                    showSearch
                    loading={loadingProject}
                    notFoundContent={loadingProject ? <Spin /> : "No matches"}
                    onSearch={(e) => initValue && searchProject(e)}
                    onChange={(e) => {
                        setProject(e);
                        onChangeUserProject && onChangeUserProject(e);
                    }}
                    disabled={availableProjects?.length === 0}
                >
                    {availableProjects?.map((value, index) => {
                        return (
                            <Select.Option
                                key={index}
                                disabled={userProject && !userProject?.includes(value.name)}
                                id={value.id}
                                name={value.name}
                                value={value.name}
                            >
                                {value.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </>
        );
    }
);
