import { BsClipboardData } from "react-icons/bs";
import { ImInsertTemplate } from "react-icons/im";
import { IoGitCompareOutline } from "react-icons/io5";
import { MdOutlineSpaceDashboard, MdRule } from "react-icons/md";
import {
    RiAddLine,
    RiBarChartFill,
    RiCheckboxCircleLine,
    RiDashboardFill,
    RiDatabaseLine,
    RiFoldersLine,
    RiGitCommitLine,
    RiGuideLine,
    RiPlayCircleLine,
    RiRocketLine,
} from "react-icons/ri";
import { TbGitCommit, TbGitMerge, TbLayoutDashboard, TbReportSearch } from "react-icons/tb";
import { VscVmRunning } from "react-icons/vsc";
import env from "../env/environment";
import { PagePath } from "../router/types";
import { NavigationPageType } from "./types";

export const navigationPages: NavigationPageType[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: <RiDashboardFill />,
        navLink: PagePath.DASHBOARD,
    },
    {
        id: "requirements",
        title: "Requirements",
        icon: <RiGuideLine />,
        navLink: PagePath.REQUIREMENTS,
    },
    {
        id: "ai_ml_predictions",
        title: "AI/ML predictions",
        icon: <RiRocketLine />,
        navLink: PagePath.PREDICTIONS,
    },
    {
        id: "tests",
        title: "Test",
        icon: <RiCheckboxCircleLine />,
        children: [
            {
                id: "tests_cases",
                title: "Test Suites",
                icon: <RiFoldersLine />,
                navLink: PagePath.TEST_SUITES,
            },
            {
                id: "tests_approaches_cases",
                title: "Test Approaches",
                icon: <RiDatabaseLine />,
                navLink: PagePath.TEST_APPROACHES,
            },
            {
                id: "test_cases",
                title: "Test Cases",
                icon: <RiFoldersLine />,
                navLink: PagePath.TEST_CASES,
            },
            {
                id: "rules",
                title: "Rules",
                icon: <MdRule />,
                navLink: PagePath.RULES,
            },
            {
                id: "new_test_case_definition",
                title: "New Test Case",
                icon: <RiAddLine />,
                navLink: PagePath.NEW_TEST_CASE,
            },
            {
                id: "reconciliation",
                title: "Compare & Run",
                icon: <IoGitCompareOutline />,
                navLink: PagePath.RECONCILIATION,
            },
        ],
    },
    {
        id: "data_quality",
        title: "Data Quality",
        icon: <BsClipboardData />,
        children: [
            {
                id: "data_quality_dashboard",
                title: "Dashboard",
                icon: <TbLayoutDashboard />,
                navLink: PagePath.DATA_QUALITY_DASHBOARD,
            },
            {
                id: "new_aggregation_check",
                title: "New DQ Rule",
                icon: <RiAddLine />,
                navLink: PagePath.NEW_DATA_QUALITY,
            },
            {
                id: "new_dq_view_results",
                title: "DQ Results",
                icon: <MdOutlineSpaceDashboard />,
                navLink: PagePath.DATA_QUALITY_RESULTS,
            },
        ],
    },
    {
        id: "releases",
        title: "Releases",
        icon: <RiGitCommitLine />,
        navLink: PagePath.RELEASES,
    },
    {
        id: "run_execution",
        title: "Executions",
        icon: <RiPlayCircleLine />,
        children: [
            {
                id: "runtime_variables_execution",
                title: "Runtime Variables",
                icon: <VscVmRunning />,
                navLink: PagePath.VARIABLES,
            },
            {
                id: "simple_execution",
                title: "Simple Run",
                icon: <TbGitCommit />,
                navLink: PagePath.NEW_EXECUTION,
            },
        ],
    },
    {
        id: "template_results",
        title: "Templates",
        icon: <ImInsertTemplate />,
        navLink: PagePath.WORKFLOW_TEMPLATES,
    },
    {
        id: "workflow",
        title: "Workflows",
        icon: <TbGitMerge />,
        navLink: PagePath.NEW_WORKFLOW,
    },
    {
        id: "execution_results",
        title: "Results",
        icon: <RiBarChartFill />,
        navLink: PagePath.EXECUTIONS,
    },
    {
        id: "reports",
        title: "Reports",
        icon: <TbReportSearch />,
        navLink: `${env.METABASE_SUBPAGE}`,
        externalLink: true,
    },
];
