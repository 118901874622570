import { Button, Space } from "antd";
import { FunctionComponent, useState } from "react";
import {
    GitRepoFilterItem,
    InputFilterItem,
    StatusFilterItem,
    TagsFilterItem,
    TestApproachesFilterItem,
    TestCasesFilterItem,
    TestDataParametersFilterItem,
    TestSuitesFilterItem,
    TestTypeFilterItem,
} from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const TestCasesFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    const [showBasic, setShowBasic] = useState(true);

    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <TestCasesFilterItem filters={filters} onChange={onChange} label="ID" keyOptions="id" />
            <InputFilterItem
                id="search-test-case-name-input"
                filters={filters}
                keyOptions="name"
                placeholder="Search test case name...."
                onChange={onChange}
            />
            {!showBasic && (
                <InputFilterItem
                    id="search-test-case-description-input"
                    filters={filters}
                    keyOptions="description"
                    placeholder="Search description...."
                    onChange={onChange}
                />
            )}
            <TestTypeFilterItem filters={filters} onChange={onChange} />
            <TagsFilterItem filters={filters} onChange={onChange} />
            <TestSuitesFilterItem filters={filters} onChange={onChange} />
            <TestApproachesFilterItem filters={filters} onChange={onChange} />
            <StatusFilterItem filters={filters} onChange={onChange} />
            <GitRepoFilterItem filters={filters} onChange={onChange} />
            {!showBasic && <TestDataParametersFilterItem filters={filters} onChange={onChange} />}
            <Button
                type="link"
                id="show-more-filter-options"
                className="da-mr-sm-8 da-mr-16"
                onClick={() => setShowBasic((prevState) => !prevState)}
            >
                {showBasic ? "Show more filters" : "Show less filters"}
            </Button>
        </Space>
    );
};
