import { action, computed, makeObservable, observable } from "mobx";
import PaginationStore, { DEFAULT_PAGE_SIZE } from "./paginationStore";

class RequirementStore extends PaginationStore {
    store;

    statisticRequirements = {
        total: 0,
        covered: 0,
    };
    requirements = [];
    info = {
        sync: "",
    };

    error = {
        new: "",
        edit: "",
        sync: "",
    };

    states = [];
    statesFetched = false;
    types = [];
    typesFetched = false;
    activated_sync = {};

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            requirements: observable,
            states: observable,
            statesFetched: observable,
            types: observable,
            typesFetched: observable,
            activated_sync: observable,
            getSyncStatus: computed,
            setActivatedSync: action,
            statisticRequirements: observable,
        });
    }

    setNewErrorMessage = (err) => {
        this.error.new = err;
    };

    get getSyncStatus() {
        return this.activated_sync;
    }

    setActivatedSync(val) {
        this.activated_sync = val;
    }

    setNewSyncInfoMessage = (err) => {
        this.info.sync = `Submitted task for sync requirements: ${err}`;
    };

    setNewSyncMessage = (err) => {
        this.error.sync = err;
    };

    setRequirements = action((rel) => {
        this.requirements = rel;
    });

    setTestCasesForRequirements = (req_id, cases) => {
        let req = this.requirements.filter((it) => it.id === req_id);
        if (req.length > 0) {
            this.setTestReqCases(req[0], cases);
        }
    };

    setTestReqCases = (req, cases) => {
        req["test_cases"] = cases;
    };

    setTypes = action((types) => {
        this.types = types;
        this.typesFetched = !!types?.length;
    });

    setStates = action((states) => {
        this.states = states;
        this.statesFetched = !!states?.length;
    });

    setStatisticRequirements = action((val) => {
        this.statisticRequirements = val;
    });

    getAllRequirements = action((page = 1, size = DEFAULT_PAGE_SIZE, covered = null, returned = false) => {
        let url;
        switch (covered) {
            case "covered":
                url = `/requirements/with_test_cases_count?covered=true`;
                break;
            case "not_covered":
                url = `/requirements/with_test_cases_count?covered=false`;
                break;
            default:
                url = `/requirements/with_test_cases_count?page=${page}&size=${size}`;
        }

        return this.store.apiStore
            .get({
                url: url,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                this.setRequirements(data["items"]);
                if (!covered) {
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                } else {
                    this.setPage(1);
                    this.setTotal(data["items"].length);
                    this.setSize(data["items"].length);
                    this.setPagination(false);
                }
            })
            .catch((e) => {
                Promise.reject("Unable to get requirements");
            });
    });

    getRequirementById = action((id) => {
        return this.store.apiStore
            .get({
                url: `/requirements/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestCasesForRequirements(id, data["test_cases"]);
            })
            .catch((e) => {
                Promise.reject("Unable to get requirements");
            });
    });

    syncRequirements = action(() => {
        return this.store.apiStore
            .post({
                url: `/requirements/sync_requirements`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.["msg"]) {
                    let task = data["msg"].split(":");
                    this.setActivatedSync({ status: "START" });
                    this.store.intervalStore.updateTaskUpdator(task[task.length - 1].trim(), "requirements");
                    this.store.intervalStore.registerWatcher();
                } else if (data?.["error"]) {
                    this.setNewSyncMessage(data?.["error"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail ?? data?.error)))
            .catch((err) => this.setNewSyncMessage(err));
    });

    syncRequirement = action((item, object = true) => {
        let sync_item = object ? item.work_item_id : item;
        return this.store.apiStore
            .post({
                url: `/requirements/sync_requirement?requirement_work_item_id=${sync_item}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.getStatisticRequirements();

                if (item?.id) {
                    this.getRequirementById(item.id);
                }
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewSyncMessage(err));
    });

    updateRequirements = action((item) => {
        return this.store.apiStore
            .post({
                url: "/requirements/link_requirement_with_test_cases",
                body: JSON.stringify(item),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.getStatisticRequirements();
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewErrorMessage(err));
    });

    getStatisticRequirements = action(() => {
        let promises = [];

        promises.push(this.store.requirementStore.searchRequirements({ filters: { name: "" }, returned: true }));
        promises.push(this.store.requirementStore.getAllRequirements(null, null, "covered", true));

        return Promise.allSettled(promises).then((data) => {
            this.setStatisticRequirements({
                total: data[0].value?.total,
                coveredTotal: data[1].value?.total,
            });
        });
    });

    combineTableForSearch = (filters) => {
        let tables = [];
        tables.push({
            table_name: "requirements",
        });
        tables.push({
            table_name: "reported_bugs",
            parent: "requirements",
            is_full: false,
            is_outer: true,
        });
        tables.push({
            table_name: "test_case",
            is_full: false,
            is_outer: true,
            parent: "requirements",
        });

        return tables;
    };

    combineFiltersForSearch = (filters, type) => {
        let items = [];
        Object.entries(filters).map((key, value) => {
            if (key[0] === "name") {
                items.push({
                    column: "requirements.name",
                    is_value_column: false,
                    search_value: `%${key[1]}%`,
                    search_type: "like",
                });
            } else if (key[0] === "id") {
                key[1].map((val) => {
                    items.push({
                        column: "requirements.id",
                        is_value_column: false,
                        search_value: val,
                        search_type: "eq",
                    });
                });
            } else if (key[0] === "type") {
                items.push({
                    column: "requirements.type",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "work_item_id") {
                items.push({
                    column: "requirements.work_item_id",
                    is_value_column: false,
                    search_value: key[1].map((i) => i),
                    search_type: "in",
                });
            } else if (key[0] === "state") {
                items.push({
                    column: "requirements.state",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "test_cases_count") {
                const isCoveredSearchType = key[1]?.includes("covered");
                items.push({
                    column: "test_case.id",
                    is_value_column: false,
                    search_value: "None",
                    search_type: isCoveredSearchType ? "is_not" : "is",
                });
            }
        });

        return [
            {
                logical_opperand: type ? type : "and",
                items: items,
            },
        ];
    };

    searchRequirements = action(({ filters, type = "and", pagination = true, returned = false, page = 1, size = DEFAULT_PAGE_SIZE, return_schema = true }) => {
        let filtersToSend = this.combineFiltersForSearch(filters, type);
        let tables = this.combineTableForSearch(filters);
        let final_body = {
            search: tables,
            filters: filtersToSend,
            distinct_on: "requirements.id",
        };

        return this.store.apiStore
            .post({
                url: `/search/?pagination=${pagination}&size=${size}&page=${page}`,
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                if (data) {
                    this.setRequirements(data["items"]);
                    this.setTotal(data.total);
                    this.setSize(data.size);
                    this.setPage(data.page);
                    this.setPagination(true);
                } else {
                    this.setRequirements([]);
                    this.setTotal(0);
                    this.setSize(DEFAULT_PAGE_SIZE);
                    this.setPagination(false);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    getTypes = (fetchFromNetwork = false) => {
        if (this.typesFetched && !fetchFromNetwork) {
            return Promise.resolve(this.types);
        }
        return this.store.configStore
            .getDistinctValues("requirements", "type")
            .then((data) => {
                this.setTypes(data);
                return data;
            })
            .catch((err) => {
                console.error("Error getting types ", err);
                this.typesFetched = false;
            });
    };

    getStates = (fetchFromNetwork = false) => {
        if (this.statesFetched && !fetchFromNetwork) {
            return Promise.resolve(this.states);
        }
        return this.store.configStore
            .getDistinctValues("requirements", "state")
            .then((data) => {
                this.setStates(data);
                return data;
            })
            .catch((err) => {
                console.error("Error getting states ", err);
                this.statesFetched = false;
            });
    };

    resetStatesAndTypes = () => {
        this.statesFetched = false;
        this.typesFetched = false;
    };
}
export default RequirementStore;
