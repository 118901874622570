import { theme } from "antd";
import React from "react";
import Chart from "react-apexcharts";
import { ChartProps } from "./types";

export const LineChart: React.FC<ChartProps> = ({
    categories,
    series,
    id = null,
    colors = [],
    yTitle = "",
    xTitle = "Date",
    customOptions = {},
}) => {
    const { useToken } = theme;
    const { token } = useToken();

    const options: any = {
        chart: {
            fontFamily: "Manrope, sans-serif",
            type: "line",
        },
        stroke: {
            curve: "smooth",
        },
        markers: {
            size: 7,
            strokeWidth: 2,
        },
        colors: colors.concat([token.colorPrimary, token.colorSuccess, token.colorWarning, token.colorError]),
        dataLabels: {
            enabled: false,
        },
        grid: {
            borderColor: "#DFE6E9",
            row: {
                opacity: 0.5,
            },
        },
        legend: {
            horizontalAlign: "right",
            offsetX: 40,
            position: "bottom",
        },
        yaxis: {
            title: {
                text: yTitle,
            },
            labels: {
                style: {
                    colors: ["636E72"],
                    fontSize: "14px",
                },
                formatter: (value) => value,
            },
            forceNiceScale: true,
        },
        xaxis: {
            type: "datetime",
            title: { text: xTitle },
            tickPlacement: "between",
            labels: {
                datetimeUTC: false,
                style: {
                    colors: ["636E72"],
                    fontSize: "14px",
                },
            },
            forceNiceScale: true,
            categories,
        },
        ...customOptions,
    };

    return <Chart id={id} options={options} series={series} type="area" height={350} legend="legend" />;
};
