import { Empty, Skeleton } from "antd";
import { ApexOptions } from "apexcharts";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useStore } from "../../stores/StoreContext";
import { BarVericalChart } from "./BarVericalChart";

interface Props {
    choosen: any;
    testRunDetails: boolean;
    loadingTcRuns: boolean;
    loading: boolean;
    setRunDetails: (value: boolean) => void;
    setLoading: (key: any, value: any) => void;
}

export const ExecutionsLineChart: React.FC<Props> = observer(
    ({ choosen, testRunDetails, loadingTcRuns, loading, setRunDetails, setLoading }) => {
        const store = useStore();
        const [testRun, setTestRun] = useState(null);

        const handleChartClick = ({ clientX, clientY }, chartContext, { dataPointIndex }) => {
            if (choosen.test_suite_name) {
                setLoading("test_run_details", true);
                setTestRun(choosen.details[dataPointIndex].test_run_id);
                return store.metricsStore
                    .getRunDetails(choosen.details[dataPointIndex].name, choosen.details[dataPointIndex].test_run_id)
                    .then(() => setLoading("test_run_details", false))
                    .then(() => setRunDetails(true));
            }
        };

        const data =
            !loading && !testRunDetails
                ? {
                      series: [
                          {
                              name: "Execution duration",
                              type: "area",
                              data: choosen && choosen.details ? choosen.details.map((it) => it.execution_time_ms / 1000) : [],
                          },
                      ],

                      options: {
                          chart: {
                              events: {
                                  dataPointSelection: handleChartClick,
                              },
                              fontFamily: "Manrope, sans-serif",
                              type: "area",
                              zoom: {
                                  autoScaleYaxis: true,
                              },
                          },
                          tooltip: {
                              custom: (value) => {
                                  let color = choosen.details[value.dataPointIndex].status !== "FAIL" ? "#005B4D" : "#c65664";
                                  return (
                                      '<div class="apexcharts-tooltip-title" style="font-family: Manrope, sans-serif; font-size: 12px;"> ' +
                                      dayjs(choosen.details[value.dataPointIndex]?.start_time).format("YYYY-MM-DD HH:mm:ss") +
                                      ' </div> \
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color: #ccac5c;"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                        <span class="apexcharts-tooltip-text-y-label">Execution duration</span> \
                        <span class="apexcharts-tooltip-text-y-value">' +
                                      value.series[0][value.dataPointIndex] +
                                      's</span> \
                    </div> \
                </div> \
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color:' +
                                      color +
                                      '"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                        <span class="apexcharts-tooltip-text-y-label">Execution Status</span> \
                        <span class="apexcharts-tooltip-text-y-value">' +
                                      choosen.details[value.dataPointIndex].status +
                                      "</span> \
                    </div> \
                </div>"
                                  );
                              },
                          },
                          colors: ["#ccac5c"],
                          labels: {
                              style: {
                                  fontSize: "14px",
                              },
                          },

                          dataLabels: {
                              enabled: false,
                          },

                          grid: {
                              borderColor: "#DFE6E9",
                              row: {
                                  opacity: 0.5,
                              },
                          },

                          xaxis: {
                              title: {
                                  text: "Date",
                              },
                              type: "datetime",
                              stroke: {
                                  curve: "smooth",
                              },

                              tickPlacement: "between",
                              labels: {
                                  datetimeUTC: false,
                                  style: {
                                      fontSize: "14px",
                                  },
                              },
                              categories:
                                  choosen && choosen.details
                                      ? choosen.details.map((it) => dayjs(it.start_time).format("YYYY-MM-DD HH:mm"))
                                      : [],
                          },
                          yaxis: {
                              title: {
                                  text: "Duration",
                              },
                              labels: {
                                  style: {
                                      fontSize: "14px",
                                  },
                                  formatter: (value) => {
                                      return `${Number(value.toFixed(2))} s`;
                                  },
                              },
                              forceNiceScale: true,
                          },
                          markers: {
                              colors: ["#ccac5c"],
                              strokeWidth: 0,
                              size: 6,
                              hover: {
                                  sizeOffset: 1,
                              },
                          },
                      },
                  }
                : {};

        return (
            <>
                {testRunDetails ? (
                    loadingTcRuns && !testRun ? (
                        <Skeleton active paragraph={{ rows: 9 }} />
                    ) : store.metricsStore.suites.filter((it) => it.test_suite_name === choosen?.test_suite_name)[0]?.runs?.[testRun]
                          .length > 0 ? (
                        <BarVericalChart
                            data_chart={store.metricsStore.suites
                                .filter((it) => it.test_suite_name === choosen.test_suite_name)[0]
                                .runs[testRun].map((it) => {
                                    return { sname: it.name, value: it.execution_time_ms };
                                })
                                .sort((a, b) => b.value - a.value)}
                            serie_name="Duration"
                        />
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                ) : loading ? (
                    <Skeleton active paragraph={{ rows: 9 }} />
                ) : (
                    <div id="chart">
                        <Chart
                            options={data.options as unknown as ApexOptions}
                            series={data.series}
                            type="area"
                            height={350}
                            legend="legend"
                        />
                    </div>
                )}
            </>
        );
    }
);
