import { Space } from "antd";
import { FunctionComponent } from "react";
import {
    InputFilterItem,
    RequirementCoveredFilterItem,
    RequirementStatesFilterItem,
    RequirementTypesFilterItem,
    RequirementsFilterItem,
} from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const RequirementsFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap>
            <RequirementsFilterItem filters={filters} onChange={onChange} label="ID" keyOptions="work_item_id" />
            <InputFilterItem filters={filters} keyOptions="name" placeholder="Search requirement name...." onChange={onChange} />
            <RequirementTypesFilterItem filters={filters} onChange={onChange} />
            <RequirementStatesFilterItem filters={filters} onChange={onChange} />
            <RequirementCoveredFilterItem filters={filters} onChange={onChange} />
        </Space>
    );
};
