import { message } from "antd";
import { action, makeObservable, observable } from "mobx";
import PaginationStore from "./paginationStore";
class UsersStore extends PaginationStore {
    store;
    
    size = observable(10);

    users = [];
    error = {
        view: "",
        projects: "",
        new: "",
        edit: "",
        delete: "",
        reset_pass: "",
        assign: "",
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            users: observable,
            error: observable,
        });
    }

    setUsers = action((users) => {
        this.users = users.map((user) => ({
            ...user,
            roles: this.store.projectsStore.projectRoles.reduce((acc, role) => {
                role?.users?.forEach((roleUser) => {
                    if (roleUser.id === user.id) {
                        acc.push(role);
                    }
                });
                return acc;
            }, []),
        }));
    });

    setDeleteErrorMessage = action((err) => {
        this.error.delete = err;
    });

    setViewErrorMessage = action((err) => {
        this.error.view = err;
    });

    setProjectsErrorMessage = action((err) => {
        this.error.projects = err;
    });

    setNewErrorMessage = action((err) => {
        this.error.new = err;
    });

    setEditErrorMessage = action((err) => {
        this.error.edit = err;
    });

    setNewPassErrorMessage = action((err) => {
        this.error.reset_pass = err;
    });

    setAssignMessage = action((err) => {
        this.error.assign = err;
    });

    getAllUsers() {
        return this.store.apiStore
            .get({
                url: "/users/",
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setUsers(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to get users")))
            .catch((err) => this.setViewErrorMessage(err));
    }

    combineUsersFilters = (filters, type) => {
        let items = [];

        if (filters?.name) {
            items.push({
                column: "user.username",
                is_value_column: false,
                search_value: `%${filters.name}%`,
                search_type: "like",
            });
        }

        if (filters?.id) {
            items.push({ 
                column: "user.id", 
                is_value_column: false, 
                search_value: filters["id"], 
                search_type: "in",
            });
        }

        if (filters?.is_active) {
            filters.is_active.forEach((value) => {
                items.push({
                    column: "user.is_active",
                    is_value_column: false,
                    search_value: `${value ? "1" : "0"}`,
                    search_type: "eq",
                });
            });
        }

        return [
            {
                logical_opperand: type ? type : "and",
                items: items,
            },
        ];
    };

    searchUsers = action(({ filters, type = "and", pagination = false, page = 1, size = 10, returned = false }) => {
        let ready_filter = this.combineUsersFilters(filters, type);
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                body: JSON.stringify({
                    search: [{ table_name: "user" }],
                    filters: ready_filter,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                if (pagination) {
                    const users = data?.items || [];

                    this.setUsers(users);
                    this.setPage(data?.page || 1);
                    this.setTotal(data?.total || 0);
                    this.setSize(data?.size || 10);
                    this.setPagination(true);

                    return users;
                } else {
                    const users = data || [];

                    this.setUsers(users);
                    return users;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    createNewUserInProject = action((user) => {
        return this.store.apiStore
            .post({
                url: "/users/",
                body: JSON.stringify({
                    username: user?.username,
                    password: user?.password,
                    email: user?.email,
                    full_name: user?.full_name,
                    role_ids: user?.roles_ids,
                    project_ids: user?.project_ids,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                message.success(`User ${user?.username} created successully`);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to create user")))
            .catch((err) => this.setNewErrorMessage(err));
    });

    editUser = action((user_id, form) => {
        let forms = JSON.stringify(form);
        return this.store.apiStore
            .put({
                url: `/users/${user_id}`,
                body: forms,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to edit user")))
            .catch((err) => this.setEditErrorMessage(err));
    });

    deleteUser = action((user_id) => {
        return this.store.apiStore
            .delete({
                url: `/users/${user_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to edit user")))
            .catch((err) => this.setDeleteErrorMessage(err));
    });

    changeUserPassword = action((old_pass, new_pass, confirmation) => {
        return this.store.apiStore
            .post({
                url: `/users/user-password-reset`,
                body: JSON.stringify({
                    old_password: old_pass,
                    new_password: new_pass,
                    confirm_password: confirmation,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                message.success(data?.msg);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || data?.msg)))
            .catch((err) => this.setNewPassErrorMessage(err));
    });

    handleAssignUser = action((users) => {
        let body = {
            users: users.map((it) => {
                return {
                    email: it?.email,
                    roles: it?.role_ids,
                };
            }),
        };

        return this.store.apiStore
            .post({
                url: `/users/grant-access`,
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                message.success(data?.msg);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || data?.msg)))
            .catch((err) => this.setAssignMessage(err));
    });
}

export default UsersStore;
