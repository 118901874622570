import { useEffect, useState } from "react";
import { Task } from "../enums";
import { VariableType } from "../view/pages/executions/results/executions/shared/IndentifierTagVariables";

export const useDataFromDags = (store: any) => {
    const [isDataFromNodesFetched, setIsDataFromNodesFetched] = useState(false);

    useEffect(() => {
        if (Object.keys(store.dagStore.settings).length !== 2) {
            store.dagStore.getDagSettings("TRIGGERS");
        }
    }, [store.dagStore.settings]);

    const fetchDataByIds = async (idsMap: Record<string, any[]>) => {
        const {
            releaseIds = [],
            repoIds = [],
            dbIds = [],
            testSuiteIds = [],
            testCaseIds = [],
            testApproachIds = [],
            variables = [],
            tags = [],
        } = idsMap;

        const currentIds = {
            releaseIds: new Set(store.releaseStore.releases.map((release) => release.id)),
            repoIds: new Set(store.gitStore.repos.map((repo) => repo.id)),
            dbIds: new Set(store.settingsStore.databases.map((database) => database.id)),
            testSuiteIds: new Set(store.testSuiteStore.testsSuites.map((testSuite) => testSuite.id)),
            testCaseIds: new Set(store.testCaseStore.testsCases.map((testCase) => testCase.id)),
            testApproachIds: new Set(store.testApproachStore.testApproaches.map((testApproach) => testApproach.id)),
            variables: new Set(store.variablesStore.group_variables.map((variable) => variable.id)),
            tags: new Set(store.tagsStore.tags.map((tag) => tag.id)),
        };

        const tasks = [
            releaseIds.some((id) => !currentIds.releaseIds.has(id))
                ? store.releaseStore.searchRelease({ filters: { id: releaseIds }, pagination: false })
                : Promise.resolve(null),

            repoIds.some((id) => !currentIds.repoIds.has(id))
                ? store.settingsStore.searchConnectionDBRepo({ id: repoIds }, "repo", "or")
                : Promise.resolve(null),

            dbIds.some((id) => !currentIds.dbIds.has(id))
                ? store.settingsStore.searchConnectionDBRepo({ id: dbIds }, "connection", "or")
                : Promise.resolve(null),

            testSuiteIds.some((id) => !currentIds.testSuiteIds.has(id))
                ? store.testsStore.searchTestSuiteData({ filters: { id: testSuiteIds } })
                : Promise.resolve(null),

            testCaseIds.some((id) => !currentIds.testCaseIds.has(id))
                ? store.testsStore.searchTestCaseData({ filters: { id: testCaseIds }, pagination: false })
                : Promise.resolve(null),

            testApproachIds.some((id) => !currentIds.testApproachIds.has(id))
                ? store.testsStore.searchTestApproachData({ filters: { id: testApproachIds } })
                : Promise.resolve(null),

            variables.some((id) => !currentIds.variables.has(id))
                ? Promise.all([
                      store.variablesStore.searchVariableWithValuesById(variables),
                      store.variablesStore.searchGroupById(variables),
                  ])
                : Promise.resolve(null),

            tags.some((tag) => !currentIds.tags.has(tag))
                ? store.tagsStore.searchTags({ tag_names: tags, save: true, pagination: false })
                : Promise.resolve(null),
        ];

        return await Promise.allSettled(tasks);
    };

    const extractIdsFromNode = (node: any, acc: any) => {
        if (node.kwargs.release_id) acc.releaseIds.add(node.kwargs.release_id);

        if (node.kwargs.filters) {
            node.kwargs.filters.forEach((filter) => {
                if (filter.repo_id) acc.repoIds.add(filter.repo_id);
                if (filter.db_id) acc.dbIds.add(filter.db_id);
                filter.test_suites?.forEach((id) => id && acc.testSuiteIds.add(id));
                filter.test_cases?.forEach((id) => id && acc.testCaseIds.add(id));
                filter.test_approaches?.forEach((id) => id && acc.testApproachIds.add(id));
                filter.test_tags?.forEach((tag) => tag && acc.tags.add(tag));
            });
        }

        const { source_db_config_id, target_db_config_id } = node.kwargs?.reconciliation_settings || {};
        const variables = node.kwargs?.runtime_variables?.variables_group_id;
        if (source_db_config_id) acc.dbIds.add(source_db_config_id);
        if (target_db_config_id) acc.dbIds.add(target_db_config_id);
        if (variables) acc.variables.add(variables);
    };

    const gatherDataFromNodes = (nodes: any[]) => {
        const idsMap = nodes.reduce(
            (acc, node) => {
                if (node.items) {
                    node.items.forEach((item) => extractIdsFromNode(item, acc));
                } else if (node.parameters) extractIdsFromNode(node.parameters, acc);

                return acc;
            },
            {
                releaseIds: new Set(),
                repoIds: new Set(),
                dbIds: new Set(),
                testSuiteIds: new Set(),
                testCaseIds: new Set(),
                testApproachIds: new Set(),
                variables: new Set(),
                tags: new Set(),
            }
        );

        return fetchDataByIds({
            releaseIds: Array.from(idsMap.releaseIds),
            repoIds: Array.from(idsMap.repoIds),
            dbIds: Array.from(idsMap.dbIds),
            testSuiteIds: Array.from(idsMap.testSuiteIds),
            testCaseIds: Array.from(idsMap.testCaseIds),
            testApproachIds: Array.from(idsMap.testApproachIds),
            variables: Array.from(idsMap.variables),
            tags: Array.from(idsMap.tags),
        }).then(() => setIsDataFromNodesFetched(true));
    };

    const getWorkflowNode = (node: any) => {
        const testSuiteMap = new Map(store.testSuiteStore.testsSuites.map((ts) => [ts.id, ts]));
        const testCaseMap = new Map(store.testCaseStore.testsCases.map((tc) => [tc.id, tc]));
        const testApproachMap = new Map(store.testApproachStore.testApproaches.map((ta) => [ta.id, ta]));

        const nodeItem = node.items?.[0];
        const nodeFilters = nodeItem.kwargs.filters
            ? nodeItem.kwargs.filters.reduce(
                  (acc, filter) => {
                      acc = {
                          db_id: acc.db_id || filter.db_id,
                          repo_id: acc.repo_id || filter.repo_id,
                          tags: [],
                          separate_tags: acc.separate_tags || filter.separate_tags,
                          test_suite: filter.test_suites.map((item) => testSuiteMap.get(item)),
                          test_case: filter.test_cases.map((item) => testCaseMap.get(item)),
                          test_approach: filter.test_approaches.map((item) => testApproachMap.get(item)),
                      };

                      if (filter.test_tags?.length) {
                          acc.tags.push(...filter.test_tags);
                      }

                      return acc;
                  },
                  {
                      db_id: null,
                      repo_id: null,
                      tags: [],
                      separate_tags: false,
                      test_suite: [],
                      test_case: [],
                      test_approach: [],
                  }
              )
            : null;

        if (nodeFilters?.repo_id) {
            store.webExecutionStore.setRepo(nodeFilters.repo_id);
        }

        const runtimeVariablesGroupId = nodeItem.kwargs.runtime_variables?.variables_group_id;
        const runtimeVariablesByGroupId =
            runtimeVariablesGroupId &&
            store.variablesStore.variables[runtimeVariablesGroupId]?.filter((item) => item.variable_type === VariableType.INPUT);

        const runtimeVariablesGroup = store.variablesStore.group_variables.find((group) => group.id === runtimeVariablesGroupId);
        const dagRuntimeVariables = runtimeVariablesGroupId && {
            group_name: runtimeVariablesGroup?.persistent ? runtimeVariablesGroup.group_name : null,
            persistent: !!runtimeVariablesGroup?.persistent,
            variables: runtimeVariablesByGroupId,
        };
        const runtimeVariables = nodeItem.kwargs.runtime_variables?.variable_group || dagRuntimeVariables;

        return {
            id: node.name,
            user_defined_name: node.user_defined_name,
            name: node.name,
            flow_type: "workflow",
            task: nodeItem.function,
            condition: node.trigger_conditions,
            parameters_mode: nodeItem.parameters_mode,
            release_id: nodeItem.kwargs.release_id,
            ...(nodeItem.function === Task.RECON && {
                settings: {
                    generate_only: nodeItem.kwargs.generate_only,
                    reconciliation_settings: nodeItem.kwargs.reconciliation_settings,
                    release_id: nodeItem.kwargs.release_id,
                    execution_name: nodeItem.kwargs.execution_name,
                },
            }),
            ...(nodeFilters && {
                repo_id: nodeFilters.repo_id,
                db_config_id: nodeFilters.db_id,
                tags: { tags: nodeFilters.tags, separate_tags: nodeFilters.separate_tags },
            }),
            ...(runtimeVariables && {
                runtime_variables: {
                    variables: runtimeVariables.variables?.map((variable) => ({
                        key: variable.variable_name,
                        value: variable.variable_value,
                    })),
                    v_group: {
                        persistent: runtimeVariables.persistent,
                        name: runtimeVariables.group_name,
                    },
                },
            }),
            ...(nodeFilters.test_suite.length && { test_suite: nodeFilters.test_suite }),
            ...(nodeFilters.test_approach.length && { test_approach: nodeFilters.test_approach }),
            ...(nodeFilters.test_case.length && { test_case: nodeFilters.test_case }),
            ...(nodeItem?.function === Task.PROFILING && { ...nodeItem.kwargs }),
            ...(nodeItem?.function === Task.METADATA && { ...nodeItem.kwargs.settings }),
        };
    };

    const getNodeData = (node: any) => {
        const testSuiteMap = new Map(store.testSuiteStore.testsSuites.map((ts) => [ts.id, ts]));
        const testCaseMap = new Map(store.testCaseStore.testsCases.map((tc) => [tc.id, tc]));
        const testApproachMap = new Map(store.testApproachStore.testApproaches.map((ta) => [ta.id, ta]));
        const tagMap = new Map(store.tagsStore.tags.map((tag) => [tag.tag, tag]));

        const nodeItem = node.parameters;
        const nodeFilters = nodeItem.kwargs.filters
            ? nodeItem.kwargs.filters.reduce(
                  (acc, filter) => {
                      acc = {
                          test_suite: filter.test_suites.map((item) => testSuiteMap.get(item)),
                          test_case: filter.test_cases.map((item) => testCaseMap.get(item)),
                          test_approach: filter.test_approaches.map((item) => testApproachMap.get(item)),
                          tags: filter.test_tags.map((item) => tagMap.get(item)),
                      };

                      return acc;
                  },
                  {
                      test_suite: [],
                      test_case: [],
                      test_approach: [],
                      tags: [],
                  }
              )
            : null;

        const release = store.releaseStore.releases.find((release) => release.id === nodeItem.kwargs.release_id);
        const repo = store.gitStore.repos.find((repo) => repo.id === nodeItem?.kwargs?.filters?.[0]?.repo_id);
        const database = store.settingsStore.databases.find(
            (db) =>
                db.db_config_id === nodeItem.kwargs?.db_config_id ||
                nodeItem?.kwargs?.filters?.[0]?.db_id ||
                nodeItem.kwargs?.settings?.db_config_id
        );

        return {
            release,
            repo,
            database,
            testSuites: nodeFilters?.test_suite || [],
            testCases: nodeFilters?.test_case || [],
            testApproaches: nodeFilters?.test_approach || [],
            tags: nodeFilters?.tags || [],
        };
    };

    return { isDataFromNodesFetched, gatherDataFromNodes, getWorkflowNode, getNodeData };
};
