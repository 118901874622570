import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";
class ProjectsStore extends PaginationStore {
    store;

    projects = [];
    projectRoles = [];

    constructor(store) {
        super();
        this.store = store;

        observable(this, {
            projects: observable,
            projectRoles: observable,
        });
    }

    getProjectId = () => {
        return this.projects.find(
            (project) => project.project_name.toLowerCase() === this.store.authStore.user?.active_project_name?.toLowerCase()
        )?.id;
    };

    setProjects = action((rel) => {
        this.projects = rel;
    });

    setProjectRoles = action((projectRoles) => {
        this.projectRoles = projectRoles;
    });

    getAppProjects = action(() => {
        return this.store.apiStore
            .get({
                url: `/projects/`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setProjects(data);
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchProject = action((name) => {
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: true, page: 1, size: 10 }),
                body: JSON.stringify({
                    search: [{ table_name: "project" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                { column: "project.project_name", is_value_column: false, search_value: `%${name}%`, search_type: "like" },
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return data?.items;
                } else {
                    return [];
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchProjectRoles = action(({ filters, type = "and", pagination = false, page = 1, size = 10, returned = false }) => {
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                body: JSON.stringify({
                    search: [{ table_name: "project_roles" }],
                    filters: [
                        {
                            logical_opperand: type,
                            items: [
                                ...(filters?.projectId
                                    ? [
                                          {
                                              column: "project_roles.project_id",
                                              is_value_column: false,
                                              search_value: filters.projectId,
                                              search_type: "eq",
                                          },
                                      ]
                                    : []),
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                if (pagination) {
                    const roles = data?.items || [];

                    this.setProjectRoles(roles);
                    this.setPage(data?.page || 1);
                    this.setTotal(data?.total || 0);
                    this.setSize(data?.size || 10);
                    this.setPagination(true);

                    return roles;
                } else {
                    const roles = data || [];

                    this.setProjectRoles(roles);
                    return roles;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });
}

export default ProjectsStore;
