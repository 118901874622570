import { lazy } from "react";

export const PublicRoutes = [
    {
        path: "/login",
        component: lazy(() => import("../../view/pages/authentication/login/index")),
        layout: "FullLayout",
    },
    {
        path: "/recover-password",
        component: lazy(() => import("../../view/pages/authentication/recover-password")),
        layout: "FullLayout",
    },
    {
        path: "/reset-password",
        component: lazy(() => import("../../view/pages/authentication/reset-password")),
        layout: "FullLayout",
    },
];
