import { Button, Dropdown } from "antd";
import React from "react";
import { RiAddLine } from "react-icons/ri";
import { FilterMenuItemType } from "./types";

interface Props {
    items: FilterMenuItemType[];
    id?: string;
    label?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
}

export const FilterMenu: React.FC<Props> = ({
    items,
    id = "new-button",
    label = "New",
    icon = <RiAddLine className="remix-icon" />,
    disabled = false,
}) => {
    return (
        <Dropdown menu={{ items }} disabled={disabled}>
            <Button id={id} className="modal-button" style={{ marginLeft: 20 }} icon={icon} type="primary">
                {label}
            </Button>
        </Dropdown>
    );
};
