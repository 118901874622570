import { Avatar, Button, Col, Drawer, Layout, Menu, Row, Space } from "antd";
import { createElement, useState } from "react";
import { RiCloseFill, RiLogoutBoxLine, RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import logoSmall from "../../../assets/images/logo/logo.png";
import { AUTH_TOKEN } from "../../../enums";
import { NavigationPageType, navigationPages } from "../../../navigation";
import { AllowedRoles } from "../../../router/AllowedRoles";
import { Roles } from "../../../router/types";
import { useStore } from "../../../stores/StoreContext";
import { RoleType } from "../../../types";
import MenuFooter from "./footer/index";
import MenuLogo from "./logo";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Sidebar(props) {
    const store = useStore();
    const { visible, setVisible } = props;
    const [collapsed, setCollapsed] = useState(localStorage.getItem("sidebar_hide") === "true" ?? false);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/");

    // Mobile Sidebar
    const onClose = () => {
        setVisible(false);
    };

    const onLogout = () => {
        localStorage.removeItem(AUTH_TOKEN);
        onClose();
    };

    // Menu
    function toggle() {
        setCollapsed(!collapsed);
        localStorage.setItem("sidebar_hide", `${!collapsed}`);
    }

    const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
        className: "trigger",
        onClick: toggle,
    });

    const resolvedItemLink = (item: NavigationPageType): Roles[] => (item.externalLink ? [Roles.ANY] : AllowedRoles[item.navLink]);

    const resolvedRolePermission = (item: NavigationPageType): boolean => {
        const ResolvedAllowedRoles =
            (item.navLink
                ? resolvedItemLink(item)
                : Array.from(
                      new Set(item.children.map((childrenItem: NavigationPageType) => resolvedItemLink(childrenItem)).flat())
                  ).filter(Boolean)) || [];

        return (
            ResolvedAllowedRoles.includes(Roles.ANY) ||
            !!store.authStore?.user?.roles?.some((role: RoleType) => ResolvedAllowedRoles.includes(role.role_name.toLowerCase() as Roles))
        );
    };

    const resolvedMenuItem = (item: NavigationPageType, className: string) => {
        if (!resolvedRolePermission(item)) return null;

        return (
            <Menu.Item key={item.id} icon={item.icon} className={className} onClick={onClose}>
                {item.externalLink ? (
                    <a target="_blank" rel="noopener noreferrer" href={item.navLink}>
                        {item.title}
                    </a>
                ) : (
                    <Link to={item.navLink}>{item.title}</Link>
                )}
            </Menu.Item>
        );
    };

    const splitLocationUrl = splitLocation[splitLocation.length - 2] + "/" + splitLocation[splitLocation.length - 1];

    const menuItems = navigationPages.map((item) => {
        if (item.children) {
            let children_submenu = (
                <SubMenu key={item.id} icon={item.icon} title={item.title}>
                    {item.children.map((childrens) => {
                        const childrenNavLink = childrens.navLink.split("/");
                        return resolvedMenuItem(
                            childrens,
                            splitLocationUrl ===
                                childrenNavLink[childrenNavLink.length - 2] + "/" + childrenNavLink[childrenNavLink.length - 1]
                                ? "ant-menu-item-selected children-item"
                                : "ant-menu-item-selected-in-active children-item"
                        );
                    })}
                </SubMenu>
            );
            return resolvedRolePermission(item) ? children_submenu : null;
        } else {
            const itemNavLink = item.navLink.split("/");
            return resolvedMenuItem(
                item,
                item.id === "profile"
                    ? splitLocation[splitLocation.length - 3] + "/" + splitLocation[splitLocation.length - 2] ===
                      itemNavLink[itemNavLink.length - 3] + "/" + itemNavLink[itemNavLink.length - 2]
                        ? "ant-menu-item-selected"
                        : "ant-menu-item-selected-in-active"
                    : splitLocation[splitLocation.length - 2] + "/" + splitLocation[splitLocation.length - 1] ===
                      itemNavLink[itemNavLink.length - 2] + "/" + itemNavLink[itemNavLink.length - 1]
                    ? "ant-menu-item-selected"
                    : "ant-menu-item-selected-in-active"
            );
        }
    });

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light" width={256} className="da-sidebar">
            <Row className="da-mr-12 da-ml-24 da-mt-24" align="bottom" justify="space-between">
                <Space style={collapsed === false ? { marginLeft: 15 } : null}>
                    <Col>{collapsed === false ? <MenuLogo store={store} onClose={onClose} /> : ""}</Col>

                    <Col className="da-pr-0">
                        <Space style={{ padding: 10 }}>
                            <Button icon={trigger} type="text" className="da-float-right"></Button>
                        </Space>
                    </Col>
                </Space>

                {collapsed !== false && (
                    <Space style={{ padding: 10, marginLeft: 5 }}>
                        <Col className="da-mt-8">
                            <Link to="/" onClick={onClose}>
                                <img className="da-logo" style={{ height: 50 }} src={logoSmall} alt="logo" />
                            </Link>
                        </Col>
                    </Space>
                )}
            </Row>

            <Menu
                mode="inline"
                defaultOpenKeys={[
                    splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
                    splitLocation[splitLocation.length - 2],
                ]}
            >
                {menuItems}
            </Menu>

            {collapsed === false ? (
                <MenuFooter onClose={onClose} />
            ) : (
                <Row className="da-sidebar-footer da-py-16" align="middle" justify="center">
                    <Col>
                        <Link to="/login" onClick={onLogout}>
                            <Avatar icon={<RiLogoutBoxLine className="remix-icon" />} />
                        </Link>
                    </Col>
                </Row>
            )}

            <Drawer
                title={<MenuLogo store={store} onClose={onClose} />}
                className="da-mobile-sidebar"
                placement="left"
                closable={true}
                onClose={onClose}
                open={visible}
                closeIcon={<RiCloseFill className="remix-icon da-text-color-black-80" size={24} />}
            >
                <Menu
                    mode="inline"
                    defaultOpenKeys={[
                        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
                        splitLocation[splitLocation.length - 2],
                    ]}
                >
                    {menuItems}
                </Menu>
                <MenuFooter onClose={onClose} />
            </Drawer>
        </Sider>
    );
}
