import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { TEST_TYPE_TECHNOLOGY } from "../enums";
import { PagePath } from "../router/types";
import { useStore } from "../stores/StoreContext";
import { mapTestCaseToDqConfigs } from "../view/pages/data_quality/new/dqMappingUtils";
import { Release } from "../view/types/releaseType";
import { TestCase } from "../view/types/testCaseType";

export const useExecutionsHooks = (setIsLoading?: (value: boolean) => void) => {
    const store = useStore();
    const history = useHistory();

    const setExecutionsCaseDataForRelease = (item: TestCase, release: Release) => {
        if (setIsLoading) setIsLoading(false);

        store.dbExecutionStore.reset();
        store.executionStore.setQuickTrigger(true);
        store.executionStore.setTechnology([item.test_type]);
        store.executionStore.setRelease(release.id);
        store.executionStore.setExecutionName(`${release.name}-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`);

        if (item?.test_type === TEST_TYPE_TECHNOLOGY.CUSTOM) {
            store.webExecutionStore.setRepo(item?.robot_test_case?.git_repo?.id);
        }

        if (item?.test_type === TEST_TYPE_TECHNOLOGY.DQ) {
            const mappedItem = mapTestCaseToDqConfigs(item);
            store.dbExecutionStore.setConnection(mappedItem?.db_config_id);
        }

        // to run only one suite with choosen test case
        store.dbExecutionStore.setSuites([item.test_suites[0]]);
        store.dbExecutionStore.setTestCases([item]);

        store.executionStore.setTestType(item.test_type);

        return history.push("/executions/new");
    };

    const handleTrigger = async (item: TestCase) => {
        if (setIsLoading) setIsLoading(true);

        try {
            const release = await store.releaseStore.getAdHocRelease();

            if (release.length === 0) {
                await store.releaseStore.createNewRelease({ name: "ad_hoc", desc: "release to quick trigger" });
                const finalRelease = await store.releaseStore.getAdHocRelease();
                setExecutionsCaseDataForRelease(item, finalRelease[0]);
            }

            return setExecutionsCaseDataForRelease(item, release[0]);
        } catch {
            if (setIsLoading) setIsLoading(false);
        }
    };

    return { setExecutionsCaseDataForRelease, handleTrigger };
};
