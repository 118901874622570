import { Badge, Button, Card, Col, Divider, Dropdown, Row, Space, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { DebouncedInput } from "../../../../view/shared/DebouncedInput";
import { FilterOptions } from "./FilterOptions";
import { findFilter } from "./utils";

type DropdownButtonFilterType = {
    options: any[];
    values: string | string[];
    buttonLabel: string;
    keyOptions: string;
    loading: boolean;
    onSave: (data: any[]) => void;
    setOptions: (e: any, a: string) => void;
    searchLocal?: boolean;
    getOptions?: (filter_value: any, page?: number) => void;
    radio?: boolean;
    pageCount?: number;
    showSearchInput?: boolean;
};

export const DropdownButtonFilter: React.FC<DropdownButtonFilterType> = ({
    options,
    values,
    buttonLabel,
    keyOptions = "id",
    loading,
    searchLocal,
    setOptions,
    getOptions,
    onSave,
    radio = false,
    pageCount = 1,
    showSearchInput = true,
}) => {
    const hasMounted = useRef(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");

    useEffect(() => {
        if ((values?.length || filterSearch) && getOptions && !hasMounted.current) {
            hasMounted.current = true;
            getOptions({ [keyOptions]: values.length ? values : [filterSearch] });
        }
    }, [filterSearch, values]);

    const handleSearch = (filters: any) => {
        if (!searchLocal) {
            if (getOptions) getOptions(filters["filters"]);
        }
    };

    let selectedOptions = Array.isArray(values)
        ? findFilter(values, keyOptions).length > 0
            ? findFilter(values, keyOptions)
            : values || []
        : [];

    let filteredOptions = options?.filter((option) => {
        if (!filterSearch) {
            return option.name;
        }

        return (
            option?.name?.toLowerCase().includes(filterSearch?.toLowerCase()) ||
            option?.value?.toString()?.includes(filterSearch) ||
            option?.friendly_id?.includes(filterSearch)
        );
    });

    return (
        <Spin spinning={loading}>
            <Dropdown
                placement="bottom"
                onOpenChange={(open) => setDropdownOpen(open)}
                open={dropdownOpen}
                key={`${keyOptions}-dropdown`}
                dropdownRender={() => (
                    <Card className={"card-padding-10"} bordered={false} style={{ padding: 10, width: 400 }}>
                        <Row>
                            <Col span={24}>
                                {showSearchInput && (
                                    <>
                                        <DebouncedInput
                                            allowClear
                                            id={`input-${keyOptions}-search`}
                                            value={filterSearch}
                                            placeholder={`Search ${keyOptions.replace("_", " ")}`}
                                            addonBefore={<AiOutlineSearch />}
                                            onChange={(value) => setFilterSearch(value)}
                                        />

                                        <Divider />
                                    </>
                                )}
                                <FilterOptions
                                    selectedOptions={selectedOptions}
                                    options={filteredOptions}
                                    isRadio={radio}
                                    loading={loading}
                                    keyOptions={keyOptions}
                                    pageCount={pageCount}
                                    setOptions={setOptions}
                                    onLoadPage={(page) => {
                                        if (getOptions) {
                                            getOptions({ ...(filterSearch && { id: [filterSearch] }) }, page);
                                        }
                                    }}
                                    onChange={() => {
                                        if (searchLocal) {
                                            setFilterSearch("");
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row style={{ justifyContent: "end", marginTop: 20 }}>
                            <Button
                                id={`button-${keyOptions}-reset`}
                                style={{ justifyContent: "end", marginRight: 20 }}
                                onClick={() => {
                                    setFilterSearch("");
                                    setOptions([], keyOptions);

                                    if (!searchLocal) {
                                        handleSearch({
                                            filters: { name: [], [keyOptions]: [] },
                                        });
                                    }

                                    onSave([]);
                                }}
                            >
                                Reset
                            </Button>

                            <Button
                                id={`button-${keyOptions}-save`}
                                type="primary"
                                onClick={() => {
                                    setDropdownOpen(false);
                                    onSave(selectedOptions);
                                }}
                            >
                                Save
                            </Button>
                        </Row>
                    </Card>
                )}
            >
                <Button className={keyOptions}>
                    <Space>
                        <>
                            {buttonLabel}
                            {selectedOptions?.length > 0 && <Badge count={selectedOptions?.length} />}
                        </>
                    </Space>
                </Button>
            </Dropdown>
        </Spin>
    );
};
