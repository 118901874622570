import { Input } from "antd";
import { isEqual } from "lodash";
import { FC, useEffect, useState } from "react";
import { useDebouncedValue } from "../../../../../hooks";

interface Props {
    filters: any;
    keyOptions: string;
    placeholder: string;
    onChange: (value: Record<string, string>) => void;
    id?: string;
    defaultFilters?: any;
}

export const InputFilterItem: FC<Props> = ({ filters, keyOptions, id = "search-input", placeholder, onChange, defaultFilters = {} }) => {
    const [inputValue, setInputValue] = useState(filters[keyOptions] || "");
    const debouncedName = useDebouncedValue(inputValue, 500);

    useEffect(() => {
        if (!filters[keyOptions] || isEqual(filters, defaultFilters)) {
            setInputValue("");
        }
    }, [filters]);

    useEffect(() => {
        if (inputValue || (!inputValue && filters[keyOptions])) {
            onChange({ [keyOptions]: inputValue });
        }
    }, [debouncedName]);

    return (
        <Input
            id={id}
            allowClear
            value={inputValue}
            style={{ padding: 8, width: 300 }}
            placeholder={placeholder}
            onChange={(e) => setInputValue(e.target.value)}
        />
    );
};
