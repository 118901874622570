export const findFilter = (items: any[], name: string) => {
    if (!Array.isArray(items)) {
        return items?.[name] || [];
    }

    let a = items.filter((item: any) => {
        return Object.entries(item).find((i) => i[0] === name);
    });
    if (a.length > 0) {
        return a[0][name];
    } else {
        return [];
    }
};
