import { observer } from "mobx-react";
import { Suspense } from "react";

// Hooks
import { useAuth } from "../hooks";

// Router
import { BrowserRouter as AppRouter, Route, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";

// Routes
import { PrivateRoutes, PublicRoutes } from "./routes";

// Layouts
import FullLayout from "../layout/FullLayout";
import VerticalLayout from "../layout/VerticalLayout";

// Components
import { Spin } from "antd";
import { useStore } from "../stores/StoreContext";
import HomePage from "../view/home";
import Error404 from "../view/pages/error";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";

const Router = observer(() => {
    const store = useStore();
    useAuth(store);

    // Default Layout
    const DefaultLayout = null; // FullLayout or VerticalLayout

    // All of the available layouts
    const Layouts = { VerticalLayout, FullLayout };

    // Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout, type) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];

        if (PrivateRoutes && type === "protected") {
            PrivateRoutes.forEach(
                (route) => (route.layout === layout || DefaultLayout === layout) && (LayoutRoutes.push(route), LayoutPaths.push(route.path))
            );
        }

        if (PublicRoutes && type === "public") {
            PublicRoutes.forEach(
                (route) => (route.layout === layout || DefaultLayout === layout) && (LayoutRoutes.push(route), LayoutPaths.push(route.path))
            );
        }

        return { LayoutRoutes, LayoutPaths };
    };

    // Return Route to Render
    const ResolvePublicRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout, "public");
            const LayoutTag = Layouts[layout];

            return (
                <PublicRoute path={LayoutPaths} store={store} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact === true}
                                    render={(props) => (
                                        <Suspense fallback={null}>
                                            <route.component {...props} />
                                        </Suspense>
                                    )}
                                />
                            ))}
                        </Switch>
                    </LayoutTag>
                </PublicRoute>
            );
        });
    };

    const ResolveProtectedRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout, "protected");
            const LayoutTag = Layouts[layout];

            return (
                <PrivateRoute key={index} store={store} path={LayoutPaths}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => (
                                <ProtectedRoute
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact === true}
                                    store={store}
                                    render={(props) => (
                                        <Suspense fallback={null}>
                                            <route.component {...props} />
                                        </Suspense>
                                    )}
                                />
                            ))}
                        </Switch>
                    </LayoutTag>
                </PrivateRoute>
            );
        });
    };

    return (
        <AppRouter>
            <Spin spinning={store.authStore.isUserLoading} style={{ minHeight: "100vh" }}>
                <Switch>{ResolvePublicRoutes()}</Switch>

                {store.authStore.user && (
                    <Switch>
                        {ResolveProtectedRoutes()}

                        {/* should stay  */}
                        <PrivateRoute
                            exact
                            path={"/"}
                            store={store}
                            render={() => {
                                return (
                                    <Layouts.VerticalLayout>
                                        <HomePage />
                                    </Layouts.VerticalLayout>
                                );
                            }}
                        />

                        {/* NotFound */}
                        <Route path="*">
                            <Error404 />
                        </Route>
                    </Switch>
                )}
            </Spin>
        </AppRouter>
    );
});

export default Router;
