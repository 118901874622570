import { AutoComplete, Badge, Input, Space, Spin, Typography } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDebouncedValue } from "../../../hooks";
import { useStore } from "../../../stores/StoreContext";

const HeaderSearch: React.FC = observer(() => {
    const store = useStore();
    const history = useHistory();
    const hasMounted = useRef(false);
    const [resultsLoading, setResultsLoading] = useState({
        cases: false,
        suites: false,
        approaches: false,
        requirements: false,
    });
    const [searchText, setSearchText] = useState("");
    const [caseOptions, setCaseOptions] = useState([]);
    const [suiteOptions, setSuiteOptions] = useState([]);
    const [approachOptions, setApproachOptions] = useState([]);
    const [requirementOptions, setRequirementOptions] = useState([]);

    const debouncedSearchText = useDebouncedValue(searchText, 500);

    useEffect(() => {
        store.intervalStore.registerWatcher();
        return () => {
            store.intervalStore.unregisterWatcher();
            store.testCaseStore.setTestCases([]);
            store.testApproachStore.setTestApproaches([]);
            store.testSuiteStore.setTestSuites([]);
            store.requirementStore.setRequirements([]);
        };
    }, []);

    useEffect(() => {
        if (hasMounted.current) {
            fetchData();
        } else {
            hasMounted.current = true;
        }
    }, [debouncedSearchText]);

    const onChange = (value) => {
        setSearchText(value);
        store.searchStore.setSearchText(value);
    };

    const fetchData = async () => {
        setResultsLoading({ cases: true, suites: true, approaches: true, requirements: true });

        setCaseOptions(
            await store.searchStore
                .searchHeaderData("case", searchText)
                .finally(() => setResultsLoading((previousResultsLoading) => ({ ...previousResultsLoading, cases: false })))
        );
        setSuiteOptions(
            await store.searchStore
                .searchHeaderData("suite", searchText)
                .finally(() => setResultsLoading((previousResultsLoading) => ({ ...previousResultsLoading, suites: false })))
        );
        setApproachOptions(
            await store.searchStore
                .searchHeaderData("approach", searchText)
                .finally(() => setResultsLoading((previousResultsLoading) => ({ ...previousResultsLoading, approaches: false })))
        );
        setRequirementOptions(
            await store.searchStore
                .searchHeaderData("requirement", searchText)
                .finally(() => setResultsLoading((previousResultsLoading) => ({ ...previousResultsLoading, requirements: false })))
        );
    };

    const handleClick = (item) => {
        let category = item?.toLowerCase().split("-")[0];
        let id = item?.toLowerCase().split("-")[1];
        store.searchStore.obj.item = {};
        if (category === "suite") {
            store.searchStore.setSearchItemObjectCategory("suite");
            store.searchStore.obj.item.id = Number(id);
            history.push("/tests/suites");
        } else if (category === "case") {
            store.searchStore.setSearchItemObjectCategory("case");
            store.searchStore.obj.item.id = Number(id);
            history.push("/tests/cases");
        } else if (category === "approach") {
            store.searchStore.setSearchItemObjectCategory("approach");
            store.searchStore.obj.item.id = Number(id);
            history.push("/tests/approaches");
        } else if (category === "requirement") {
            store.searchStore.setSearchItemObjectCategory("requirement");
            store.searchStore.obj.item.id = id;
            history.push("/requirements");
        }
        setSearchText("");
    };

    const renderTitle = (title, href, amount, isLoading) => (
        <>
            <Badge count={isLoading ? 0 : amount} offset={[17, 5]}>
                <Typography.Text strong>{title}</Typography.Text>
            </Badge>
            <div style={{ float: "right" }}>{isLoading ? <Spin /> : <a href={href}>more</a>}</div>
        </>
    );

    const renderItem = (category, friendly_id, title, id) => ({
        value: `${category}-${id}`,
        key: id,
        label: (
            <Space className={category}>
                <Typography.Text type="secondary">{friendly_id}</Typography.Text>
                <Typography>{title}</Typography>
            </Space>
        ),
    });

    const options = [];

    if (typeof store.searchStore.searchText === "string" && store.searchStore.searchText) {
        if (resultsLoading.cases || caseOptions?.length) {
            options.push({
                label: renderTitle("Test Cases", "/tests/cases", caseOptions.length, resultsLoading.cases),
                options: !resultsLoading.cases ? caseOptions.map((opt) => renderItem("case", opt.friendly_id, opt.name, opt.id)) : [],
            });
        }

        if (resultsLoading.suites || suiteOptions?.length) {
            options.push({
                label: renderTitle("Test Suites", "/tests/suites", suiteOptions.length, resultsLoading.suites),
                options: !resultsLoading.suites ? suiteOptions.map((opt) => renderItem("suite", opt.friendly_id, opt.name, opt.id)) : [],
            });
        }

        if (resultsLoading.approaches || approachOptions?.length) {
            options.push({
                label: renderTitle("Test Approach", "/tests/approaches", approachOptions.length, resultsLoading.approaches),
                options: !resultsLoading.approaches
                    ? approachOptions.map((opt) => renderItem("approach", opt.friendly_id, opt.name, opt.id))
                    : [],
            });
        }

        if (resultsLoading.requirements || requirementOptions?.length) {
            options.push({
                label: renderTitle("Requirements", "/requirements", requirementOptions.length, resultsLoading.requirements),
                options: !resultsLoading.requirements
                    ? requirementOptions.map((opt) => renderItem("requirement", opt.friendly_id, opt.name, opt.work_item_id))
                    : [],
            });
        }
    }

    return (
        <AutoComplete
            value={searchText}
            placeholder="Search..."
            notFoundContent={"No matches"}
            onChange={(val) => onChange(val)}
            onSelect={(item) => handleClick(item)}
            popupMatchSelectWidth={500}
            dropdownStyle={{ padding: 10 }}
            style={{ width: "100%" }}
            options={options}
        >
            <Input className="search" width={"15%"} />
        </AutoComplete>
    );
});

export default withRouter(HeaderSearch);
