import { Space } from "antd";
import { FunctionComponent } from "react";
import {
    AggregationsFilterItem,
    DbConnectionFilterItem,
    DistinctValuesFilterItem,
    DqRulesFilterItem,
    InputFilterItem,
    StatusFilterItem,
    TagsFilterItem,
    TestApproachesFilterItem,
    TestSuitesFilterItem,
    UsersFilterItem,
} from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const DQResultsFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange, defaultFilters }) => {
    return (
        <Space wrap>
            <DqRulesFilterItem filters={filters} label="ID" onChange={onChange} />
            <InputFilterItem
                filters={filters}
                keyOptions="name"
                placeholder="Search rule name...."
                onChange={onChange}
                defaultFilters={defaultFilters}
            />
            <DbConnectionFilterItem filters={filters} onChange={onChange} keyOptions="connection_ids" />
            <StatusFilterItem filters={filters} onChange={onChange} />
            <DistinctValuesFilterItem
                filters={filters}
                table="database_metadata"
                column="schema"
                label="Database Schema"
                keyOptions="db_schema"
                onChange={onChange}
            />
            <DistinctValuesFilterItem
                filters={filters}
                table="database_metadata"
                column="table_name"
                label="Database Table"
                keyOptions="db_table"
                onChange={onChange}
            />
            <DistinctValuesFilterItem
                filters={filters}
                table="database_metadata"
                column="column_name"
                label="Database Column"
                keyOptions="db_column"
                onChange={onChange}
            />
            <AggregationsFilterItem filters={filters} keyOptions="aggregation_type" onChange={onChange} />
            <TestSuitesFilterItem filters={filters} onChange={onChange} />
            <TestApproachesFilterItem filters={filters} onChange={onChange} />
            <TagsFilterItem filters={filters} onChange={onChange} />
            <UsersFilterItem filters={filters} onChange={onChange} />
        </Space>
    );
};
