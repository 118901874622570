import { action, makeObservable, observable } from "mobx";
import PaginationStore from "./paginationStore";

class GitStore extends PaginationStore {
    store;

    repos = [];

    error = {
        new: "",
        edit: "",
        delete: "",
        import: "",
        refresh: "",
    };

    info = {
        import: "",
        refresh: "",
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            error: observable,
            repos: observable,
        });
    }

    setNewGitError = action((err) => {
        if (err[0]?.msg) {
            this.error.new = err[0].msg;
        } else {
            this.error.new = err;
        }
    });

    setDuplicateGitError = action((err) => {
        if (err[0]?.msg) {
            this.error.duplicate = err[0].msg;
        } else {
            this.error.duplicate = err;
        }
    });

    getDefaultGitConnection = () => {
        return this.repos.find((it) => it.is_default);
    };

    setImportGitInfo = action((err) => {
        this.info.import = err;
    });

    setRefreshGitInfo = action((err) => {
        this.info.refresh = err;
    });

    setEditGitError = action((err) => {
        if (err[0]?.msg) {
            this.error.edit = err[0].msg;
        } else {
            this.error.edit = err;
        }
    });

    setDeleteGitError = action((err) => {
        this.error.delete = err;
    });

    setImportGitError = action((err) => {
        this.error.import = err;
    });

    setRefreshGitError = action((err) => {
        this.error.refresh = err;
    });

    setGitConnection = action((repo) => {
        this.repos = [...repo.filter((db) => db.is_default), ...repo.filter((db) => !db.is_default)];
    });

    getGitConnections = (page = 1, size = 50) => {
        return this.store.apiStore
            .get({
                url: `/connections/git?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setGitConnection(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((e) => {
                Promise.reject("Unable to get git connections");
            });
    };

    editGit = (id, item = null, status = null, is_default = null) => {
        let finalItem;
        if (item) {
            finalItem = {
                connection_name: item.connection_name,
                description: item.description,
                git_repo_url: item.url,
                git_ssh_key: item.sshkey,
                git_token: item.token,
                is_default: item.is_default,
                is_active: item.is_active,
                branch: item.branch,
                repository_type: item.repository_type,
            };
        } else if (typeof status == "boolean") {
            finalItem = {
                is_active: status,
            };
        } else if (typeof is_default == "boolean") {
            finalItem = {
                is_default: is_default,
            };
        }

        return this.store.apiStore
            .put({
                url: `/connections/git/${id}`,
                body: JSON.stringify(finalItem),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setEditGitError(err));
    };

    createGit = (item) => {
        return this.store.apiStore
            .post({
                url: "/connections/git",
                body: JSON.stringify({
                    connection_name: item.git.connection_name,
                    description: item.git.description,
                    git_repo_url: item.git.url,
                    branch: item.git.branch,
                    git_ssh_key: item.git.sshkey,
                    is_default: item.is_default,
                    git_token: item.git.token,
                    is_active: item.git.is_active,
                    repository_type: item.git.repository_type,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return Promise.all([this.getGitConnections(this.page, this.size)]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewGitError(err));
    };

    cloneGitConfig = (git_config_id, item) => {
        return this.store.apiStore
            .post({
                url: `/connections/git/clone/${git_config_id}`,
                body: JSON.stringify({
                    connection_name: item.connection_name,
                    description: item.description,
                    branch: item.branch,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return Promise.all([this.getGitConnections(this.page, this.size)]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setDuplicateGitError(err));
    };

    deleteGitConnections = (repo_id) => {
        return this.store.apiStore
            .delete({
                url: `/connections/git/${repo_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getGitConnections(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteGitError(err));
    };

    importRepo = (id) => {
        return this.store.apiStore
            .post({
                url: `/connections/git/import/${id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(async (data) => {
                this.setImportGitError("");
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "git");
                this.store.intervalStore.registerWatcher();
                await this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `Git Import Submit`,
                    status: "SUCCESS",
                    type: "GIT",
                    link: "/settings/connections",
                });

                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setImportGitError(err));
    };

    refreshRepo = (id) => {
        return this.store.apiStore
            .post({
                url: `/connections/git/refresh/${id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setRefreshGitInfo(data["msg"]);
                return Promise.all([this.getGitConnections(this.page, this.size)]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setRefreshGitError(err));
    };
}
export default GitStore;
