import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const SchedulesFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "Schedules", keyOptions = "schedules" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

    const store = useStore();
    const [filterSchedules, setFilterSchedules] = useState(appliedFilter);
    const [schedules, setSchedules] = useState({ items: [], pageCount: 1 });
    const [schedulesIsLoading, setSchedulesIsLoading] = useState(false);

    const fetchScheduleOptions = (optionFilters = {} as any, page = 1) => {
        if (!schedules.items.length) setSchedulesIsLoading(true);

        store.croneJobStore
            .getSchedule({ page, size: optionsSize, filters: optionFilters, returned: true })
            .then((data: any) => {
                if (!data) return;

                setSchedules((prevSchedules) => {
                    const prevIds = new Set(prevSchedules.items.map((prevItem) => prevItem.schedule_id));
                    return {
                        items: [...prevSchedules.items, ...data.items.filter((item) => !prevIds.has(item.schedule_id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setSchedulesIsLoading(false));
    };

    useEffect(() => {
        setFilterSchedules(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            radio={true}
            loading={schedulesIsLoading}
            options={schedules.items.map((item) => ({
                name: item.schedule_id,
                value: item.schedule_id,
            }))}
            pageCount={schedules.pageCount}
            searchLocal={false}
            values={filterSchedules}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterSchedules(value)}
            getOptions={fetchScheduleOptions}
            onSave={(savedSchedules) => onChange({ [keyOptions]: savedSchedules })}
        />
    );
});
