import { Table, Tag } from "antd";
import React from "react";
import Text from "../../layout/components/content/text/text";

interface Props {
    items: any[];
}

export const TopFailedTests: React.FC<Props> = ({ items }) => {
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "10%",
            render: (text) => <Text isLink={false} ellipsis={{ tooltip: `${text}` }} text={text} />,
        },
        {
            title: "Failed Count",
            dataIndex: "failed_count",
            key: "failed_count",
            width: "5%",
            render: (text) => <Tag className={`tag-failed-no-hover`}>{text}</Tag>,
        },
    ];

    return <Table tableLayout="fixed" rowKey={(record) => record.test_id} columns={columns} dataSource={items} pagination={false} />;
};
