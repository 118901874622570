import { Redirect, Route } from "react-router-dom";
import { RoleType } from "../types";
import { AllowedRoles } from "./AllowedRoles";
import { PagePath, Roles } from "./types";

const ProtectedRoute = ({ store, path, ...restProps }) => {
    if (
        AllowedRoles[path].includes(Roles.ANY) ||
        !!store.authStore?.user?.roles?.some((role: RoleType) => AllowedRoles[path]?.includes(role.role_name.toLowerCase()))
    )
        return <Route {...restProps} />;

    return <Redirect to={PagePath.DASHBOARD} />;
};

export default ProtectedRoute;
