import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
export type UserInstanceType = {
    id: string;
    name: string;
};

export type ProjectType = {
    project_name: string;
    friendly_id: string;
    description: string;
    id: number;
};

export const useAvailableUserProjects = (store: any) => {
    const [availableProjects, setAvailableProjects] = useState<UserInstanceType[]>();
    const [loadingProject, setLoadingProject] = useState<boolean>(false);

    const [project, setProject] = useState<string>("");

    const assignAppProject = (data: any) => {
        setAvailableProjects(
            data?.map((it: ProjectType) => {
                return { id: it.id, friendly_id: it.friendly_id, name: it.project_name };
            })
        );
    };

    const getAppProjects = () => {
        setLoadingProject(true);
        store.projectsStore
            .getAppProjects()
            .then((data: any) => assignAppProject(data))
            .finally(() => setLoadingProject(false));
    };

    const searchProject = useCallback(
        debounce((name) => {
            setLoadingProject(true);
            store.projectsStore
                .searchProject(name)
                .then((data: any) => assignAppProject(data))
                .finally(() => setLoadingProject(false));
        }, 500),
        []
    );

    useEffect(() => {
        if (!availableProjects || availableProjects?.length === 0) {
            getAppProjects();
        }
    }, []);

    return { availableProjects, loadingProject, project, setProject, searchProject };
};
