export enum PagePath {
    DASHBOARD = "/",
    REQUIREMENTS = "/requirements",
    TEST_SUITES = "/tests/suites",
    TEST_APPROACHES = "/tests/approaches",
    TEST_CASES = "/tests/cases",
    TEST_PAGE = "/tests/:id",
    NEW_TEST_CASE = "/tests/cases/new",
    PREDICTIONS = "/predictions",
    RELEASES = "/release",
    RULES = "/rules",
    NEW_EXECUTION = "/executions/new",
    NEW_WORKFLOW = "/workflow/new",
    WORKFLOW_TEMPLATES = "/templates",
    EXECUTIONS = "/executions/results",
    EXECUTION_PAGE = "/executions/:id",
    PROFILE_INFORMATION = "/profile/information",
    PROFILE_PASSWORD_CHANGE = "/profile/password-change",
    RECONCILIATION = "/reconciliation",
    DATA_QUALITY_DASHBOARD = "/data_quality/dashboard",
    NEW_DATA_QUALITY = "/data_quality/new",
    DATA_QUALITY_RESULTS = "/data_quality/results",
    DATA_QUALITY_RESULTS_PAGE = "/data_quality/results/:id",
    CONNECTIONS = "/settings/connections",
    USERS = "/settings/users",
    CONFIGURATIONS = "/settings/configurations",
    NOTIFICATIONS = "/settings/notifications",
    SETTINGS = "/settings",
    VARIABLES = "/variables/results",
    ERROR_PAGE = "/error-page",
}
