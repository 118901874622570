import { useEffect } from "react";
import { AUTH_TOKEN } from "../enums";
import { PublicRoutes } from "../router/routes/PublicRoutes";

export const useAuth = (store) => {
    const publicPathes = PublicRoutes.map((route) => route.path);

    const handleUser = () => {
        if (store.authStore.token) {
            store.authStore.getMe().catch(goToLogin);
        } else {
            goToLogin();
        }
    };

    const handleToken = () => {
        const currentToken = localStorage.getItem(AUTH_TOKEN);

        if (currentToken) {
            store.authStore.setAuthToken({ access_token: currentToken });
        } else {
            goToLogin();
        }
    };

    const goToLogin = () => window.location.replace("/login");

    useEffect(() => {
        if (!publicPathes.includes(window.location.pathname)) {
            handleUser();
        }
    }, [store.authStore.token]);    

    useEffect(() => {
        window.dispatchEvent(new Event("storage")); 
        window.addEventListener('storage', handleToken);
        
        return () => {
            window.removeEventListener('storage', handleToken);
        }
    }, []);
};