import { Button, Col, Form, Modal, Row, Space, Spin, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import ErrorMessages from "../content/errorMessages/errorMessages";
import { ProjectSelect } from "./ProjectSelect";

interface Props {
    onSubmit: () => void;
    onCancel: () => void;
    open?: boolean;
}

export const ProjectChangeModal: React.FC<Props> = ({ open, onCancel, onSubmit }) => {
    const store = useStore();
    const history = useHistory();
    const [project, setProject] = useState<string>(store.authStore.currentProject);
    const [loading, setLoading] = useState<boolean>(false);

    const changeProject = () => {
        setLoading(true);

        store.authStore
            .changeProject(project)
            .then(() => {
                if (store.authStore.message.change_project === "") {
                    onSubmit();
                    history.push("/");
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        store.authStore.setMessage("change_project", "");
    }, []);

    useEffect(() => {
        store.authStore.setMessage("change_project", "");
    }, [project]);

    const cancelModal = () => {
        setProject(store.authStore.currentProject);
        onCancel();
    };

    return (
        <Modal
            className="ant-modal-20"
            destroyOnClose
            title={
                <Space align="baseline">
                    <Typography>User Project Change</Typography>
                    <Tag>{store.authStore.currentProject}</Tag>
                </Space>
            }
            loading={loading}
            open={open}
            onCancel={() => cancelModal()}
            footer={
                <div>
                    <Row gutter={[8, 8]} justify="end">
                        <Col>
                            <Button onClick={() => cancelModal()}>Cancel</Button>
                        </Col>

                        <Col>
                            <Button type="primary" htmlType="submit" onClick={() => changeProject()}>
                                Change Project
                            </Button>
                        </Col>
                    </Row>
                    {store.authStore.message.change_project && (
                        <Row gutter={[8, 8]} style={{ margin: 20 }} justify="center">
                            <ErrorMessages error={store.authStore.message.change_project} />
                        </Row>
                    )}
                </div>
            }
            styles={{ mask: { backdropFilter: "blur(10px)" } }}
        >
            <Spin spinning={loading}>
                <Row className="da-h-100" align="middle" justify="center">
                    <Col span={24}>
                        <Form layout="vertical" name="basic" initialValues={{ remember: true }} className="da-mt-sm-16 da-mt-32">
                            <Form.Item
                                label="Project"
                                className="da-mb-8"
                                rules={[{ required: true, message: "Please choose your project!" }]}
                            >
                                <ProjectSelect
                                    id={`login_instance_select`}
                                    initValue={project}
                                    onChangeUserProject={(e) => {
                                        store.authStore.setErrorMessage("");
                                        setProject(e);
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};
