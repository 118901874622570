import { action, makeObservable, observable } from "mobx";
import { DATA_QUALITY_DIMENSIONS } from "../enums";
import PaginationStore from "./paginationStore";
class DataQualityMetric extends PaginationStore {
    store;

    dimensions = {};
    dq_execution_details = {};
    error = {
        metric: "",
        metrics: "",
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            dimensions: observable,
            dq_execution_details: observable,
        });
    }

    setError = action((type, data) => {
        this.error[type] = data;
    });

    setDQDimensions = action((data) => {
        this.dimensions = data;
    });

    setDQDimension = action((name, data = []) => {
        const dim = DATA_QUALITY_DIMENSIONS.find((i) => name === i.value)
        if (dim) {
            this.dimensions[dim.type].items = data;
        }
    });

    setDQExecutionDetails = action((name, data) => {
        this.dq_execution_details[name] = data;
    });

    getDQDimensions = () => {
        return this.store.apiStore
            .get({
                url: `/dq_metrics/`,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setDQDimensions(data);
                }

                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setDQDimensions([]);
                this.setError("metrics", err);
            });
    };

    getDQDimension = (name, start = null, end = null) => {
        let url;
        if (start && end) {
            url = `/dq_metrics/${name}?start_date=${start}&end_date=${end}`;
        } else {
            url = `/dq_metrics/${name}`;
        }

        return this.store.apiStore
            .get({
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setDQDimension(name, data["items"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setDQDimension(name, []);
                this.setError("metric", err);
            });
    };

    getDQDimensionDetails = action((execution_id, test_approach_name, dimension, status = null) => {
        let url;
        if (status) {
            url = `/dq_metrics/by_execution/${execution_id}?dimension=${dimension}&test_approach=${test_approach_name}&status=${status}`;
        } else {
            url = `/dq_metrics/by_execution/${execution_id}?dimension=${dimension}&test_approach=${test_approach_name}&status=ALL`;
        }

        return this.store.apiStore
            .get({
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setDQExecutionDetails(execution_id, data);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setDQExecutionDetails(execution_id, []);
                this.setError("metric", err);
            });
    });
}
export default DataQualityMetric;
