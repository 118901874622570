import { Badge, Divider, Popover, Row, Space, Spin, Tag, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useStore } from "../../../../../../stores/StoreContext";
import { Variable } from "../../../../../types/variables";
import { ExecutionMetadataComponentType } from "../header/types/ExecutionsMetadataComponentType";

type IndentifierCountTagType = {
    category: string;
    resources: any;
    title?: string;
    remote?: boolean;
    tagType?: string;
};

export const VariableType = {
    INPUT: "INPUT",
    OUTPUT: "OUTPUT",
};

export const IndentifierTagVariables: FunctionComponent<IndentifierCountTagType> = ({
    category = "",
    title = "",
    resources = null,
    remote = true,
    tagType = ExecutionMetadataComponentType.ZIP,
}) => {
    const store = useStore();
    const [loading, setLoading] = useState<boolean>(false);
    const [variables, setVariables] = useState<any[]>([]);

    const getVariableForResources = async () => {
        if (category === "variables" && variables.length === 0 && resources && remote) {
            setLoading(true);
            return store.variablesStore.searchVariableWithValuesById([resources]).then((data: any) => {
                data ? setVariables(data.filter((i: any) => i.variable_type === VariableType.INPUT)) : setVariables([]);
                setLoading(false);
            });
        }
        if (!remote) {
            setVariables(resources);
        }
    };

    useEffect(() => {
        getVariableForResources();
    }, [resources]);

    const handleVariablesContent = () => {
        let content = variables.map((it: Variable, index) => (
            <Row gutter={[3, 3]} key={`IndentifierTagVariables-content-${index}`}>
                <Typography.Text strong>{it.variable_name}</Typography.Text>
                <Divider type="vertical" />
                <Typography.Text type="secondary">{it.variable_value}</Typography.Text>
            </Row>
        ));
        return content;
    };

    const handlePopoverContent = () => {
        let content = handleVariablesContent();
        let popover = (
            <Spin spinning={loading}>
                <Popover
                    rootClassName={"variables-popover-overlay"}
                    content={<Space style={{ display: "contents" }}>{content}</Space>}
                    title={
                        title ? (
                            <>
                                {title}
                                <Divider style={{ marginBottom: 0 }} />
                            </>
                        ) : (
                            category
                        )
                    }
                    trigger="hover"
                >
                    {variables.length > 0 && (
                        <div>
                            <Badge count={variables.length} offset={[-5, 10]}>
                                <Tag className="summaryTag info" key={`variables`}>
                                    {category?.toLowerCase()}
                                </Tag>
                            </Badge>
                        </div>
                    )}
                </Popover>
            </Spin>
        );
        return popover;
    };

    const handleFullContent = () => {
        let content = handleVariablesContent();
        return <div style={{ marginLeft: 10 }}>{content}</div>;
    };

    const chooseComponent = () => {
        if (tagType === ExecutionMetadataComponentType.ZIP) {
            return handlePopoverContent();
        } else if (tagType === ExecutionMetadataComponentType.FULL) {
            return handleFullContent();
        }
    };

    return <>{chooseComponent()}</>;
};
