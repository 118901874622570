import { FC, useEffect, useMemo, useState } from "react";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const RequirementCoveredFilterItem: FC<FilterItemProps> = ({
    filters,
    onChange,
    label = "Covered",
    keyOptions = "test_cases_count",
}) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterCovered, setFilterCovered] = useState(appliedFilter);

    useEffect(() => {
        setFilterCovered(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={[
                {
                    name: "Covered",
                    value: "covered",
                },
                {
                    name: "Not Covered",
                    value: "not_covered",
                },
            ]}
            values={filterCovered}
            buttonLabel={label}
            keyOptions={keyOptions}
            radio={true}
            setOptions={(value) => setFilterCovered(value)}
            onSave={(savedCovered) => onChange({ [keyOptions]: savedCovered })}
            showSearchInput={false}
        />
    );
};
