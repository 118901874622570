import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { RiFoldersLine, RiGitCommitLine, RiRocketLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import MetricTile from "../../layout/components/content/tile/tile";
import { useStore } from "../../stores/StoreContext";

interface Props {
    queryPredictions: any;
    executionInTimeLoading: boolean;
    queryPredictionsLoading: boolean;
}

export const StatsTileRow: React.FC<Props> = observer(({ queryPredictions, executionInTimeLoading, queryPredictionsLoading }) => {
    const store = useStore();
    const [isLoading, setIsLoading] = useState({
        total_execution: true,
        test_cases_count: true,
        releases_count: true,
    });

    useEffect(() => {
        getDefaultData();
    }, []);

    useEffect(() => {
        getDefaultData();
    }, [store.authStore.user?.active_project_name]);

    const getDefaultData = async () => {
        setIsLoading({ total_execution: true, test_cases_count: true, releases_count: true });
        await store.metricsStore
            .getReleasesCount()
            .then(() => setIsLoading((prevIsLoading) => ({ ...prevIsLoading, releases_count: false })));
        await store.metricsStore
            .getTestCaseCount()
            .then(() => setIsLoading((prevIsLoading) => ({ ...prevIsLoading, test_cases_count: false })));
        await store.metricsStore
            .getTestRulesCount()
            .then(() => setIsLoading((prevIsLoading) => ({ ...prevIsLoading, test_rules_count: false })));
    };

    const status =
        Object.keys(store.metricsStore.executions_in_time).length > 0 ? store.metricsStore.executions_in_time[0]?.status : "No data";

    return (
        <Row align="middle" gutter={[32, 32]} justify="space-between">
            <Col xl={6} sm={12} xs={24}>
                <MetricTile
                    id="query_predictions"
                    tooltip="Click to see details"
                    loading={queryPredictionsLoading}
                    metricValue={queryPredictions?.total > 0 ? queryPredictions.total : "No data"}
                    metricName={"Query Predictions"}
                    metricIcon={{ icon: <RiRocketLine className="remix-icon" /> }}
                    link={"/predictions"}
                />
            </Col>
            <Col xl={6} sm={12} xs={24}>
                <MetricTile
                    id="last_executions"
                    tooltip="Click to see details"
                    loading={executionInTimeLoading}
                    metricValue={status}
                    metricName={"Last Execution Status"}
                    metricIcon={
                        status === "Failed"
                            ? { icon: <TiDeleteOutline className="remix-icon" /> }
                            : { icon: <BiCheckCircle className="remix-icon" /> }
                    }
                    link={"/executions/results"}
                />
            </Col>
            <Col xl={6} sm={12} xs={24}>
                <MetricTile
                    id="releases_count"
                    tooltip="Click to see details"
                    loading={isLoading.releases_count}
                    metricValue={
                        Object.keys(store.metricsStore.releases_count).length > 0
                            ? store.metricsStore.releases_count[0].release_count
                            : "No data"
                    }
                    metricName={"Releases Count"}
                    metricIcon={{ icon: <RiGitCommitLine className="remix-icon" /> }}
                    link={"/release"}
                />
            </Col>
            <Col xl={6} sm={12} xs={24}>
                <MetricTile
                    id="test_cases_count"
                    tooltip="Click to see details"
                    loading={isLoading.test_cases_count}
                    metricValue={
                        Object.keys(store.metricsStore.test_case_count).length > 0
                            ? store.metricsStore.test_case_count.case_count
                            : "No data"
                    }
                    metricName={"Test Cases Count"}
                    metricIcon={{ icon: <RiFoldersLine className="remix-icon" /> }}
                    link={"/tests/cases"}
                />
            </Col>
        </Row>
    );
});
