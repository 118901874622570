import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const RequirementsFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Requirements", keyOptions = "requirements" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterRequirementIds, setFilterRequirementIds] = useState(appliedFilter);
        const [requirements, setRequirements] = useState({ items: [], pageCount: 1 });
        const [requirementsIsLoading, setRequirementsIsLoading] = useState(false);

        const fetchRequirementsOptions = (optionFilters = {}, page = 1) => {
            if (!requirements.items.length) setRequirementsIsLoading(true);

            store.requirementStore
                .searchRequirements({ filters: optionFilters, pagination: true, page, size: optionsSize, type: "or", returned: true })
                .then((data) => {
                    if (!data) return;

                    setRequirements((prevRequirements) => {
                        const prevIds = new Set(prevRequirements.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevRequirements.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setRequirementsIsLoading(false));
        };

        useEffect(() => {
            setFilterRequirementIds(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={requirementsIsLoading}
                options={requirements.items.map((item: any) => ({ name: `${item.work_item_id} - ${item.name}`, value: item.work_item_id }))}
                pageCount={requirements.pageCount}
                searchLocal={false}
                values={filterRequirementIds}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterRequirementIds(value)}
                getOptions={fetchRequirementsOptions}
                onSave={(savedRequirements) => onChange({ [keyOptions]: savedRequirements })}
            />
        );
    }
);
