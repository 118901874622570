import { action, makeObservable, observable } from "mobx";

class SearchStore {
    store;

    searchText = "";
    obj = {
        item: {},
        category: "",
    };
    error = "";

    constructor(store) {
        this.store = store;

        makeObservable(this, {
            searchText: observable,
            obj: observable,
        });
    }

    setError = action((err) => {
        this.error = err;
    });

    setSearchText = action((item) => {
        this.searchText = item;
    });

    setSearchItemObjectCategory = action((item) => {
        this.obj.category = item;
    });

    setSearchItemObject = action((item) => {
        this.obj.item = item;
    });

    setSearchItem = action((item) => {
        this.obj = item;
    });

    getSearchUrl = ({ pagination, page, size }) => {
        let url;
        if (pagination) {
            url = `/search/?pagination=${pagination}&distinct=true&page=${page}&size=${size}`;
        } else {
            url = `/search/?pagination=${pagination}&distinct=true`;
        }
        return url;
    };

    resolveTableByType = (type) => {
        let table = [];
        if (type === "suite") {
            table.push({
                table_name: "test_suite",
            });
        } else if (type === "approach") {
            table.push({
                table_name: "test_approach",
            });
        } else if (type === "case") {
            table.push({
                table_name: "test_case",
            });
        } else if (type === "requirement") {
            table.push({
                table_name: "requirements",
            });
        }
        return table;
    };

    resolveItemsByTypeWithValue = (type, value) => {
        let items = [];
        if (type === "suite") {
            items.push({
                column: "test_suite.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_suite.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "approach") {
            items.push({
                column: "test_approach.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_approach.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "case") {
            items.push({
                column: "test_case.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_case.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "requirement") {
            items.push({
                column: "requirements.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "requirements.work_item_id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        }
        return items;
    };

    searchHeaderData = (type, value) => {
        let table = this.resolveTableByType(type);
        let items = this.resolveItemsByTypeWithValue(type, value);
        let final_body = {
            search: table,
            filters: [
                {
                    logical_opperand: "or",
                    items: items,
                },
            ],
        };

        return this.store.apiStore
            .post({
                url: "/search/?pagination=true&page=1&size=10",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => data?.items || [])
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError(err));
    };
}

export default SearchStore;
