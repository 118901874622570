import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const DqRulesFilterItem: FC<FilterItemProps> = observer(({ filters, onChange, label = "DQ Rules", keyOptions = "ids" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

    const store = useStore();
    const [filterDqRules, setFilterDqRules] = useState(appliedFilter);
    const [dqRules, setDqRules] = useState({ items: [], pageCount: 1 });
    const [dqRulesIsLoading, setDqRulesIsLoading] = useState(false);

    const fetchDqRulesOptions = (optionFilters = {}, page = 1) => {
        if (!dqRules.items.length) setDqRulesIsLoading(true);

        store.testsStore
            .searchDQTestCaseData({
                page,
                filters: optionFilters,
                size: optionsSize,
                returned: true,
            })
            .then((data: any) => {
                setDqRules((prevDqRules) => {
                    const prevIds = new Set(prevDqRules.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevDqRules.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setDqRulesIsLoading(false));
    };

    useEffect(() => {
        setFilterDqRules(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={dqRulesIsLoading}
            options={dqRules.items.map((tag: any) => ({
                name: tag.name,
                value: tag.id,
                friendly_id: tag.friendly_id,
            }))}
            pageCount={dqRules.pageCount}
            searchLocal={false}
            values={filterDqRules}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterDqRules(value)}
            getOptions={fetchDqRulesOptions}
            onSave={(savedDqRules) => onChange({ [keyOptions]: savedDqRules })}
        />
    );
});
