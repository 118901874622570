import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TestCasesFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Test Cases", keyOptions = "test_cases" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterTestCases, setFilterTestCases] = useState(appliedFilter);
        const [testCases, setTestCases] = useState({ items: [], pageCount: 1 });
        const [testCasesIsLoading, setTestCasesIsLoading] = useState(false);

        const fetchTestCaseOptions = (optionFilters = {}, page = 1) => {
            if (!testCases.items.length) setTestCasesIsLoading(true);

            store.testsStore
                .searchTestCaseData({
                    page,
                    filters: optionFilters,
                    pagination: true,
                    size: optionsSize,
                    type: "or",
                    returned: true,
                })
                .then((data: any) => {
                    setTestCases((prevTestCases) => {
                        const prevIds = new Set(prevTestCases.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevTestCases.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setTestCasesIsLoading(false));
        };

        useEffect(() => {
            setFilterTestCases(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={testCasesIsLoading}
                options={testCases.items.map((testCase: any) => ({
                    name: testCase.name,
                    value: testCase.id,
                    friendly_id: testCase.friendly_id,
                }))}
                pageCount={testCases.pageCount}
                searchLocal={false}
                values={filterTestCases}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterTestCases(value)}
                getOptions={fetchTestCaseOptions}
                onSave={(savedTestCases) => onChange({ [keyOptions]: savedTestCases })}
            />
        );
    }
);
