import { Space } from "antd";
import { FunctionComponent } from "react";
import { InputFilterItem, StatusFilterItem, TestApproachesFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const TestApproachesFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap>
            <TestApproachesFilterItem filters={filters} onChange={onChange} label="ID" keyOptions="id" />
            <InputFilterItem filters={filters} keyOptions="name" placeholder="Search test approach name...." onChange={onChange} />
            <StatusFilterItem filters={filters} onChange={onChange} />
        </Space>
    );
};
