import { action, observable } from "mobx";
import { getQueryConfig, mapDqRequest } from "../view/pages/data_quality/new/dqMappingUtils";
import PaginationStore from "./paginationStore";

class DataQuality extends PaginationStore {
    store;
    source = observable({
        type: "",
        db_config_id: "",
    });
    source_object_schema = observable("");
    source_object = observable("");
    source_object_column = observable("");

    test_case_name = observable("");
    test_case_description = observable("");

    threshold_warning = observable(0);
    threshold_failure = observable(0);

    possibleVariants = observable([]);
    dq_results = observable([]);
    previewDQRule = observable({});
    custom_tags = observable([]);
    custom_approach_ids = observable([]);
    queries = {
        query_bad_count: "",
        query_bad_count_raw: false,
        query_count: "",
        query_count_raw: false,
        query_validate: "",
        query_validate_raw: false,
    };

    fullFlowUpdate = observable({});

    error = observable({
        new: "",
        edit: "",
        dq_gatherer: "",
        convert: "",
        convert_result: "",
    });
    info = "";

    constructor(store) {
        super();
        this.store = store;
    }

    reset() {
        this.setSource(null);
        this.setSourceObject("");
        this.setSourceObjectSchema("");
        this.setThresholdFail(0);
        this.setThresholdWarn(0);
        this.setTestCaseName("");
        this.setTestCaseDescription("");
        this.setDQResults([]);
        this.setError("new", "");
        this.custom_tags = observable([]);
        this.custom_approach_ids = observable([]);
        this.queries = {
            query_bad_count: "",
            query_count: "",
            query_validate: "",
            custom_raw: false,
        };
        this.store.reconciliationStore.profiling_results = {};
        this.fullFlowUpdate = observable({});
    }

    setTestCaseName = (data) => {
        this.test_case_name = data;
    };

    setDQResults = (data) => {
        this.dq_results = data;
    };

    setTestCaseDescription = (data) => {
        this.test_case_description = data;
    };

    setInfo = (info) => {
        this.info = info;
    };

    setError = action((key, err) => {
        this.error[key] = err;
    });

    setEditCaseErrorMessage = (msg) => {
        this.error["edit"] = msg;
    };

    setSource = (data) => {
        if (data) {
            this.source.db_config_id = data;
            let type = this.store.settingsStore.databases.filter((i) => i.db_config_id === data);
            if (type.length > 0) {
                this.source.type = type[0].db_type;
            }
        } else {
            this.source.db_config_id = "";
            this.source.type = "";
        }
    };

    setThresholdFail = (data) => {
        this.threshold_failure = data;
    };

    setThresholdWarn = (data) => {
        this.threshold_warning = data;
    };

    setSourceObjectSchema = (data) => {
        this.source_object_schema = data;
    };

    setSourceObject = (data) => {
        this.source_object = data;
    };

    setSourceColumn = (data) => {
        this.source_object_column = data;
    };

    setPossibleVariants = (data) => {
        this.possibleVariants = data;
    };

    setCustomTags = (data) => {
        this.custom_tags = data;
    };

    setCustomRaw = (data, name) => {
        this.queries[`${name}_raw`] = data;
    };

    setCustomApproach = (data) => {
        this.custom_approach_ids = data;
    };

    setQueries = (data, type) => {
        this.queries[type] = data;
    };

    setPreviewDQRule = (data) => {
        this.previewDQRule = data;
    };

    getDQResults = action(({ db_config, schema, table, column } = {}) => {
        let url;
        if (!schema && !table && !column) url = `/data_quality/${db_config}`;
        else url = `/data_quality/${db_config}/${schema}/${table}/${column}`;

        return this.store.apiStore
            .get({
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (data.total === 1) this.setDQResults(data["items"][0]);
                    else this.setDQResults(data["items"]);

                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                } else {
                    this.setDQResults([]);
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setPage(0);
                this.setTotal(0);
                this.setSize(0);
                this.setDQResults([]);
            });
    });

    getFullVariantsForTypeV3 = (type) => {
        if (["Categorical", "Numeric", "DateTime", "Unknown"].includes(type)) {
            return this.store.apiStore
                .get({
                    url: `/data_quality_v3/variants_v3/${type}`,
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        this.setPossibleVariants(data);
                    }
                })
                .catch((e) => {
                    Promise.reject(`Unable to get variants for type ${type}`);
                });
        }
    };

    getFullVariantsForType = (type) => {
        if (["Categorical", "Numeric", "DateTime", "Unknown"].includes(type)) {
            return this.store.apiStore
                .get({
                    url: `/data_quality/variants/${type}`,
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        this.setPossibleVariants(data);
                    }
                })
                .catch((e) => {
                    Promise.reject(`Unable to get variants for type ${type}`);
                });
        }
    };

    getAllVariants = () => {
        return this.store.apiStore
            .get({
                url: `/data_quality_v3/all/variants_v3`,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return data;
                }
            })
            .catch((e) => {
                Promise.reject(`Unable to get variants `);
            });
    };

    editDQTestCaseRequest = (test_case_id, body) => {
        return this.store.apiStore
            .put({
                url: `/data_quality/${test_case_id}`,
                body: JSON.stringify(body),
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                if (err.length > 0) {
                    this.setEditCaseErrorMessage(err?.[0]?.msg ?? err);
                } else {
                    this.setEditCaseErrorMessage("Error saving DQ Test Case");
                }
            });
    };

    editDqTestCase = (testCaseId, dqTestCase) => {
        this.setError("edit", "");
        const body = mapDqRequest({
            ...dqTestCase,
            source: dqTestCase.db_config,
        });
        return this.editDQTestCaseRequest(testCaseId, body);
    };

    editDqTestCaseCreateSchema = ({ test_case_id, body }) => {
        return this.editDQTestCaseRequest(test_case_id, body);
    };

    prepareBodyForNewTestCase = (
        dqConfig,
        aggregation,
        db_config,
        test_case_name,
        test_case_description,
        reqs,
        tags,
        failure_threshold,
        warning_threshold,
        global_where
    ) => {
        const aggregationValues = (aggregation.type === "regular_expression" ? aggregation?.values.map((value) => {
            try {
                const regex = new RegExp(value);
                return regex.source; 
              } catch (error) {
                return value;
              }
        }) : aggregation?.values) ?? [];

        return {
            db_config_id: db_config.db_config_id,
            database_type: db_config.db_type?.toLowerCase(),
            schema_name: dqConfig.schema,
            table_name: dqConfig.object,
            column_name: dqConfig.column,
            operator_type: aggregation.operator ?? null,
            test_case_name: test_case_name,
            test_case_description: test_case_description,
            aggregation_type: aggregation.type,
            custom_requirements_ids: reqs,
            custom_tags: tags,
            aggregation_value: aggregationValues,
            threshold_warning: `${warning_threshold}`,
            threshold_failure: `${failure_threshold}`,
            where: dqConfig.where,
            global_where: global_where,
        };
    };

    createDQTest = action(
        (
            preview,
            dqConfig,
            aggregation,
            db_config,
            test_case_name,
            test_case_description,
            reqs,
            tags,
            failure_threshold,
            warning_threshold,
            global_where
        ) => {
            let body = this.prepareBodyForNewTestCase(
                dqConfig,
                aggregation,
                db_config,
                test_case_name,
                test_case_description,
                reqs,
                tags,
                failure_threshold,
                warning_threshold,
                global_where
            );
            return this.store.apiStore
                .post({
                    url: preview ? "/data_quality/preview_test" : "/data_quality/create_test",
                    body: JSON.stringify(body),
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (preview) {
                        this.setPreviewDQRule(data);
                    } else {
                        this.setInfo(data);
                    }
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => {
                    if (err.length > 0) {
                        if (err[0].msg) this.setError("new", err[0].msg);
                        else if (err) this.setError("new", err);
                    }
                    if (preview) {
                        this.setPreviewDQRule({});
                    }
                });
        }
    );

    createDQMultiColumnTest = action(
        ({
            preview = false,
            dqConfigs,
            joinType,
            aggregationType,
            comparison,
            test_case_name,
            test_case_description,
            db_config,
            reqs,
            tags,
            global_where,
        }) => {
            let body = {
                test_case_name: test_case_name,
                test_case_description: test_case_description,
                custom_requirements_ids: reqs,
                custom_tags: tags,
                db_config_id: db_config.db_config_id,
                comparison_type: comparison.type,
                comparison_min: comparison.min,
                comparison_max: comparison.max,
                database_type: db_config.db_type?.toLowerCase(),
                db_query: getQueryConfig(dqConfigs[0]),
                limit_query: getQueryConfig(dqConfigs[1]),
                join_type: joinType,
                where: global_where,
                aggregation_type: aggregationType?.toLowerCase() ?? null,
            };
            return this.store.apiStore
                .post({
                    url: preview ? "/data_quality/preview_multi_column_test" : "/data_quality/create_multi_column_test",

                    body: JSON.stringify(body),
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (preview) {
                        this.setPreviewDQRule(data);
                    } else {
                        this.setInfo(data);
                    }
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => {
                    if (err.length > 0) {
                        if (err[0].msg) this.setError("new", err[0].msg);
                        else if (err) this.setError("new", err);
                    }
                    if (preview) {
                        this.setPreviewDQRule({});
                    }
                });
        }
    );

    createDQCustomTest = action(
        (
            preview,
            dqConfig,
            db_config,
            test_case_description,
            test_case_name,
            query_bad_count,
            query_count,
            query_validate,
            threshold_warning,
            threshold_failure,
            reqs,
            tags,
            customApproachIds,
            customSuiteIds,
        ) => {
            let body = {
                db_config_id: db_config.db_config_id,
                database_type: db_config.db_type?.toLowerCase(),
                schema_name: dqConfig.schema,
                table_name: dqConfig.object,
                column_name: dqConfig.column,
                test_case_name: test_case_name,
                test_case_description: test_case_description,
                custom_requirements_ids: reqs,
                custom_tags: tags,
                threshold_warning: threshold_warning,
                threshold_failure: threshold_failure,
                custom_count_query: query_count?.input,
                custom_count_query_raw: query_count.status ? false : true,
                custom_bad_count_query: query_bad_count?.input,
                custom_bad_count_raw: query_bad_count.status ? false : true,
                custom_verification_query: query_validate?.input,
                custom_verification_raw: query_validate.status ? false : true,
                custom_approach_ids: customApproachIds,
                custom_suite_ids: customSuiteIds,
            };
            return this.store.apiStore
                .post({
                    url: preview ? "/data_quality/preview_custom_test" : "/data_quality/create_custom_test",

                    body: JSON.stringify(body),
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (preview) {
                        this.setPreviewDQRule(data);
                    } else {
                        this.setInfo(data);
                    }
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => {
                    if (err.length > 0) {
                        if (err[0].msg) this.setError("new", err[0].msg);
                        else if (err) this.setError("new", err);
                    }
                    if (preview) {
                        this.setPreviewDQRule({});
                    }
                });
        }
    );

    runDQGatherer = action(({ db_config_id, include, exclude } = {}) => {
        let body = {
            db_config_id: db_config_id,
            include: include,
            exclude: exclude,
        };
        return this.store.apiStore
            .post({
                url: "/data_quality/",

                body: JSON.stringify(body),
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.updateTaskUpdator(data.task_id, "dq");
                this.store.intervalStore.registerWatcher();
                this.store.intervalStore.pushNew({
                    task: data.task_id,
                    content: `DQ Submit`,
                    status: "SUCCESS",
                    type: "DQ",
                });
                this.setInfo(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError("dq_gatherer", err));
    });

    collectFullFlowMetadata = ({ db_config_id, dqConfig } = {}) => {
        let body = { db_config_id: db_config_id };
        let include = [];
        let include_column = [];
        if (dqConfig.schema) {
            if (dqConfig.object) {
                include = [`${dqConfig.schema}.${dqConfig.object}`];
            } else {
                include = [`${dqConfig.schema}.*`];
            }
        }
        if (dqConfig.column) {
            include_column = [
                {
                    source_db_config_id: db_config_id,
                    comparison_object: `${dqConfig.schema}.${dqConfig.object}`,
                    include: [dqConfig.column],
                },
            ];
        }

        if (include) {
            body.include = include;
        }
        if (dqConfig.column) {
            body.include = [];
            body.specific_profiling_settings = include_column;
        }

        return this.store.apiStore
            .post({
                url: `/data_quality/full_flow`,
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setError("full_flow", "");
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "full_flow");
                this.store.intervalStore.registerWatcher();
                this.fullFlowUpdate[db_config_id] = {
                    id: data["task_id"],
                    status: "STARTED",
                };
                return this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `FULL_FLOW Submit`,
                    status: "SUCCESS",
                    type: "METADATA",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError("full_flow", err));
    };

    updateMetadataStatus = (taskId) => {
        const res = Object.entries(this.fullFlowUpdate).find(([key, value]) => value?.id === taskId);
        if (!res) {
            return;
        }
        this.fullFlowUpdate[res[0]] = {
            ...res[1],
            status: "DONE",
        };
    };

    resetMetadataStatus = (dbConfigId) => {
        this.fullFlowUpdate[dbConfigId] = null;
    };

    convertToCustom = action((tc_id) => {
        return this.store.apiStore
            .post({
                url: `/data_quality_v3/create_custom_from_simple_test_v3/${tc_id}`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError("convert", err));
    });
}
export default DataQuality;
