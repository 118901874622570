import { Space } from "antd";
import { FunctionComponent } from "react";
import { InputFilterItem, StatusFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const ConnectionsFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap>
            <InputFilterItem
                filters={filters}
                keyOptions="connection_name"
                id="search-connection-name-input"
                placeholder="Search connection name...."
                onChange={onChange}
            />
            <StatusFilterItem filters={filters} onChange={onChange} />
        </Space>
    );
};
