import { Space } from "antd";
import { FunctionComponent } from "react";
import { InputFilterItem, TagsFilterItem, TemplateTypeFilterItem, UsersFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const TemplatesFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <InputFilterItem filters={filters} keyOptions="dag_name" placeholder="Search template name...." onChange={onChange} />
            <TemplateTypeFilterItem filters={filters} onChange={onChange} />
            <TagsFilterItem filters={filters} onChange={onChange} />
            <UsersFilterItem filters={filters} onChange={onChange} keyOptions="created_by" />
        </Space>
    );
};
