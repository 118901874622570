import { Col, Descriptions, Row, Statistic } from "antd";
import React from "react";
import { StatisticFormatter } from "../../shared/tileWithStatistics";

interface Props {
    failed: number;
    total: number;
}

export const DqResultTooltipContent: React.FC<Props> = ({ failed = 0, total = 0 }) => {
    const passed = total - failed;
    const ratio = Number(((passed / total) * 100).toFixed(2));

    return (
        <Row>
            <Col md={16}>
                <Descriptions>
                    <Descriptions.Item key={1} label={"All rows"} style={{ paddingBottom: 0 }}>
                        {total}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item key={2} label={"Correct rows"} style={{ paddingBottom: 0 }}>
                        {passed}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item key={3} label={"Incorrect rows"} style={{ paddingBottom: 0 }}>
                        {failed}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col md={8} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Statistic
                    title="Pass Ratio"
                    value={ratio}
                    formatter={StatisticFormatter}
                    suffix={"%"}
                    valueStyle={{ display: "flex", alignItems: "baseline", minWidth: "85px" }}
                />
            </Col>
        </Row>
    );
};
