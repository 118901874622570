import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const GitRepoFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Git repositories", keyOptions = "git_repo" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterRepos, setFilterRepos] = useState(appliedFilter);
        const [repos, setRepos] = useState({ items: [], pageCount: 1 });
        const [reposIsLoading, setReposIsLoading] = useState(false);

        const fetchRepoOptions = (optionFilters = {}, page = 1) => {
            if (!repos.items.length) setReposIsLoading(true);

            store.settingsStore
                .searchConnectionDBRepo(optionFilters, "repo", "or", page)
                .then((data) => {
                    if (!data) return;

                    setRepos((prevRepos) => {
                        const prevIds = new Set(prevRepos.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevRepos.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setReposIsLoading(false));
        };

        useEffect(() => {
            setFilterRepos(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={reposIsLoading}
                options={repos.items.map((tag: any) => ({
                    name: tag.connection_name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={repos.pageCount}
                searchLocal={false}
                values={filterRepos}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterRepos(value)}
                getOptions={fetchRepoOptions}
                onSave={(savedRepos) => onChange({ [keyOptions]: savedRepos })}
            />
        );
    }
);
