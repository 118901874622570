import { action, makeObservable, observable } from "mobx";
import PaginationStore from "./paginationStore";
import { getUrlPart } from "./utils/storeUtils";

class TestApproachStore extends PaginationStore {
    store;

    errors = {
        approach: {
            new: "",
            edit: "",
            delete: "",
            getQuery: "",
        },
    };

    testApproaches = [];
    approach_prediction = {
        review: {},
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            testApproaches: observable,
            errors: observable,
            approach_prediction: observable,
        });
    }

    setNewApproachErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.approach.new = error[0].msg;
        } else {
            this.errors.approach.new = error;
        }
    });

    setErrorMessage = action((type, err) => {
        this.errors.approach[type] = err;
    });

    setEditApproachErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.approach.edit = error[0].msg;
        } else {
            this.errors.approach.edit = error;
        }
    });

    setDeleteApproachErrorMessage = action((error) => {
        this.errors.approach.delete = error;
    });

    setTestApproaches = action((cases) => {
        this.testApproaches = cases;
    });

    setPredictionsApproaches = action((data, db_config_id, status) => {
        if (!this.approach_prediction[status]) {
            this.approach_prediction[status] = observable({});
        }
        this.approach_prediction[status][db_config_id] = data;
    });

    getApproachesPredictions = action(({ db_config_id, page = 1, size = 50, column, table, schema } = {}) => {
        const url = `/test_approach/with_available_predictions/?db_config_id=${db_config_id}&page=${page}&size=${size}${getUrlPart(
            "column_name",
            column
        )}${getUrlPart("table_name", table)}${getUrlPart("schema_name", schema)}`;
        return this.store.apiStore
            .get({
                url: url,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setPage(data["page"]);
                this.setPagination(true);
                this.setSize(data["size"]);
                this.setTotal(data["total"]);
                this.setPredictionsApproaches(data["items"], db_config_id, "review");
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setPredictionsApproaches([], db_config_id, "review");
                this.setPage(1);
                this.setPagination(true);
                this.setSize(0);
                this.setTotal(0);
                this.setErrorMessage("getQuery", err);
            });
    });

    getTestApproaches(page = 1, size = 50) {
        return this.store.apiStore
            .get({
                url: `/test_approach/?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestApproaches(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((e) => {
                Promise.reject("Unable to get tests-approaches");
            });
    }

    getTestSuitesForTestCasesList = (items_ids) => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "test_suite" }, { table_name: "test_case", parent: "test_suite" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [{ column: "test_case.id", is_value_column: false, search_value: items_ids, search_type: "in" }],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    let uniques = [...new Map(data.map((item) => [item["id"], item])).values()];
                    this.store.dbExecutionStore.setSuites(uniques);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewApproachErrorMessage(err));
    };

    getInactiveTestCasesForTestApproches = (items_ids) => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "test_case" }, { table_name: "test_approach", parent: "test_case" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                { column: "test_case.is_active", is_value_column: false, search_value: "0", search_type: "eq" },
                                { column: "test_approach.id", is_value_column: false, search_value: items_ids, search_type: "in" },
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    let uniques = [...new Map(data.map((item) => [item["id"], item])).values()];
                    return uniques;
                }
                return [];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewApproachErrorMessage(err));
    };

    getTestCasesForTestApproches = (items_ids) => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "test_case" }, { table_name: "test_approach", parent: "test_case" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [{ column: "test_approach.id", is_value_column: false, search_value: items_ids, search_type: "in" }],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    let uniques = [...new Map(data.map((item) => [item["id"], item])).values()];
                    return uniques;
                }
                return [];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewApproachErrorMessage(err));
    };

    createTestApproach(item) {
        return this.store.apiStore
            .post({
                url: "/test_approach/",
                body: JSON.stringify({
                    name: item.name,
                    description: item.description,
                    is_active: item.is_active,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return Promise.all([this.getTestApproaches(this.page, this.size)]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewApproachErrorMessage(err));
    }

    editTestApproach(approach_id, item = null, status = null) {
        return this.store.apiStore
            .put({
                url: `/test_approach/${approach_id}`,
                body: item
                    ? JSON.stringify(item)
                    : JSON.stringify({
                          is_active: status,
                      }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setEditApproachErrorMessage(err));
    }

    deleteTestApproach(approach_id) {
        return this.store.apiStore
            .delete({
                url: `/test_approach/${approach_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getTestApproaches(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteApproachErrorMessage(err));
    }
}
export default TestApproachStore;
