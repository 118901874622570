import { FC, useEffect, useMemo, useState } from "react";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const StatusFilterItem: FC<FilterItemProps> = ({ filters, onChange, label = "Status", keyOptions = "is_active" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterStatuses, setFilterStatuses] = useState(appliedFilter);

    useEffect(() => {
        setFilterStatuses(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={[
                { name: "ACTIVE", value: true },
                { name: "INACTIVE", value: false },
            ]}
            searchLocal={true}
            values={filterStatuses}
            buttonLabel={label}
            keyOptions={keyOptions}
            radio={true}
            setOptions={(value) => setFilterStatuses(value)}
            onSave={(savedStatuses) => onChange({ [keyOptions]: savedStatuses })}
        />
    );
};
