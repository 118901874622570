import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class RolesStore extends PaginationStore {
    store;
    roles = [];

    constructor(store) {
        super();
        this.store = store;

        observable(this, {
            roles: observable,
        });
    }

    setRoles = action((roles) => {
        this.roles = roles;
    });

    getAllRoles = action(() => {
        return this.store.apiStore
            .get({
                url: "/roles/",
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
                this.setRoles(data["items"]);
            })
            .catch((e) => {
                Promise.reject("Unable to get roles");
            });
    });
}
export default RolesStore;
