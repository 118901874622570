import { PagePath, Roles } from "./types";

export const AllowedRoles = {
    [PagePath.DASHBOARD]: [Roles.ANY],
    [PagePath.REQUIREMENTS]: [Roles.ANY],
    [PagePath.TEST_SUITES]: [Roles.ANY],
    [PagePath.TEST_APPROACHES]: [Roles.ANY],
    [PagePath.TEST_CASES]: [Roles.ANY],
    [PagePath.TEST_PAGE]: [Roles.ANY],
    [PagePath.NEW_TEST_CASE]: [Roles.ANY],
    [PagePath.DATA_QUALITY_DASHBOARD]: [Roles.DQ_ADMIN, Roles.DQ_TESTER],
    [PagePath.NEW_DATA_QUALITY]: [Roles.DQ_ADMIN, Roles.DQ_TESTER],
    [PagePath.DATA_QUALITY_RESULTS]: [Roles.DQ_ADMIN, Roles.DQ_TESTER],
    [PagePath.DATA_QUALITY_RESULTS_PAGE]: [Roles.DQ_ADMIN, Roles.DQ_TESTER],
    [PagePath.CONNECTIONS]: [Roles.ANY],
    [PagePath.EXECUTIONS]: [Roles.ANY],
    [PagePath.EXECUTION_PAGE]: [Roles.ANY],
    [PagePath.RECONCILIATION]: [Roles.ADMIN, Roles.TESTER, Roles.TEST_ADMINISTRATOR],
    [PagePath.PROFILE_INFORMATION]: [Roles.ANY],
    [PagePath.PROFILE_PASSWORD_CHANGE]: [Roles.ANY],
    [PagePath.PREDICTIONS]: [Roles.ANY],
    [PagePath.RELEASES]: [Roles.ANY],
    [PagePath.RULES]: [Roles.ANY],
    [PagePath.NEW_EXECUTION]: [Roles.ANY],
    [PagePath.NEW_WORKFLOW]: [Roles.ANY],
    [PagePath.WORKFLOW_TEMPLATES]: [Roles.ANY],
    [PagePath.USERS]: [Roles.ADMIN, Roles.DQ_ADMIN],
    [PagePath.CONFIGURATIONS]: [Roles.ADMIN, Roles.DQ_ADMIN],
    [PagePath.NOTIFICATIONS]: [Roles.ADMIN, Roles.DQ_ADMIN],
    [PagePath.SETTINGS]: [Roles.ADMIN, Roles.DQ_ADMIN],
    [PagePath.VARIABLES]: [Roles.ADMIN, Roles.DQ_ADMIN],
    [PagePath.ERROR_PAGE]: [Roles.ANY],
};
