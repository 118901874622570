import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TestSuitesFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Test Suites", keyOptions = "test_suites" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterTestSuites, setFilterTestSuites] = useState(appliedFilter);
        const [testSuites, setTestSuites] = useState({ items: [], pageCount: 1 });
        const [testSuitesIsLoading, setTestSuitesIsLoading] = useState(false);

        const fetchTestSuiteOptions = (optionFilters = {}, page = 1) => {
            if (!testSuites.items.length) setTestSuitesIsLoading(true);

            store.testsStore
                .searchTestSuiteData({
                    page,
                    filters: optionFilters,
                    pagination: true,
                    size: optionsSize,
                    type: "or",
                    returned: true,
                })
                .then((data: any) => {
                    setTestSuites((prevTestSuites) => {
                        const prevIds = new Set(prevTestSuites.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevTestSuites.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setTestSuitesIsLoading(false));
        };

        useEffect(() => {
            setFilterTestSuites(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={testSuitesIsLoading}
                options={testSuites.items.map((testSuite: any) => ({
                    name: testSuite.name,
                    value: testSuite.id,
                    friendly_id: testSuite.friendly_id,
                }))}
                pageCount={testSuites.pageCount}
                searchLocal={false}
                values={filterTestSuites}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterTestSuites(value)}
                getOptions={fetchTestSuiteOptions}
                onSave={(savedTestSuites) => onChange({ [keyOptions]: savedTestSuites })}
            />
        );
    }
);
