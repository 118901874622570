import { action, makeObservable, observable } from "mobx";
import PaginationStore from "./paginationStore";

class ReleaseStore extends PaginationStore {
    store;

    releases = [];
    error = {
        new: "",
        delete: "",
        edit: "",
    };

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            releases: observable,
        });
    }

    setReleases = action((rel) => {
        this.releases = rel;
    });

    setNewErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.error.new = err[0].msg;
        } else {
            this.error.new = err;
        }
    });

    setDeleteErrorMessage = action((err) => {
        this.error.delete = err;
    });

    setEditErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.error.edit = err[0].msg;
        } else {
            this.error.edit = err;
        }
    });

    getAllReleases = action((page = 1, size = 50) => {
        return this.store.apiStore
            .get({
                url: `/release/?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setReleases(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((e) => {
                Promise.reject("Unable to get releases");
            });
    });

    getReleaseById = (id) => {
        if (id === null) return;

        const currentRelease = this.releases.find((release) => release.id === id);

        if (!!currentRelease) return currentRelease;

        return this.store.apiStore
            .get({
                url: `/release/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((release) => {
                this.setReleases(this.releases.concat([release]));
                return release;
            })
            .catch((e) => {
                Promise.reject("Unable to get release");
            });
    };

    createNewRelease = action(({ name, desc: description, is_active = true }) => {
        return this.store.apiStore
            .post({
                url: "/release/",
                body: JSON.stringify({
                    name: name,
                    description: description,
                    is_active: is_active,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return Promise.all([this.getAllReleases(this.page, this.size)]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewErrorMessage(err));
    });

    editRelease = action(({ id: release_id, desc: description = null, is_active = true }) => {
        let finalBody = {
            description: description,
            is_active: is_active,
        };
        if (is_active === null) {
            delete finalBody["is_active"];
        }
        return this.store.apiStore
            .put({
                url: `/release/${release_id}`,
                body: JSON.stringify(finalBody),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getAllReleases(this.page, this.size);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewErrorMessage(err));
    });

    deleteRelease = action((id) => {
        return this.store.apiStore
            .delete({
                url: `/release/${id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getAllReleases(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteErrorMessage(err));
    });

    combineTableForSearch(filters) {
        let tables = [];
        tables.push({
            table_name: "release",
        });
        return tables;
    }

    combineFiltersForSearch = (filters, type) => {
        let items = [];

        Object.entries(filters).map((key, value) => {
            if (key[0] === "name") {
                items.push({
                    column: "release.name",
                    is_value_column: false,
                    search_value: `%${key[1]}%`,
                    search_type: "like",
                });
            } else if (key[0] === "is_active") {
                key[1].map((val) => {
                    items.push({
                        column: "release.is_active",
                        is_value_column: false,
                        search_value: `${val ? "1" : "0"}`,
                        search_type: "eq",
                    });
                });
            } else if (key[0] === "id") {
                items.push({
                    column: "release.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            }
        });
        return [
            {
                logical_opperand: type ? type : "and",
                items: items,
            },
        ];
    };

    searchRelease = ({ filters, pagination = true, page = 1, size = 50, type = "and", returned = false }) => {
        const currentReleases = this.releases.filter((release) => filters?.id?.includes(release.id));

        if (!pagination && filters?.id?.length === currentReleases.length) {
            return Promise.resolve(currentReleases);
        }

        let filtersToSend = this.combineFiltersForSearch(filters, type);
        let tables = this.combineTableForSearch(filters);

        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: pagination, page: page, size: size }),
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;

                if (data) {
                    if (pagination) {
                        this.setReleases(data["items"]);
                        this.setTotal(data.total);
                        this.setPage(data["page"]);
                        this.setSize(data.size);
                        this.setPagination(true);
                    } else {
                        this.setReleases(data);
                        this.setTotal(data.length);
                        this.setSize(data.length);
                        this.setPagination(false);
                    }
                } else {
                    this.setReleases([]);
                    this.setTotal(0);
                    this.setPagination(false);
                }

                return data;
            })
            .catch((err) => {
                this.setReleases([]);
                this.setTotal(0);
                this.setPagination(false);
            });
        // .catch(err => this.setNewConnectionError(err))
    };

    getAdHocRelease = () => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "release" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "release.name",
                                    is_value_column: false,
                                    search_value: "ad_hoc",
                                    search_type: "eq",
                                },
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return data;
                } else {
                    return [];
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    };
}

export default ReleaseStore;
