import { action, observable } from "mobx";

export const DEFAULT_PAGE_SIZE = 50;

class PaginationStore {
    page = 1;
    size = DEFAULT_PAGE_SIZE;
    pagination = true;
    total = 0;

    constructor(store) {
        this.store = store;

        observable(this, {
            page: observable,
            size: observable,
            pagination: observable,
            total: observable,
        });
    }

    setSize = action((size) => {
        this.size = size;
    });

    setPage = action((page) => {
        this.page = page;
    });

    setPagination = action((page) => {
        this.pagination = page;
    });

    setTotal = action((tot) => {
        this.total = tot;
    });
}
export default PaginationStore;
