import { Badge, Button, Card, Descriptions, Empty, Input, List, Row, Space, Tag, theme, Typography } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import { useDebouncedValue } from "../../../hooks";
import { ThemeContext } from "../../../themeProvider";
import { DqResultTooltipContent } from "../../pages/data_quality/DqResultTooltipContent";
import { tag_status_mapper } from "../../types/testCaseType";
import { TagTheme } from "../TagTheme";

interface Props {
    tooltipRef?: any;
    id: string;
    data: any[];
    position: Record<string, string>;
    date: string;
    onClose: () => void;
    showTotal?: boolean;
    showRatio?: boolean;
}

export const dqChartTooltipWidth = 500;
export const dqChartTooltipHeight = 450;

export const DqChartTooltip: React.FC<Props> = ({
    tooltipRef,
    id,
    data,
    position,
    date,
    onClose,
    showTotal = false,
    showRatio = false,
}) => {
    const { useToken } = theme;
    const { token } = useToken();
    const { isDarkMode } = useContext(ThemeContext);
    const [inputValue, setInputValue] = useState("");
    const [dqRules, setDqRules] = useState(data);

    const debouncedInputValue = useDebouncedValue(inputValue, 500);

    useEffect(() => {
        setDqRules(data);
    }, [data]);

    useEffect(() => {
        handleSearchedData();
    }, [debouncedInputValue]);

    const handleSearchedData = () => {
        const isMatchedString = (str: string): boolean => str.toString().toLowerCase().includes(debouncedInputValue.toLowerCase());

        if (!debouncedInputValue) {
            setDqRules(data);
        } else {
            setDqRules(data.filter((item) => isMatchedString(item.friendly_id) || isMatchedString(item.name)));
        }
    };

    const TooltipTitle = <Typography.Title level={5}>Executed DQ Rules on {date}</Typography.Title>;
    const totalCount = dqRules.reduce((acc, dqRule) => acc + dqRule.executionsCount, 0);

    return (
        <div
            ref={tooltipRef}
            className="dq-chart-tooltip"
            style={{
                position: "absolute",
                backgroundColor: isDarkMode ? token.colorPrimaryBgHover : "#fff",
                border: "1px solid #ccc",
                borderRadius: 10,
                padding: "15px 20px",
                zIndex: 100,
                pointerEvents: "auto",
                width: "100%",
                overflow: "hidden",
                maxWidth: `${dqChartTooltipWidth}px`,
                maxHeight: `${dqChartTooltipHeight}px`,
                ...position,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Row style={{ justifyContent: "space-between", width: "100%" }}>
                <Space size={16}>
                    {showTotal ? (
                        <Badge count={totalCount} style={{ marginTop: 2, marginRight: -12 }}>
                            {TooltipTitle}
                        </Badge>
                    ) : (
                        TooltipTitle
                    )}
                </Space>
                <Button type="link" icon={<RiCloseFill />} onClick={onClose} style={{ minHeight: 32, marginRight: -10, marginTop: -10 }} />
            </Row>
            <Input
                allowClear
                id={`input--search`}
                value={inputValue}
                placeholder={`Search DQ Rule`}
                addonBefore={<AiOutlineSearch />}
                onChange={(e: any) => setInputValue(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            {dqRules.length ? (
                <List style={{ maxHeight: `calc(${dqChartTooltipHeight}px - 110px)`, overflowY: "auto" }}>
                    {dqRules.map((item) => (
                        <List.Item key={`${id}-${item.friendly_id}-list-item`} style={{ borderBottom: "none", paddingTop: 0 }}>
                            <Card style={{ width: "100%" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                        <TagTheme text={item.friendly_id} style={{ margin: 0, whiteSpace: "nowrap" }} />
                                        <Badge className="full_width_badge" count={item.executionsCount}>
                                            <Tag
                                                id="dq_results_metric"
                                                className={tag_status_mapper[item.test_result]}
                                                style={{ margin: 0 }}
                                            >
                                                {item.test_result}
                                            </Tag>
                                        </Badge>
                                    </Space>
                                    <Typography.Link
                                        href={`/data_quality/results/${item.id}`}
                                        className="text-hover-link"
                                        style={{
                                            fontWeight: "bold",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {item.name}
                                    </Typography.Link>
                                    {item.description && (
                                        <Typography.Paragraph
                                            type="secondary"
                                            style={{ marginBottom: 8, wordBreak: "break-all" }}
                                            ellipsis={{
                                                rows: 2,
                                                expandable: true,
                                                symbol: "Show all",
                                            }}
                                        >
                                            {item.description}
                                        </Typography.Paragraph>
                                    )}
                                    <Descriptions>
                                        <Descriptions.Item label={"Last Run"} style={{ paddingBottom: 0 }}>
                                            <Typography>{dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {showRatio && <DqResultTooltipContent failed={item.failed} total={item.total} />}
                                </Space>
                            </Card>
                        </List.Item>
                    ))}
                </List>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    );
};
