import { message } from "antd";
import dayjs from "dayjs";
import { action, makeObservable, observable } from "mobx";
import { Edge, Node } from "reactflow";
import { PARAMETERS_MODE, Task } from "../enums";
import { getScheduleAsText } from "../view/pages/executions/simple/steps/executionSCJ/schedulerUtils";
import { isDagTemplate } from "../view/pages/workflow/utils";
import { IDagTemplate } from "../view/types";
import { TestApproachType } from "../view/types/testApproachType";
import { TestCase } from "../view/types/testCaseType";
import { TestSuiteType } from "../view/types/testSuiteType";
import AppStore from "./appStore";
import PaginationStore from "./paginationStore";

export type SettingsSteps = {
    name: string;
    title: string;
    description: string;
    parameters: SettingsStepsParameters;
};

export type SettingsStepsParameters = {
    title: string;
    type: string;
    properties: any;
};

enum DagType {
    Execution = "execution",
    Template = "template",
}

export const dagTemplatesPathname = "dag_templates";

class DagStore extends PaginationStore {
    store;
    settings: any = {};
    tasks: any[] = [];
    schedule: any = null;
    scheduleMessage: string = "";
    templates: IDagTemplate[] = [];
    error: string = "";
    notification = true;
    initWorkflow = null;

    constructor(store: AppStore) {
        super();

        this.store = store;
        makeObservable(this, {
            templates: observable,
            tasks: observable,
            settings: observable,
            schedule: observable,
            error: observable,
            initWorkflow: observable,
            notification: observable,
        });
    }

    reset = () => {
        this.schedule = null;
        this.scheduleMessage = "";
        this.error = "";
    };

    setError = action((data: any) => {
        this.error = data;
    });

    setNotification = action((data: any) => {
        this.notification = data;
    });

    setSchedule = action((schedule: any) => {
        this.schedule = schedule;
        this.scheduleMessage = getScheduleAsText(schedule);
    });

    setTasks = action((execution_id: number, data: any[]) => {
        this.tasks[execution_id] = data;
    });

    setSettings = action((data: SettingsSteps[], settings_type: string) => {
        this.settings[settings_type] = data;
    });

    setTemplates = action((data: IDagTemplate[]) => {
        this.templates = data;
    });

    setInitWorkflow = action((data: any) => {
        this.initWorkflow = data;
    });

    getErrorHandler = async (error, reject) => {
        const errorData = await error.json();
        const errorMessage = typeof errorData.detail === "string" ? errorData.detail : errorData.detail?.msg || errorData.detail?.[0]?.msg;
        this.setError(errorMessage);
        reject(errorMessage);
    };

    getSettingsOptionFromGroup = (group: string, value_name: string) => {
        if (this.settings[group] && Object.keys(this.settings[group])?.length > 0 && value_name) {
            let item = Object.entries(this.settings[group])
                .filter((i: any) => i[1]["name"] === value_name)
                .map((a) => a[1]);
            if (item.length === 1) {
                return item[0];
            }
        }
    };

    getDagSettings = action((settings_type = "STEPS") => {
        if (this.settings[settings_type]) {
            return Promise.resolve(this.settings[settings_type]);
        }

        return this.store.apiStore
            .get({
                url: `/dag/settings?settings_type=${settings_type}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSettings(data, settings_type);
            })
            .catch((e) => {
                Promise.reject("Unable to get settings");
            });
    });

    getDagResultForExecution = action((execution_id: number) => {
        return this.store.apiStore
            .get({
                url: `/dag/structure/${execution_id}?id_type=EXECUTION`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTasks(execution_id, data);
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get dag execution details");
            });
    });

    preparePayloadForTask = (nodeData: any) => {
        let pay = {};
        if ([Task.CUSTOM, Task.ETL].includes(nodeData.task)) {
            let release = nodeData.release_id;
            let approach = nodeData.test_approach ? nodeData.test_approach.map((it: TestApproachType) => it.id) : [];
            let suites = nodeData.test_suite.map((it: TestSuiteType) => it.id);
            let cases = nodeData.test_case ? nodeData.test_case.map((it: TestCase) => it.id) : [];
            let tags = nodeData.tags ? nodeData.tags : [];
            if (nodeData?.task === Task.CUSTOM) {
                let repo_id = nodeData.repo_id;

                pay = {
                    function: Task.CUSTOM,
                    args: [],
                    kwargs: {
                        release_id: release,
                        filters: [
                            {
                                repo_id: repo_id,
                                test_cases: cases,
                                test_suites: suites,
                                test_approaches: approach,
                                test_tags: tags?.tags,
                                separate_tags: tags?.separate_tags || false,
                            },
                        ],
                        runtime_variables: nodeData?.runtime_variables
                            ? this.store.executionStore.checkRuntimeVariables(nodeData?.runtime_variables)
                            : {},
                    },
                    parameters_mode: nodeData.parameters_mode ? nodeData.parameters_mode : PARAMETERS_MODE.ISOLATED,
                    // on_error: {
                    //   function: "test_celery",
                    //   args: ["A2"],
                    //   kwargs: {},
                    // },
                    // post_execution_callback: null,
                };
            } else if (nodeData?.task === Task.ETL) {
                let database = nodeData.db_config_id;
                pay = {
                    function: Task.ETL,
                    args: [],
                    kwargs: {
                        release_id: release,
                        filters: [
                            {
                                test_type: "N",
                                db_id: database,
                                test_cases: cases,
                                test_suites: suites,
                                test_approaches: approach,
                                test_tags: tags?.tags,
                                separate_tags: tags?.separate_tags || false,
                            },
                        ],
                        runtime_variables: nodeData?.runtime_variables
                            ? this.store.executionStore.checkRuntimeVariables(nodeData?.runtime_variables)
                            : {},
                    },
                    parameters_mode: nodeData.parameters_mode ? nodeData.parameters_mode : PARAMETERS_MODE.ISOLATED,
                    // on_error: {},
                    // post_execution_callback: null,
                };
            }
        } else if (nodeData?.task === Task.METADATA) {
            let database = nodeData.db_config_id;

            pay = {
                function: Task.METADATA,
                args: [],
                kwargs: {
                    settings: {
                        db_config_id: database,
                        full_flow: true,
                        exclude: nodeData.metadata_exclude ?? [],
                        include: nodeData.metadata_include ?? [],
                    },
                },
                parameters_mode: "ISOLATED",
                // on_error: {},
                // post_execution_callback: null,
            };
        } else if (nodeData?.task === Task.PROFILING) {
            let database = nodeData.db_config_id;
            pay = {
                function: Task.PROFILING,
                args: [],
                kwargs: {
                    db_config_id: database,
                    exclude: nodeData.profiling_exclude ?? [],
                    include: nodeData.profiling_include ?? [],
                },
                parameters_mode: "ISOLATED",
                // on_error: {},
                // post_execution_callback: null,
            };
        } else if (nodeData?.task === Task.RECON) {
            pay = {
                function: Task.RECON,
                args: [],
                kwargs: {
                    release_id: nodeData?.settings?.release_id,
                    generate_only: nodeData?.settings?.generate_only,
                    execution_name: nodeData?.settings?.execution_name,
                    reconciliation_settings: {
                        source_db_config_id: nodeData?.settings?.reconciliation_settings?.source_db_config_id,
                        target_db_config_id: nodeData?.settings?.reconciliation_settings?.target_db_config_id,
                        comparison_object: nodeData?.settings?.reconciliation_settings?.comparison_object,
                        target_comparison_object: nodeData?.settings?.reconciliation_settings?.target_comparison_object,
                    },
                    runtime_variables: {},
                },
                parameters_mode: "ISOLATED",
                // on_error: {},
                // post_execution_callback: null,
            };
        }
        if (!pay?.["kwargs"]?.["runtime_variables"] || !Object.keys(pay?.["kwargs"]?.["runtime_variables"])?.length) {
            delete pay["kwargs"]["runtime_variables"];
        }

        return pay;
    };

    createPayloadForDags = (
        nodes: Node[],
        edges: Edge[],
        workflow_name: string = `dag-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`,
        type = DagType.Execution,
        tags?: string[],
        description?: string
    ) => {
        let payload_relations = {};
        let payload = {};
        let items = nodes.map((node: Node) => this.preparePayloadForNode(node));

        this.prepareRelationsPayload(edges, payload_relations);

        if (type === DagType.Execution) {
            payload = {
                execution_name: workflow_name,
                relations: payload_relations,
                nodes: items,
                notify: this.notification,
                callbacks: [],
            };
        }

        if (type === DagType.Template) {
            payload = {
                template: {
                    relations: payload_relations,
                    nodes: items,
                    callbacks: [],
                    notify: this.notification,
                },
                description: description,
                name: workflow_name,
                ...(tags && { tags }),
            };
        }

        return payload;
    };

    prepareRelationsPayload = (edges: Edge[], payload_relation: any) => {
        edges.map((it: Edge) => {
            if (it.source !== "start") {
                if (!Object.keys(payload_relation).includes(it.source)) {
                    payload_relation[it.source] = [];
                }
                if (payload_relation[it.source]?.indexOf(it.target) === -1 && it.target !== "end") {
                    payload_relation[it.source].push(it.target);
                }
            }

            return payload_relation;
        });
        let values = [...Object.values(payload_relation)];
        let relation_val = [].concat(...values.filter((i: any) => i.length !== 0).map((i) => i));
        if (values.filter((i: any) => i.length !== 0)?.length > 0) {
            Object.entries(payload_relation).map((item: any) => {
                if (item[1]?.length === 0 && relation_val.includes(item[0])) {
                    delete payload_relation[item[0]];
                }
            });
        }

        return payload_relation;
    };

    preparePayloadForNode = (node: Node | any) => {
        let node_payload: any = {
            name: node.id || node.data?.id,
            user_defined_name: node.data?.user_defined_name || node.data?.name || node.id,
            execution_mode: "CONCURRENT",
            trigger_conditions: node.data?.condition || ["ANY"],
            failture_tolerance: 0,
            items: [],
        };
        let nodeItems: any = this.preparePayloadForTask(node.data);
        node_payload["items"].push(nodeItems);

        return node_payload;
    };

    createDagExecution = action(
        (nodes: Node[], edges: Edge[], workflowName: string = `dag-execution-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`) => {
            return new Promise((resolve, reject) => {
                if (this.schedule) {
                    this.createScheduleExecution({ nodes, edges, executionName: workflowName }).then(resolve).catch(reject);
                    return;
                }

                let payload = this.createPayloadForDags(nodes, edges, workflowName);
                let url = "/dag/";

                if (workflowName) {
                    url = `${url}?execution_name=${workflowName}`;
                }

                this.store.apiStore
                    .post({
                        url,
                        body: JSON.stringify(payload),
                        headers: { accept: "application/json", "Content-Type": "application/json" },
                        auth_headers: this.store.authStore.getAuthHeader(),
                    })
                    .then(async (response: any) => {
                        const data = await response.json();

                        this.store.intervalStore.updateTaskUpdator(data.task_id, "execution");
                        this.store.intervalStore.registerWatcher();
                        this.store.intervalStore.pushNew({
                            task: data.task_id,
                            content: `DAG Submit`,
                            status: "SUCCESS",
                            type: "DAG",
                        });

                        resolve(data);
                    })
                    .catch((error) => this.getErrorHandler(error, reject));
            });
        }
    );

    rerunDag = (item_id: any) => {
        return this.store.apiStore
            .post({
                url: `/dag/rerun/${item_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({}),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.pushNew({
                    task: data.task_id,
                    content: `DAG Submit`,
                    status: "SUCCESS",
                    type: "DAG",
                    link: `/executions/${item_id}`,
                });
            })
            .catch((err) => {
                this.setError(err);
                message.info("Error when submit dag rerun execution...Please try again");
            });
    };

    createScheduleExecution = action(({ data = null, executionName = "", nodes = [], edges = [] }) => {
        let payload = data ? { ...data, execution_name: executionName } : this.createPayloadForDags(nodes, edges, executionName);
        let url = `/schedule/?trigger_type=${this.schedule.type}`;

        let schedule_payload = {
            function_name: this.schedule.taskType,
            trigger_params: this.store.croneJobStore.getTriggerParams(this.schedule),
            schedule_description: this.scheduleMessage,
            execution_params: payload,
        };

        return new Promise((resolve, reject) => {
            this.store.apiStore
                .post({
                    url: url,
                    body: JSON.stringify(schedule_payload),
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();

                    this.store.intervalStore.pushNew({
                        task: data.task_id,
                        content: `DAG Scheduler Submit`,
                        status: "SUCCESS",
                        type: "DAG",
                    });

                    resolve(data);
                })
                .catch((error) => this.getErrorHandler(error, reject));
        });
    });

    getDagStatus = (execution_id: number) => {
        return this.store.apiStore
            .get({
                url: `/dag/status/${execution_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response: any) => response.json())
            .then((data: any) => {
                return data;
            })
            .catch((err: any) => err.json().then((data: any) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])));
        // .catch((err) => this.setAppVer({ app_version: "undefined" }));
    };

    createDagTemplate = action(
        (
            nodes: Node[],
            edges: Edge[],
            workflowName: string = `dag-execution-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`,
            tags: string[],
            description: string
        ) => {
            let payload = this.createPayloadForDags(nodes, edges, workflowName, DagType.Template, tags, description);
            let url = `/${dagTemplatesPathname}/`;

            if (workflowName) {
                url = `${url}?name=${workflowName}`;
            }

            return new Promise((resolve, reject) => {
                this.store.apiStore
                    .post({
                        url,
                        body: JSON.stringify(payload),
                        headers: { accept: "application/json", "Content-Type": "application/json" },
                        auth_headers: this.store.authStore.getAuthHeader(),
                    })
                    .then(async (response) => {
                        const data = await response.json();
                        resolve(data);
                    })
                    .catch((error) => this.getErrorHandler(error, reject));
            });
        }
    );

    getDagTemplates = action(({ filters = [], page = 1, size = 50 }) => {
        return new Promise((resolve, reject) => {
            this.store.apiStore
                .get({
                    url: this.store.apiStore.getCombinedPaginatedURL(dagTemplatesPathname, filters, page, size),
                    headers: { "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();

                    if (data) {
                        this.setTemplates(data.items);
                        this.setPage(data.page);
                        this.setTotal(data.total);
                        this.setSize(data.size);
                    }

                    resolve(data);
                })
                .catch((error) => this.getErrorHandler(error, reject));
        });
    });

    editDagTemplate = action(({ template, nodes, edges, workflowName, tags, description }) => {
        let payload = this.createPayloadForDags(
            nodes.map((node) => ({ data: node })),
            edges,
            workflowName,
            DagType.Template,
            tags,
            description
        );
        return new Promise((resolve, reject) => {
            this.store.apiStore
                .put({
                    url: `/${dagTemplatesPathname}/${template.dag_id}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                    body: JSON.stringify(payload),
                })
                .then(async (response) => {
                    const data = await response.json();

                    this.setInitWorkflow(null);

                    if (this.schedule) {
                        await this.createScheduleExecution({ nodes, edges, executionName: workflowName });
                    }

                    resolve(data);
                })
                .catch((error) => this.getErrorHandler(error, reject));
        });
    });

    deleteDagTemplate = action((id: string | number) => {
        return new Promise((resolve, reject) => {
            this.store.apiStore
                .delete({
                    url: `/${dagTemplatesPathname}/${id}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();
                    resolve(data?.msg);
                })
                .catch((error) => this.getErrorHandler(error, reject));
        });
    });

    runNowTemplate = action((template: IDagTemplate) => {
        const executionName = template.name;
        let url = isDagTemplate(template.type) ? "/dag/" : "/tasks/execute";

        if (executionName) {
            url = `${url}?execution_name=${executionName}`;
        }

        return new Promise((resolve, reject) => {
            this.store.apiStore
                .post({
                    url,
                    body: JSON.stringify({ ...template.template }),
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response: any) => {
                    const data = await response.json();

                    message.success("Submitted new execution!");
                    this.store.intervalStore.updateTaskUpdator(data.task_id, "execution");
                    this.store.intervalStore.registerWatcher();
                    this.store.intervalStore.pushNew({
                        task: data.task_id,
                        content: `Template Run Now`,
                        status: "SUCCESS",
                        type: "DAG",
                    });

                    resolve(data);
                })
                .catch(async (err: any) => {
                    const errorData = await err.json();
                    const errorMessage = errorData.detail || "Error running template";

                    message.error(errorMessage);
                    reject(errorMessage);
                });
        });
    });
}

export default DagStore;
