import { FC, useEffect, useMemo, useState } from "react";
import { DagTemplateType } from "../../../../../view/types";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const TemplateTypeFilterItem: FC<FilterItemProps> = ({ filters, onChange, keyOptions = "type", label = "Type" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterTestTypes, setFilterTestTypes] = useState(appliedFilter);

    useEffect(() => {
        setFilterTestTypes(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={Object.values(DagTemplateType)
                .filter((type) => type)
                .map((type) => ({ name: type, value: type }))}
            values={filterTestTypes}
            searchLocal={true}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterTestTypes(value)}
            onSave={(savedTestTypes) => onChange({ [keyOptions]: savedTestTypes })}
        />
    );
};
