import { Card, Col, DatePicker, Row, Skeleton, Spin, TreeSelect } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import getValue from "../../common/getValue";
import PageTitle from "../../layout/components/content/page-title";
import { useStore } from "../../stores/StoreContext";
import { LineChart } from "../shared/Charts/LineChart";
import { DbConfigSelectComponent } from "../shared/DbConfigSelectComponent";
import { ColumnChart } from "./columnChart";
import { ExecutionsLineChart } from "./executionsLineChart";
import { RadialBarChart } from "./radialBarChart";
import { StatsTileRow } from "./statsRow";
import { TopFailedTests } from "./topFailedTests";

const Home: React.FC = observer(() => {
    const store = useStore();
    const [loading, setLoading] = useState({
        db_attribute_coverage: true,
        test_case_coverage_expected_actual: true,
        executions_in_time: true,
        automation_trend: true,
        top_failed_tests: true,
        suites: true,
        suites_chart: true,
        test_run_details: false,
        connections: true,
        query_predictions: true,
    });
    const [testRunDetails, setTestRunDetails] = useState(false);
    const [executionsSelect, setExecutionsSelect] = useState(null);
    const [connectionSelect, setConnectionSelect] = useState(null);
    const [queryPredictions, setQueryPredictions] = useState(null);
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [store.authStore.user?.active_project_name]);

    const getDbMetaResults = async (dbConfigId = null) => {
        await store.dbMetaResults.getStatisticsForDb(dbConfigId || store.settingsStore.databases[0].db_config_id);
    };

    const fetchData = async () => {
        setLoading({
            db_attribute_coverage: true,
            test_case_coverage_expected_actual: true,
            executions_in_time: true,
            automation_trend: true,
            top_failed_tests: true,
            suites: true,
            suites_chart: true,
            test_run_details: false,
            connections: true,
            query_predictions: true,
        });
        setTreeData([]);
        setQueryPredictions([]);
        setConnectionSelect(null);
        setExecutionsSelect(null);
        setTestRunDetails(null);
        await store.metricsStore.getExecutionsTrend();
        setLoading((prev) => ({ ...prev, automation_trend: false }));

        await store.metricsStore.getTopFailedTests();
        setLoading((prev) => ({ ...prev, top_failed_tests: false }));

        await store.metricsStore.getExecutionsInTime();
        setLoading((prev) => ({ ...prev, executions_in_time: false }));

        await store.metricsStore.getTestSuiteCoverageExpectedTestCasesVsActual();
        setLoading((prev) => ({ ...prev, test_case_coverage_expected_actual: false }));

        const data = await store.mlPredictionStore.getAllPredictiedQueriesModel();
        setLoading((prev) => ({ ...prev, query_predictions: false }));
        setQueryPredictions(data);

        await Promise.allSettled([store.configStore.getAppSettings(), store.settingsStore.getDatabaseConnections()]);
        setLoading((prev) => ({ ...prev, connections: false }));

        const isDefault = store.settingsStore.databases.filter((it) => it.is_default);
        if (isDefault.length > 0) {
            setConnectionSelect(isDefault[0].db_config_id);
            await getDbMetaResults();
        }
        setLoading((prev) => ({ ...prev, db_attribute_coverage: false }));

        await store.metricsStore.getSuites();
        if (store.metricsStore.suites.length > 0) {
            await store.metricsStore.getSuiteDetails(store.metricsStore.suites[0].test_suite_name);
            setLoading((prev) => ({ ...prev, suites: false }));
            setExecutionsSelect({
                type: "suite",
                data: store.metricsStore.suites[0].test_suite_name,
            });
            setTreeData(
                store.metricsStore.suites.map((it) => ({
                    id: it.test_suite_name,
                    key: `${it.test_suite_name}^suite`,
                    title: it.test_suite_name,
                    value: `${it.test_suite_name}^suite`,
                    isLeaf: false,
                }))
            );
        }
        setLoading((prev) => ({ ...prev, suites_chart: false }));
    };

    const handleSelectDatabaseChange = async (e) => {
        const value = getValue(e);
        if (value) {
            setLoading((prev) => ({ ...prev, db_attribute_coverage: true }));
            setConnectionSelect(value);
            await getDbMetaResults(value);
            setLoading((prev) => ({ ...prev, db_attribute_coverage: false }));
        }
    };

    const automationExecutionTrendChange = async (e) => {
        const value = getValue(e);
        if (value) {
            setLoading((prev) => ({ ...prev, automation_trend: true }));
            await store.metricsStore.getExecutionsTrend(value.$d);
            setLoading((prev) => ({ ...prev, automation_trend: false }));
        }
    };

    const onChangeSelect = async (e) => {
        setTestRunDetails(false);
        setLoading((prev) => ({ ...prev, suites_chart: true }));
        const value = getValue(e);
        const splittedValue = value.split("^");
        const type = splittedValue.at(-1);

        if (type === "suite") {
            const resourceName = splittedValue.slice(0, -1).join("^");
            setExecutionsSelect({ data: resourceName, type: type });
            await store.metricsStore.getSuiteDetails(resourceName);
            setLoading((prev) => ({ ...prev, suites_chart: false }));
        } else if (type === "case") {
            const caseName = splittedValue.slice(1, -1).join("^");
            const suiteName = splittedValue.slice(0, -2).join("^");
            setExecutionsSelect({
                data: caseName,
                parent: suiteName,
                type: type,
            });
            await store.metricsStore.getCaseDetails(suiteName, caseName);
            setLoading((prev) => ({ ...prev, suites_chart: false }));
        }
    };

    const onLoadData = async (e) => {
        const value = getValue(e);
        setLoading((prev) => ({ ...prev, suites: false }));
        const suite = store.metricsStore.suites.find((it) => it.test_suite_name === value.title);
        await store.metricsStore.getSuiteCasesDetails(value.title);
        if (suite.cases.length > 0) {
            setTreeData((prev) => [
                ...prev,
                ...suite.cases.map((cas) => ({
                    id: cas.test_case_name,
                    key: `${suite.test_suite_name}^${cas.test_case_name}^case`,
                    pId: suite.test_suite_name,
                    title: cas.test_case_name,
                    value: `${suite.test_suite_name}^${cas.test_case_name}^case`,
                    isLeaf: true,
                })),
            ]);
        }
        setLoading((prev) => ({ ...prev, suites: false }));
    };

    const getDefaultSuiteRuns = () => {
        if (executionsSelect) {
            if (executionsSelect.type === "suite") {
                return store.metricsStore.suites.find((it) => it.test_suite_name === executionsSelect.data);
            } else {
                return store.metricsStore.suites
                    .find((it) => it.test_suite_name === executionsSelect.parent)
                    .cases.find((it) => it.test_case_name === executionsSelect.data);
            }
        }
    };

    const user = store.authStore.user;
    const testAutomationTrend = store.metricsStore.test_automation_trend?.slice(-10);
    const testAutomationTrendSeries = ["PASS", "FAIL"].map((item) => ({
        name: item,
        data: testAutomationTrend.filter((i) => i.status === item).map((e) => e.count),
    }));
    return (
        <>
            <Col span={24}>
                <Row align="middle" justify="space-between">
                    <Col md={12} span={24}>
                        <PageTitle pageTitle={`Hello${user?.username ? `, ${user?.username}` : ""} 👋`} pageText={"Welcome to XAutomate"} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="da-mt-32">
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <StatsTileRow
                            queryPredictions={queryPredictions}
                            executionInTimeLoading={loading.executions_in_time}
                            queryPredictionsLoading={loading.query_predictions}
                        />
                    </Col>

                    <Col md={16} span={24}>
                        <Card bordered={false} className="da-border-color-black-40">
                            <Row>
                                <Col className="da-mb-16" span={24}>
                                    <Row justify="space-between">
                                        <Row align="bottom" className="da-pb-16">
                                            <h5 className="da-mr-8" id="test_case_coverage_title">
                                                Test Case Coverage - Expected Test Cases vs Actual Test Cases
                                            </h5>
                                        </Row>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    {loading.test_case_coverage_expected_actual ? (
                                        <Skeleton active paragraph={{ rows: 10 }} />
                                    ) : (
                                        <ColumnChart
                                            actual_data={store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                (item) => item.actual_test_cases_count
                                            )}
                                            expected_data={store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                (item) => item.expected_test_case_count
                                            )}
                                            categories={store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                (item) => item.test_suite_name
                                            )}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={8} span={24}>
                        <Card bordered={false} className="da-border-color-black-40">
                            <Row>
                                <Col md={24} span={24}>
                                    <Row align="middle" justify="space-between">
                                        <h5 id="metadata_coverage">Attributes Metadata Coverage</h5>
                                        {loading.connections ? (
                                            <Spin />
                                        ) : (
                                            <DbConfigSelectComponent
                                                id="database-config-dashboard"
                                                dbConnection={connectionSelect}
                                                onDbConnectionChanged={handleSelectDatabaseChange}
                                            />
                                        )}
                                    </Row>

                                    {loading.db_attribute_coverage ? (
                                        <Skeleton active style={{ marginTop: 20 }} paragraph={{ rows: 10 }} />
                                    ) : (
                                        <RadialBarChart connection_select={connectionSelect} />
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col md={8} span={24}>
                        <Card
                            bordered={false}
                            className="da-border-color-black-40 da-project-ecommerce-table-card"
                            style={{ marginBottom: 50 }}
                        >
                            <Row>
                                <Col md={24} span={24}>
                                    <Row align="middle" justify="space-between" style={{ marginBottom: "5%" }}>
                                        <h5 id="executions_title">Executions</h5>

                                        {loading.suites ? (
                                            <Spin />
                                        ) : (
                                            <TreeSelect
                                                id="executions_select_input"
                                                treeDataSimpleMode
                                                showSearch
                                                defaultValue={store.metricsStore.suites[0].test_suite_name}
                                                onChange={onChangeSelect}
                                                placeholder={"Select Suite"}
                                                style={{ width: "80%" }}
                                                loadData={onLoadData}
                                                treeData={treeData}
                                            />
                                        )}
                                    </Row>

                                    {loading.suites && loading.suites_chart && executionsSelect ? (
                                        <Skeleton active paragraph={{ rows: 9 }} />
                                    ) : (
                                        <ExecutionsLineChart
                                            setLoading={setLoading}
                                            setRunDetails={setTestRunDetails}
                                            testRunDetails={testRunDetails}
                                            loadingTcRuns={loading.test_run_details}
                                            loading={loading.suites_chart}
                                            choosen={getDefaultSuiteRuns()}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xl={8} md={6} span={24}>
                        <Card
                            bordered={false}
                            className="da-border-color-black-40 da-project-ecommerce-table-card"
                            style={{ marginBottom: 50 }}
                        >
                            <Row>
                                <Col md={24} span={24}>
                                    <Row align="middle" justify="space-between">
                                        <h5 className="da-mb-32" id="top_failed_tests_title">
                                            Top 5 Failed Tests
                                        </h5>
                                    </Row>
                                    {loading.top_failed_tests ? (
                                        <Skeleton active paragraph={{ rows: 9 }} />
                                    ) : (
                                        <TopFailedTests items={store.metricsStore.top_failed_tests} />
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xl={8} md={8} span={24}>
                        <Card bordered={false} className="da-border-color-black-40" style={{ marginBottom: 50 }}>
                            <Row>
                                <Col className="da-mb-16" span={24}>
                                    <Row justify="space-between">
                                        <Row align="bottom" className="da-pb-16">
                                            <h5 className="da-mr-8" id="execution_trend_title">
                                                Automation Test Execution Trend
                                            </h5>
                                        </Row>

                                        <Col>
                                            <DatePicker onChange={automationExecutionTrendChange} picker="year" defaultValue={dayjs()} />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    {loading.automation_trend ? (
                                        <Skeleton active paragraph={{ rows: 9 }} />
                                    ) : (
                                        <LineChart
                                            id="test-automation-chart"
                                            categories={testAutomationTrend.map((it) => `${dayjs(it.time_sec).format("YYYY-MM-DD HH:mm")}`)}
                                            series={testAutomationTrendSeries}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
    );
});

export default Home;
