import { Space } from "antd";
import { FunctionComponent, useState } from "react";
import { schedule_types_mode } from "../../../../../enums";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { InputFilterItem, SchedulesFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const ScheduleFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange, defaultFilters }) => {
    const [filterTypes, setFilterTypes] = useState(filters?.types || []);

    return (
        <Space wrap>
            <SchedulesFilterItem filters={filters} onChange={onChange} label="ID" keyOptions="id" />
            <InputFilterItem
                filters={filters}
                keyOptions="name"
                placeholder="Search schedule name...."
                onChange={onChange}
                defaultFilters={defaultFilters}
            />
            <DropdownButtonFilter
                loading={false}
                options={Object.keys(schedule_types_mode).map((it: any) => ({ name: it, value: it }))}
                values={filterTypes}
                searchLocal={true}
                buttonLabel={"Types"}
                keyOptions={"types"}
                setOptions={(value) => setFilterTypes(value)}
                onSave={(savedTypes) => onChange({ types: savedTypes })}
            />
        </Space>
    );
};
