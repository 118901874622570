import { FC, useEffect, useMemo, useState } from "react";
import { TEST_TYPE_TECHNOLOGY } from "../../../../../enums";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { FilterItemProps } from "../types";

export const TestTypeFilterItem: FC<FilterItemProps> = ({ filters, onChange, keyOptions = "test_type", label = "Test type" }) => {
    const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);
    const [filterTestTypes, setFilterTestTypes] = useState(appliedFilter);

    useEffect(() => {
        setFilterTestTypes(appliedFilter);
    }, [appliedFilter]);

    return (
        <DropdownButtonFilter
            loading={false}
            options={Object.values(TEST_TYPE_TECHNOLOGY)
                .filter((type) => type)
                .map((type) => ({
                    name: type.toUpperCase(),
                    value: type,
                }))}
            values={filterTestTypes}
            searchLocal={true}
            buttonLabel={label}
            keyOptions={keyOptions}
            setOptions={(value) => setFilterTestTypes(value)}
            onSave={(savedTestTypes) => onChange({ [keyOptions]: savedTestTypes })}
        />
    );
};
