import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const DbConnectionFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "DB Config", keyOptions = "db_config_id" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterDbConnections, setFilterDbConnections] = useState(appliedFilter);
        const [dbConnections, setDBConnections] = useState({ items: [], pageCount: 1 });
        const [dbConnectionsIsLoading, setDBConnectionsIsLoading] = useState(false);

        const fetchDBConnectionOptions = (optionFilters = {}, page = 1) => {
            if (!dbConnections.items.length) setDBConnectionsIsLoading(true);

            store.testsStore
                .searchConnectionData(optionFilters, true, "or", page)
                .then((data) => {
                    if (!data) return;

                    setDBConnections((prevDBConnections) => {
                        const prevIds = new Set(prevDBConnections.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevDBConnections.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setDBConnectionsIsLoading(false));
        };

        useEffect(() => {
            setFilterDbConnections(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={dbConnectionsIsLoading}
                options={dbConnections.items.map((tag: any) => ({
                    name: tag.connection_name,
                    value: tag.db_config_id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={dbConnections.pageCount}
                searchLocal={false}
                values={filterDbConnections}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterDbConnections(value)}
                getOptions={fetchDBConnectionOptions}
                onSave={(savedDbConnections) => onChange({ [keyOptions]: savedDbConnections })}
            />
        );
    }
);
