import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TestApproachesFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Test Approaches", keyOptions = "test_approaches" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterTestApproaches, setFilterTestApproaches] = useState(appliedFilter);
        const [testApproaches, setTestApproaches] = useState({ items: [], pageCount: 1 });
        const [testApproachesIsLoading, setTestApproachesIsLoading] = useState(false);

        const fetchTestApproachOptions = (optionFilters = {}, page = 1) => {
            if (!testApproaches.items.length) setTestApproachesIsLoading(true);

            store.testsStore
                .searchTestApproachData({
                    page,
                    filters: optionFilters,
                    pagination: true,
                    size: optionsSize,
                    type: "or",
                    returned: true,
                })
                .then((data: any) => {
                    setTestApproaches((prevTestApproaches) => {
                        const prevIds = new Set(prevTestApproaches.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevTestApproaches.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setTestApproachesIsLoading(false));
        };

        useEffect(() => {
            setFilterTestApproaches(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={testApproachesIsLoading}
                options={testApproaches.items.map((testApproach: any) => ({
                    name: testApproach.name,
                    value: testApproach.id,
                    friendly_id: testApproach.friendly_id,
                }))}
                pageCount={testApproaches.pageCount}
                searchLocal={false}
                values={filterTestApproaches}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterTestApproaches(value)}
                getOptions={fetchTestApproachOptions}
                onSave={(savedTestApproaches) => onChange({ [keyOptions]: savedTestApproaches })}
            />
        );
    }
);
