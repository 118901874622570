import { message } from "antd";
import dayjs from "dayjs";
import { makeAutoObservable, observable } from "mobx";
import notify from "../assets/music/notify.mp3";
import { ExecutionCeleryStatus } from "../view/pages/executions/results/executions/executionStatuses";

class IntervalStore {
    store;
    interval = null;
    notifications = observable([]);

    constructor(store) {
        makeAutoObservable(this);
        this.store = store;
    }
    audio = new Audio(notify);
    registerWatcher = () => {
        this.interval = setInterval(this.update, 6000);
    };

    resetNotifications = () => {
        this.notifications = [];
    };

    pushNew = (message) => {
        const date = dayjs().format("YYYY-MM-DD HH:mm");
    
        this.notifications = this.notifications.filter(
            (item) => item.task !== message.task || item.content !== message.content
        );
    
        this.notifications = [{ ...message, date }, ...this.notifications];
    };

    unregisterWatcher = () => {
        clearInterval(this.interval);
        this.interval = null;
    };

    update = async () => {
        let tasks_to_update = JSON.parse(sessionStorage.getItem("task_update"));
        if (tasks_to_update) {
            let all_lists = [];
            Object.entries(tasks_to_update).map((result) => (result[1].length > 0 ? all_lists.push(...result[1]) : null));
            if (all_lists.length > 0) {
                return await this.getUpdateTaskStatus(all_lists).then((data) =>
                    data.map((task) => {
                        message.loading({
                            content: `Get update for ${all_lists.length} task/s`,
                            key: "loading",
                            duration: 0,
                        });
                        if (task.status === ExecutionCeleryStatus.SUCCESS) {
                            Object.entries(tasks_to_update).map(async (result) => {
                                if (result[1].length > 0 && result[1].includes(task.task_id)) {
                                    result[1].pop(task.task_id);
                                    sessionStorage.setItem("task_update", JSON.stringify(tasks_to_update));
                                    this.audio.play();
                                    return await message
                                        .success({
                                            content: `${result[0]} task - (${task.task_id}) - finished successfully`,
                                            duration: 5,
                                            onClose: this.pushNew({
                                                task: task.task_id,
                                                content: `${result[0].toUpperCase()} Update`,
                                                status: "SUCCESS",
                                                type: result[0],
                                            }),
                                        })
                                        .then(async () => {
                                            if (result[0].toUpperCase() === "FULL_FLOW") {
                                                this.store.dataQualityStore.updateMetadataStatus(task.task_id);
                                            }
                                            if (result[0].toUpperCase() === "METADATA") {
                                                if (Object.keys(this.store.dbMetaResults.all).length > 0) {
                                                    Object.keys(this.store.dbMetaResults.all).map(
                                                        async (id) => await this.store.dbMetaResults.getTotalResultsForDb(id)
                                                    );
                                                }
                                            } else if (result[0].toUpperCase() === "GIT") {
                                                if (window?.location?.pathname?.includes('/tests/cases') || this.store.testCaseStore.testsCases.length > 0) {
                                                    this.props.store.testsStore.searchTestCaseData({ pagination: true });
                                                }
                                            } else if (
                                                ["ML TRAINING & PREDICTION", "ML RETRAINING & PREDICTION"].includes(result[0].toUpperCase())
                                            ) {
                                                if (Object.keys(this.store.mlPredictionStore.query_prediction).length > 0) {
                                                    Object.keys(this.store.mlPredictionStore.query_prediction).map(
                                                        async (id) =>
                                                            await this.store.mlPredictionStore.getQueryPredictions({ db_config_id: id })
                                                    );
                                                }
                                            } else if (result[0].toUpperCase() === "REQUIREMENTS") {
                                                this.store.requirementStore.resetStatesAndTypes();
                                                this.store.requirementStore.setActivatedSync({ status: "DONE" });
                                                return await this.store.requirementStore.getAllRequirements();
                                            } else if (result[0].toUpperCase() === "PROFILING") {
                                                if (Object.keys(this.store.reconciliationStore.profiling_results).length > 0) {
                                                    Object.keys(this.store.reconciliationStore.profiling_results).map(
                                                        async (id) => await this.store.reconciliationStore.getProfileResults(id)
                                                    );
                                                }
                                            }
                                        });
                                } else {
                                    return;
                                }
                            });
                        } else if (task.status === ExecutionCeleryStatus.FAILURE) {
                            Object.entries(tasks_to_update).map(async (result) => {
                                if (result[1].length > 0 && result[1].includes(task.task_id)) {
                                    result[1].pop(task.task_id);
                                    sessionStorage.setItem("task_update", JSON.stringify(tasks_to_update));
                                    return await message.error({
                                        content: `${result[0]} task - (${task.task_id}) - failed`,
                                        duration: 5,
                                        onClose: this.pushNew({
                                            task: task.task_id,
                                            content: `${result[0].toUpperCase()} Update`,
                                            status: "FAILURE",
                                            type: result[0],
                                        }),
                                    });
                                } else {
                                    return;
                                }
                            });
                    } else if (task.status === ExecutionCeleryStatus.REVOKED) {
                        Object.entries(tasks_to_update).map(async (result) => {
                            if (result[1].length > 0 && result[1].includes(task.task_id)) {
                                result[1].pop(task.task_id);
                                sessionStorage.setItem("task_update", JSON.stringify(tasks_to_update));
                                
                            } else {
                                return;
                            }
                        });
                    }
                    })
                );
            } else {
                sessionStorage.removeItem("task_update");
                if (this.interval) {
                    message.success({ content: `Loaded!`, key: "loading", duration: 5 });
                }
                this.unregisterWatcher();
            }
        } else {
            this.unregisterWatcher();
        }
    };

    updateTaskUpdator = (task_id, property) => {
        let current = JSON.parse(sessionStorage.getItem("task_update"));
        if (current) {
            if (current[property]) {
                current[property] = current[property].concat(task_id);
                sessionStorage.setItem("task_update", JSON.stringify(current));
            } else {
                current[property] = [task_id];
                sessionStorage.setItem("task_update", JSON.stringify(current));
            }
        } else {
            sessionStorage.setItem("task_update", JSON.stringify({ [property]: [task_id] }));
        }
    };

    getUpdateTaskStatus = (task_ids) => {
        return this.store.apiStore
            .post({
                url: `/tasks/results`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(task_ids),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch(err => this.setUpdateTaskError(task_id, err))
    };
}
export default IntervalStore;
