import { message } from "antd";
import dayjs from "dayjs";
import * as FileSaver from "file-saver";
import { action } from "mobx";
import * as XLSX from "xlsx";
import env from "../env/environment";
import { getCombinedUrl } from "../utils";

class APIStore {
    store;

    constructor(store) {
        this.store = store;
    }

    static checkStatus = action((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        if (response.status === 403 && !window.location.href.includes("login")) {
            window.location.href = "/login";
        }
        return Promise.reject(response);
    });

    appendParams(url, params) {
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    }

    get = action(({ url, params = {}, headers = {}, auth_headers = {}, prefix_with_env = true } = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL_V2}${url}` : `${url}`);
        headers = { ...headers, ...auth_headers };
        this.appendParams(url, params);
        return fetch(url, {
            method: "GET",
            headers: headers,
        }).then(APIStore.checkStatus);
    });

    post = action(({ url, params = {}, body = {}, headers = {}, auth_headers = {}, prefix_with_env = true } = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL_V2}${url}` : `${url}`);
        headers = { ...headers, ...auth_headers };
        this.appendParams(url, params);
        return fetch(url, {
            method: "POST",
            body: body,
            headers: headers,
        }).then(APIStore.checkStatus);
    });

    put = action(({ url, params = {}, body = {}, headers = {}, auth_headers = {}, prefix_with_env = true } = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL_V2}${url}` : `${url}`);
        headers = { ...headers, ...auth_headers };
        this.appendParams(url, params);
        return fetch(url, {
            method: "PUT",
            body: body,
            headers: headers,
        }).then(APIStore.checkStatus);
    });

    delete = action(({ url, params = {}, body = {}, headers = {}, auth_headers = {}, prefix_with_env = true } = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL_V2}${url}` : `${url}`);
        headers = { ...headers, ...auth_headers };
        this.appendParams(url, params);
        return fetch(url, {
            method: "DELETE",
            body: body,
            headers: headers,
        }).then(APIStore.checkStatus);
    });

    get_static_url = action(() => {
        return `${env.API_STATIC}/static`;
    });

    saveFile = action(({ url, headers = {}, auth_headers = {}, prefix_with_env = true } = {}) => {
        headers = { ...headers, ...auth_headers };
        return this.get({
            url: url,
            headers: headers,
            prefix_with_env: prefix_with_env,
        }).then(APIStore.checkStatus);
    });

    getSupportBundle = () => {
        return this.get({
            url: `/support/?daily=true`,
            auth_headers: this.store.authStore.getAuthHeader(),
        })
            .then((response) => {
                response.blob().then((blob) => this.downloadAsFile(blob, `support_bundle_${dayjs().format("YYYY-MM-DD-HH-mm")}.zip`));
            })
            .catch((e) => {
                message.error("Unable to get support bundle. Try again later.");
                Promise.reject("Unable to get support bundle");
            });
    };

    downloadAsFile = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    // only used in test suite download template
    exportToExcel = (csvData, fileName, multiple = false) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        var wb = XLSX.utils.book_new();
        if (!multiple) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(csvData), "data");
        } else {
            Object.keys(csvData).map((key) => XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(csvData[key]), key));
        }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        return FileSaver.saveAs(data, fileName + fileExtension);
    };

    getCombinedPaginatedURL = (pathname, filters, page, size) => {
        let url = `/${pathname}/?page=${page}&size=${size}`;

        Object.keys(filters).forEach((filterKey) => {
            if (filters[filterKey]) {
                if (Array.isArray(filters[filterKey])) {
                    filters[filterKey].forEach((value) => {
                        url = getCombinedUrl(url, filterKey, value);
                    });
                } else {
                    url = getCombinedUrl(url, filterKey, filters[filterKey]);
                }
            }
        });

        return url;
    };
}
export default APIStore;
