import { useEffect, useState } from "react";
import { dqChartTooltipWidth } from "../view/shared/DqCharts/DqChartTooltip";

export const useDqChartTooltip = (chartRef = null) => {
    const [activeTooltip, setActiveTooltip] = useState(null);

    let tooltipX = 0;
    let tooltipY = 0;
    let tooltipStyle = { top: "0px", left: "unset", right: "unset" };

    const handleTooltipVisibility = (series, event, seriesIndex, dataPointIndex) => {
        if (seriesIndex !== undefined && dataPointIndex !== undefined && dataPointIndex !== -1) {
            const pointData = series[seriesIndex].data[dataPointIndex];
            const chartElement = chartRef.current.getBoundingClientRect();

            if (!activeTooltip) {
                tooltipX = event.clientX - chartElement.left;
                tooltipY = event.clientY - chartElement.top;
                tooltipStyle = { top: `${tooltipY}px`, left: "unset", right: "unset" };

                if (tooltipX + dqChartTooltipWidth > chartElement.width) {
                    tooltipStyle.right = `${chartElement.width - tooltipX}px`;
                } else {
                    tooltipStyle.left = `${tooltipX < 0 ? 10 : tooltipX}px`;
                }
            }

            setActiveTooltip({
                ...pointData,
                details: Object.values(
                    pointData.details.reduce((acc, item) => {
                        const existingItem = acc[item.id];

                        if (existingItem) {
                            existingItem.executionsCount += 1;
                            return acc;
                        }

                        acc[item.id] = {
                            id: item.id,
                            friendly_id: item.friendly_id,
                            date: item.date,
                            name: item.name,
                            description: item.description,
                            test_result: item.test_result,
                            total: item.total,
                            failed: item.failed,
                            passed: item.passed,
                            executionsCount: 1,
                        };

                        return acc;
                    }, {})
                ),
                position: { ...tooltipStyle, y: tooltipY },
            });
        }
    };

    const adjustTooltipPosition = () => {
        const tooltipElement = document.querySelector(".dq-chart-tooltip");
        if (tooltipElement && activeTooltip?.position?.y) {
            const tooltipHeight = tooltipElement.getBoundingClientRect().height;
            const chartBounds = chartRef.current.getBoundingClientRect();

            let tooltipY = activeTooltip.position.y;

            if (tooltipY + tooltipHeight > chartBounds.height) {
                tooltipY = chartBounds.height - tooltipHeight - 10;
            }

            if (tooltipY < 0) {
                tooltipY = 10;
            }

            if (tooltipY !== activeTooltip.position.y) {
                setActiveTooltip((prevTooltip) => ({
                    ...prevTooltip,
                    position: {
                        ...prevTooltip.position,
                        top: `${tooltipY}px`,
                    },
                }));
            }
        }
    };

    const handleOutsideClick = (event) => {
        if (chartRef.current && !chartRef.current.contains(event.target)) {
            handleTooltipClose();
        }
    };

    const handleTooltipClose = () => {
        setActiveTooltip(null);
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (activeTooltip) {
            adjustTooltipPosition();
        }
    }, [activeTooltip?.position?.y]);

    return { activeTooltip, handleTooltipVisibility, handleTooltipClose };
};
