import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface Props {
    onChange: (value: any) => void;
    fromDate?: string;
    toDate?: string;
}

export const filterDateFormat = "YYYY-MM-DD HH:mm:ss";
export const defaultFilterFromDate = dayjs().subtract(7, "days").startOf("day");
export const defaultFilterToDate = dayjs().endOf("day");

export const DatePickerFilter: React.FC<Props> = ({ fromDate, toDate, onChange }) => {
    const [initialFromDate, setInitialFromDate] = useState(fromDate ? dayjs(fromDate) : defaultFilterFromDate);
    const [initialToDate, setInitialToDate] = useState(toDate ? dayjs(toDate) : defaultFilterToDate);

    useEffect(() => {
        setInitialFromDate(fromDate ? dayjs(fromDate) : null);
        setInitialToDate(toDate ? dayjs(toDate) : null);
    }, [fromDate, toDate]);

    const handleDatesChange = ([currentFromDate, currentToDate]) => {
        setInitialFromDate(currentFromDate);
        setInitialToDate(currentToDate);

        if (!currentFromDate && !currentToDate) {
            onChange({ from_date: null, to_date: null });
        }
    };

    const handleOnSubmit = ([currentFromDate, currentToDate]) => {
        onChange({
            from_date: currentFromDate ? dayjs(currentFromDate).format(filterDateFormat) : null,
            to_date: currentToDate ? dayjs(currentToDate).format(filterDateFormat) : null,
        });
    };

    return (
        <DatePicker.RangePicker
            value={[initialFromDate, initialToDate]}
            presets={[
                { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
                { label: "Yesterday", value: [dayjs().subtract(1, "days").startOf("day"), dayjs().endOf("day")] },
                { label: "Last 3 Days", value: [dayjs().subtract(3, "days").startOf("day"), dayjs().endOf("day")] },
                { label: "Last 7 Days", value: [dayjs().subtract(7, "days").startOf("day"), dayjs().endOf("day")] },
                { label: "This week", value: [dayjs().startOf("week"), dayjs().endOf("week")] },
                { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("month")] },
                { label: "Last 3 Months", value: [dayjs().subtract(3, "month").startOf("month"), dayjs().endOf("month")] },
                { label: "Last 6 Months", value: [dayjs().subtract(6, "month").startOf("month"), dayjs().endOf("month")] },
            ]}
            defaultValue={[dayjs().subtract(7, "days").startOf("day"), dayjs().endOf("day")]}
            showTime
            format="YYYY/MM/DD HH:mm:ss"
            onCalendarChange={handleDatesChange}
            onChange={handleOnSubmit}
            onOk={() => handleOnSubmit([initialFromDate, initialToDate])}
        />
    );
};
