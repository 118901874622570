import { lazy } from "react";
import { PagePath, RouteType } from "../types";

export const PrivateRoutes: RouteType[] = [
    {
        path: PagePath.REQUIREMENTS,
        component: lazy(() => import("../../view/pages/requirements/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.TEST_SUITES,
        component: lazy(() => import("../../view/pages/tests/testSuitesCases/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/tests/suites/:id",
        component: lazy(() => import("../../view/pages/tests/testSuitesCases/single/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: PagePath.TEST_APPROACHES,
        component: lazy(() => import("../../view/pages/tests/testCasesApproach/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.TEST_CASES,
        component: lazy(() => import("../../view/pages/tests/testCaseTable/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: PagePath.TEST_PAGE,
        component: lazy(() => import("../../view/pages/tests/testCaseTable/single/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: PagePath.NEW_TEST_CASE,
        component: lazy(() => import("../../view/pages/tests/newDefinition/newDefinition")),
        layout: "VerticalLayout",
        exact: false,
    },
    {
        path: PagePath.PREDICTIONS,
        component: lazy(() => import("../../view/pages/predictions/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.RELEASES,
        component: lazy(() => import("../../view/pages/release/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.RULES,
        component: lazy(() => import("../../view/pages/rules/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.NEW_EXECUTION,
        component: lazy(() => import("../../view/pages/executions/simple/steps/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.NEW_WORKFLOW,
        component: lazy(() => import("../../view/pages/workflow/new")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.WORKFLOW_TEMPLATES,
        component: lazy(() => import("../../view/pages/workflow/templates")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.EXECUTIONS,
        component: lazy(() => import("../../view/pages/executions/results/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.EXECUTION_PAGE,
        component: lazy(() => import("../../view/pages/executions/single/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.RECONCILIATION,
        component: lazy(() => import("../../view/pages/reconciliation/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.PROFILE_INFORMATION,
        component: lazy(() => import("../../view/pages/profile/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.PROFILE_PASSWORD_CHANGE,
        component: lazy(() => import("../../view/pages/profile/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.DATA_QUALITY_DASHBOARD,
        component: lazy(() => import("../../view/pages/data_quality/dashboard/dashboard")),
        layout: "FullLayout",
    },
    {
        path: PagePath.NEW_DATA_QUALITY,
        component: lazy(() => import("../../view/pages/data_quality/new/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.DATA_QUALITY_RESULTS_PAGE,
        component: lazy(() => import("../../view/pages/data_quality/single/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/data_quality/results",
        component: lazy(() => import("../../view/pages/data_quality/results/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.CONNECTIONS,
        component: lazy(() => import("../../view/pages/settings/connections/connections")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.USERS,
        component: lazy(() => import("../../view/pages/settings/users/users")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.CONFIGURATIONS,
        component: lazy(() => import("../../view/pages/settings/configurations/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.NOTIFICATIONS,
        component: lazy(() => import("../../view/pages/settings/notifications/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.SETTINGS,
        component: lazy(() => import("../../view/pages/settings/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.VARIABLES,
        component: lazy(() => import("../../view/pages/executions/variables/index")),
        layout: "VerticalLayout",
    },
    {
        path: PagePath.ERROR_PAGE,
        component: lazy(() => import("../../view/pages/error")),
        layout: "FullLayout",
    },
];
