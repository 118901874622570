import { Space } from "antd";
import { FunctionComponent } from "react";
import { InputFilterItem, StatusFilterItem } from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const RulesFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange }) => {
    return (
        <Space wrap>
            <InputFilterItem filters={filters} keyOptions="name" placeholder="Search rule name...." onChange={onChange} />
            <StatusFilterItem filters={filters} onChange={onChange} />
        </Space>
    );
};
