import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

interface Props extends FilterItemProps {
    table: string;
    column: string;
}

export const DistinctValuesFilterItem: FC<Props> = observer(
    ({ filters, table, column, onChange, label = "Distinct values", keyOptions = "distinct_values" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterDistinctValues, setFilterDistinctValues] = useState(appliedFilter);
        const [distinctValues, setDistinctValues] = useState([]);
        const [distinctValuesIsLoading, setDistinctValuesIsLoading] = useState(false);

        const fetchDistinctValueOptions = () => {
            setDistinctValuesIsLoading(true);

            store.configStore
                .getDistinctValues(table, column)
                .then((data) => {
                    if (!data) return;

                    setDistinctValues(data.slice(0, optionsSize));
                })
                .finally(() => setDistinctValuesIsLoading(false));
        };

        useEffect(() => {
            setFilterDistinctValues(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={distinctValuesIsLoading}
                options={distinctValues.map((it: any) => ({ name: it, value: it }))}
                values={filterDistinctValues}
                searchLocal={true}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterDistinctValues(value)}
                getOptions={fetchDistinctValueOptions}
                onSave={(savedDbTable) => onChange({ [keyOptions]: savedDbTable })}
            />
        );
    }
);
