import { Typography } from "antd";
import React, { CSSProperties, ReactNode } from "react";
import { AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";

interface Props {
    text: string | JSX.Element;
    rows?: number;
    copyable?: boolean;
    isColumn?: boolean;
    style?: CSSProperties;
    children?: ReactNode;
    className?: string;
}

export const EllipsisTooltip: React.FC<Props> = ({
    text,
    rows,
    copyable = false,
    isColumn = false,
    style = {},
    children = null,
    className = "",
}) => (
    <div
        style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            width: "100%",
            ...(isColumn && {
                flexWrap: "nowrap",
                flexDirection: "column",
            }),
        }}
    >
        <Typography.Paragraph
            copyable={
                copyable
                    ? {
                          text: typeof text === "string" ? text : text.props.children,
                          icon: [
                              <AiOutlineCopy size={20} style={{ transform: "translateY(2px)" }} />,
                              <AiOutlineCheck size={20} style={{ transform: "translateY(2px)" }} />,
                          ],
                          tooltips: false,
                      }
                    : null
            }
            ellipsis={{
                rows: rows || copyable ? 4 : 3,
                expandable: false,
                tooltip: <span style={{ display: "block", textAlign: "center" }}>{text}</span>,
            }}
            className={className}
            style={{ margin: 0, ...style }}
        >
            {text}
        </Typography.Paragraph>
        {children && <div style={{ display: "flex", alignItems: "baseline", flexShrink: 0, marginTop: 3 }}>{children}</div>}
    </div>
);
